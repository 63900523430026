import Link from '@material-ui/core/Link';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { localeState } from '../../../api/appState';
import { catalogueFilterTireSeasonTypesState } from '../../../api/catalogue/catalogue';
import { mainNavigationNarrowNavigationToggleState } from '../../../api/mainNavigation';
import MotoIcon from '../../../components/icons/MotoIcon';
import SuvIcon from '../../../components/icons/SuvIcon';
import TirIcon from '../../../components/icons/TirIcon';
import TruckIcon from '../../../components/icons/TruckIcon';
import { routeUrl } from '../../../routesBuilder';
import AppLayoutHeaderWideVehicleTypeLink from './AppLayoutHeaderWideVehicleTypeLink';

export type AppLayoutHeaderNarrowListProps = StandardDivProps<AppLayoutHeaderNarrowListClassKey>;

export type AppLayoutHeaderNarrowListClassKey = 'root' | 'linkContainer';

const themeSettings = { name: 'AppLayoutHeaderNarrowList' };
const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
    },
    linkContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1, 0),
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderTopColor: theme.palette.divider,
      borderBottomColor: theme.palette.divider,
      '& > a': {
        color: theme.palette.text.primary,
        padding: theme.spacing(1, 2),
        [theme.breakpoints.down('xs')]: {
          paddingLeft: theme.spacing(8),
        },
      },
    },
  }),
  themeSettings,
);

const AppLayoutHeaderNarrowList: React.FC<AppLayoutHeaderNarrowListProps> = React.forwardRef(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const locale = useRecoilValue(localeState);
    const onToggleOpen = useSetRecoilState(mainNavigationNarrowNavigationToggleState);

    const handleClose = React.useCallback(() => onToggleOpen(undefined), [onToggleOpen]);

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <AppLayoutHeaderNarrowVehicleTypeList onClose={handleClose} />
        <AppLayoutHeaderNarrowMainNavigationLinks onClose={handleClose} />
        <div className={classes.linkContainer}>
          <Link component={RouterLink} to={routeUrl('branchList', { locale, params: {} })} onClick={handleClose}>
            Naše pneuservisy
          </Link>
          <Link component={RouterLink} to={routeUrl('tireSelection', { locale, params: {} })} onClick={handleClose}>
            Výběr pneumatiky
          </Link>
          <Link component={RouterLink} to={routeUrl('ourServices', { locale, params: {} })} onClick={handleClose}>
            Servisní služby
          </Link>
        </div>
      </div>
    );
  },
);

AppLayoutHeaderNarrowList.displayName = 'AppLayoutHeaderNarrowList';

export default withThemeProps(themeSettings)(AppLayoutHeaderNarrowList);

const AppLayoutHeaderNarrowVehicleTypeList = withStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      width: '100%',
      maxWidth: 'unset',
      background: theme.palette.primary.main,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        background: theme.palette.common.white,
      },
    },
    vehicleType: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'inherit',

      '& svg': { marginRight: theme.spacing(1), width: theme.spacing(5) },

      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(2),
        width: '100%',
        color: theme.palette.text.primary,
        border: 'none',
        justifyContent: 'flex-start',
        '&:first-of-type': { borderLeft: 'none' },
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.divider,
      },
    },
    icon: {
      fill: theme.palette.common.white,
      [theme.breakpoints.down('xs')]: {
        fill: theme.palette.primary.main,
      },
    },
    tirIcon: {
      [theme.breakpoints.down('xs')]: {
        stroke: theme.palette.primary.main,
      },
    },
  }),
  { name: 'AppLayoutHeaderNarrowVehicleTypeList' },
)(
  ({
    classes,
    className,
    onClose: handleClose,
  }: {
    classes: Record<'root' | 'vehicleType' | 'icon' | 'tirIcon', string>;
    className?: string;
    onClose: () => void;
  }) => {
    const locale = useRecoilValue(localeState);
    const tireSeasons = useRecoilValue(catalogueFilterTireSeasonTypesState);
    return (
      <div className={clsx(classes.root, className)}>
        <AppLayoutHeaderWideVehicleTypeLink
          className={classes.vehicleType}
          classes={{ iconFill: classes.icon }}
          icon={SuvIcon}
          to={routeUrl('catalogue', {
            locale,
            otherParamsToQs: true,
            params: { filter: { vehicleType: 'passenger', tireSeasons } },
          })}
          onClick={handleClose}
        >
          {'Osobní a SUV'}
        </AppLayoutHeaderWideVehicleTypeLink>
        <AppLayoutHeaderWideVehicleTypeLink
          className={classes.vehicleType}
          classes={{ iconFill: classes.icon }}
          icon={TirIcon}
          to={routeUrl('catalogue', {
            locale,
            otherParamsToQs: true,
            params: { filter: { vehicleType: 'van', tireSeasons } },
          })}
          onClick={handleClose}
        >
          {'Dodávky'}
        </AppLayoutHeaderWideVehicleTypeLink>
        <AppLayoutHeaderWideVehicleTypeLink
          className={classes.vehicleType}
          classes={{ iconFill: classes.tirIcon }}
          icon={TruckIcon}
          to={routeUrl('catalogue', {
            locale,
            otherParamsToQs: true,
            params: { filter: { vehicleType: 'truck', tireSeasons } },
          })}
          onClick={handleClose}
        >
          {'Nákladní auta'}
        </AppLayoutHeaderWideVehicleTypeLink>
        <AppLayoutHeaderWideVehicleTypeLink
          className={classes.vehicleType}
          classes={{ iconFill: classes.icon }}
          icon={MotoIcon}
          to={routeUrl('catalogue', {
            locale,
            otherParamsToQs: true,
            params: { filter: { vehicleType: 'moto', tireSeasons } },
          })}
          onClick={handleClose}
        >
          {'Motocykly'}
        </AppLayoutHeaderWideVehicleTypeLink>
      </div>
    );
  },
);

const AppLayoutHeaderNarrowMainNavigationLinks = withStyles(
  (theme: Theme) => ({
    root: {
      color: 'inherit',
      display: 'flex',
      flexDirection: 'column',
      '& a': {
        color: 'inherit',
        padding: theme.spacing(1, 2),
        [theme.breakpoints.down('xs')]: {
          paddingLeft: theme.spacing(8),
        },
      },
    },
  }),
  { name: 'AppLayoutHeaderNarrowMainNavigationLinks' },
)(
  ({
    onClose: handleClose,
    classes,
    className,
  }: {
    classes: Record<'root', string>;
    className?: string;
    onClose: () => void;
  }) => {
    const locale = useRecoilValue(localeState);
    const t = useTranslate();

    return (
      <div className={clsx(className, classes.root)}>
        <Link component={RouterLink} to={routeUrl('newsList', { locale, params: {} })} onClick={handleClose}>
          {t('appMainMenuLinks/news')}
        </Link>
        <Link component={RouterLink} to={routeUrl('tiresTestsList', { locale, params: {} })} onClick={handleClose}>
          {t('appMainMenuLinks/tests')}
        </Link>
        <Link component={RouterLink} to='/technicky-radce' onClick={handleClose}>
          {t('appMainMenuLinks/techHelp')}
        </Link>
        <Link component={RouterLink} to={routeUrl('termsAndConditions', { locale, params: {} })} onClick={handleClose}>
          {t('appMainMenuLinks/termsAndConditions')}
        </Link>
        <Link component={RouterLink} to='/kontakt' onClick={handleClose}>
          {t('appMainMenuLinks/contact')}
        </Link>
      </div>
    );
  },
);
