import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import React from 'react';
import { Link as RouterLink, Route } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { wrapLayout } from './@sprinx/react-after-razzle';
import { localeState } from './api/appState';
import Page from './components/Page';
import PageHeadline from './components/PageHeadline';
import { routeUrl } from './routesBuilder';

export type NotFoundErrorPageProps = StandardDivProps<NotFoundErrorPageClassKey>;

export type NotFoundErrorPageClassKey = 'root' | 'content' | 'title' | 'button';

const themeSettings = { name: 'NotFoundErrorPage' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<NotFoundErrorPageClassKey, {}>({
      root: {},
      title: {
        textAlign: 'center',
        fontSize: theme.typography.h1.fontSize,
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflow: 'hidden',
        alignItems: 'center',
        fontSize: theme.typography.h5.fontSize,
        padding: theme.spacing(1, 2, 5),
        textAlign: 'center',
      },
      button: {
        marginTop: theme.spacing(3),
      },
    }),
  themeSettings,
);

function NotFoundErrorPage({ classes: pClasses }: StandardDivProps<NotFoundErrorPageClassKey>): JSX.Element {
  const classes = useStyles({ classes: pClasses });
  const t = useTranslate();
  const locale = useRecoilValue(localeState);
  const to = routeUrl('home', { locale, params: {} });

  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = 404;
        return (
          <Page pageTitle={t('page404/title')}>
            <PageHeadline classes={{ title: classes.title }}>{t('page404/title')}</PageHeadline>
            <div className={classes.content}>
              <span>{t('page404/subTitle')}</span>
              <Button className={classes.button} to={to} color='primary' variant='outlined' component={RouterLink}>
                {t('page404/button')}
              </Button>
            </div>
          </Page>
        );
      }}
    />
  );
}

export default wrapLayout(withThemeProps(themeSettings)(NotFoundErrorPage));
