import { QueryPaginationSettings } from '@sprinx/query-builder';
import { atom, DefaultValue, selector } from 'recoil';
import { MoleculerListResult } from '../../@sprinx/knihovka-types';
import { apiClientState, currencyState, localeState, pricesTypeState, regionState } from '../appState';
import { VehicleType } from '../vehicleTypes';
import { CatalogueFilter, CataloguePageProduct, getProductCatalogue } from './catalogue';

export const catalogueSearchTerm = atom<string | undefined>({
  key: 'catalogueSearchTerm',
  default: undefined,
});

export const catalogueSearchQuery = selector<MoleculerListResult<CataloguePageProduct>>({
  key: 'catalogueSearchQuery',
  get: ({ get }) => {
    const term = get(catalogueSearchTerm);
    if (term && term.length > 3) {
      return getProductCatalogue(
        get(apiClientState),
        get(localeState),
        get(currencyState),
        undefined, // decimalPrecision
        undefined, // enabledDynamicRules
        get(pricesTypeState),
        get(regionState),
        { searchTerm: term },
        { orderBy: 'name.cs', orderDirection: 'asc' }, // params.order,
        { page: 1, rowsPerPage: 5 }, //params.pagination,
      );
    } else {
      return {
        page: 1,
        total: 0,
        totalPages: 0,
        rows: [],
        pageSize: 5,
      } as MoleculerListResult<CataloguePageProduct>;
    }
  },

  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(catalogueSearchQueryTrigger, (n) => n + 1);
    }
  },
});

const catalogueSearchQueryTrigger = atom<number>({
  key: 'catalogueSearchQueryTrigger',
  default: 0,
});

export const catalogueSearchResultsTerm = atom<string | undefined>({
  key: 'catalogueSearchResultsTerm',
  default: undefined,
});

export const catalogueSearchResultsQuery = selector<
  MoleculerListResult<CataloguePageProduct> & { vehicleTypes: Record<VehicleType, number> }
>({
  key: 'catalogueSearchResultsQuery',
  get: ({ get }) => {
    const term = get(catalogueSearchResultsTerm);
    if (term) {
      const getCatalogue = (filter: CatalogueFilter, pagination: QueryPaginationSettings) =>
        getProductCatalogue(
          get(apiClientState),
          get(localeState),
          get(currencyState),
          undefined, // decimalPrecision
          undefined, // enabledDynamicRules
          get(pricesTypeState),
          get(regionState),
          filter,
          { orderBy: 'name.cs', orderDirection: 'asc' }, // params.order,
          pagination, //params.pagination,
        );

      return Promise.all([
        getCatalogue(
          { searchTerm: term },
          { page: 1, rowsPerPage: 12 }, //params.pagination,
        ),
        getCatalogue({ searchTerm: term, vehicleType: 'passenger' }, { page: 1, rowsPerPage: 1 }),
        getCatalogue({ searchTerm: term, vehicleType: 'van' }, { page: 1, rowsPerPage: 1 }),
        getCatalogue({ searchTerm: term, vehicleType: 'truck' }, { page: 1, rowsPerPage: 1 }),
        getCatalogue({ searchTerm: term, vehicleType: 'moto' }, { page: 1, rowsPerPage: 1 }),
      ]).then(([data, passenger, van, truck, moto]) => ({
        ...data,
        vehicleTypes: {
          moto: moto.total,
          passenger: passenger.total,
          truck: truck.total,
          van: van.total,
        },
      }));
    } else {
      return {
        page: 1,
        total: 0,
        totalPages: 0,
        rows: [],
        pageSize: 12,
        vehicleTypes: {
          van: 0,
          passenger: 0,
          truck: 0,
          moto: 0,
        },
      } as MoleculerListResult<CataloguePageProduct> & { vehicleTypes: Record<VehicleType, number> };
    }
  },

  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(catalogueSearchResultsQueryTrigger, (n) => n + 1);
    }
  },
});

const catalogueSearchResultsQueryTrigger = atom<number>({
  key: 'catalogueSearchResultsQueryTrigger',
  default: 0,
});
