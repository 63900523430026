import { createStyles, makeStyles } from '@material-ui/core/styles';
import currencyFormatter, { CurrencyFormatterAmountAsObject } from '@sprinx/formatting/currencyFormatter';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';

export interface PriceFormattedProps extends StandardDivProps<PriceFormattedClassKey> {
  currency?: string;
  value: number | CurrencyFormatterAmountAsObject | null | undefined;
}

export type PriceFormattedClassKey = 'root';

const themeSettings = { name: 'PriceFormatted' };
const useStyles = makeStyles(
  createStyles<PriceFormattedClassKey, {}>({
    root: {},
  }),
  themeSettings,
);

const PriceFormatted = React.forwardRef<HTMLSpanElement, PriceFormattedProps>(
  ({ className, classes: pClasses, currency, value }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <span ref={ref} className={clsx(classes.root, className)}>
        {currencyFormatter(value, currency, { maximumFractionDigits: 0 })}
      </span>
    );
  },
);

PriceFormatted.displayName = 'PriceFormatted';

export default withThemeProps(themeSettings)(PriceFormatted);
