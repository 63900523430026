import { makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import AppLayoutFooterPartners from './AppLayoutFooterPartners';
import AppLayoutFooterWideNavigation from './AppLayoutFooterWideNavigation';

export type AppLayoutFooterWideProps = StandardDivProps<AppLayoutFooterWideClassKey>;

export type AppLayoutFooterWideClassKey = 'root';

const themeSettings = { name: 'AppLayoutFooterWide' };
const useStyles = makeStyles(
  (theme) => ({
    root: {
      paddingTop: theme.spacing(8),
    },
  }),
  themeSettings,
);

const AppLayoutFooterWide: React.FC<AppLayoutFooterWideProps> = React.forwardRef(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        {/* TODO: doplnit obsah kosiku  */}
        <AppLayoutFooterPartners />
        <AppLayoutFooterWideNavigation />
      </div>
    );
  },
);

AppLayoutFooterWide.displayName = 'AppLayoutFooterWide';

export default withThemeProps(themeSettings)(AppLayoutFooterWide);
