import React from 'react';

export interface TruckIconProps extends React.SVGAttributes<SVGSVGElement> {
  fillClassName?: string;
  ref?: React.Ref<SVGSVGElement>;
}

const TruckIcon: React.FC<TruckIconProps> = React.forwardRef(({ fillClassName, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      // width='2129px'
      // height='1127px'
      width='38px'
      height='19px'
      viewBox='0 0 2129 1127'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <ellipse
          id='Oval'
          stroke='#ffffff'
          strokeWidth='60'
          cx='361'
          cy='964'
          rx='134'
          ry='133'
          className={fillClassName}
        ></ellipse>
        <ellipse
          id='Oval'
          stroke='#ffffff'
          strokeWidth='60'
          cx='1462'
          cy='964'
          rx='134'
          ry='133'
          className={fillClassName}
        ></ellipse>
        <ellipse
          id='Oval'
          stroke='#ffffff'
          strokeWidth='60'
          cx='1732'
          cy='964'
          rx='134'
          ry='133'
          className={fillClassName}
        ></ellipse>
        <polyline
          id='Path'
          stroke='#ffffff'
          strokeWidth='59'
          strokeLinecap='round'
          strokeLinejoin='round'
          points='158.482317 229.172395 497.111791 229.172395 497.111791 831.198146 412.21131 831.198146'
          className={fillClassName}
        ></polyline>
        <path
          d='M190.424392,229.172395 L99.8037353,513.28598 C98.2871851,518.040663 95.9152977,522.478515 92.8047382,526.381252 L37.1693316,596.185533 C32.6528982,601.852183 30.1934689,608.883954 30.1934689,616.13027 L30.1934689,906.967749 C30.1934689,921.327153 41.8340654,932.967749 56.1934689,932.967749 L229.888643,932.967749 L229.888643,932.967749'
          id='Path-2'
          stroke='#ffffff'
          strokeWidth='60'
          strokeLinecap='round'
          strokeLinejoin='round'
          className={fillClassName}
        ></path>
        <line
          x1='497.111791'
          y1='964'
          x2='1333.11439'
          y2='964'
          id='Path-3'
          stroke='#ffffff'
          strokeWidth='60'
          className={fillClassName}
        ></line>
        <path
          d='M1867.13166,964 L1965.18913,964 C1983.41452,964 1998.18913,949.225397 1998.18913,931 L1998.18913,863.640708 C1998.18913,847.072165 2011.62059,833.640708 2028.18913,833.640708 L2063.92336,833.640708 C2083.25333,833.640708 2098.92336,817.970674 2098.92336,798.640708 L2098.92336,61.8386487 C2098.92336,44.7178214 2085.04419,30.8386487 2067.92336,30.8386487 L528.111791,30.8386487 C510.990964,30.8386487 497.111791,44.7178214 497.111791,61.8386487 L497.111791,229.172395 L497.111791,229.172395'
          id='Path-4'
          stroke='#ffffff'
          strokeWidth='60'
          strokeLinecap='round'
          strokeLinejoin='round'
          className={fillClassName}
        ></path>
      </g>
    </svg>
  );
});

TruckIcon.displayName = 'TruckIcon';

export default TruckIcon;
