import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { TiresChangeDateListRequest } from '../../api/tiresChange';

export interface TireChangeDurationPreviewProps {
  params: TiresChangeDateListRequest;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles<'root' | 'duration', {}>({
    root: {
      ...theme.typography.subtitle1,
      fontWeight: theme.typography.fontWeightMedium,
      padding: theme.spacing(2, 0),
    },
    duration: {
      color: theme.palette.primary.dark,
      fontWeight: theme.typography.fontWeightBold,
      paddingLeft: '1ch',
    },
  }),
);

const TireChangeDurationPreview: React.FC<TireChangeDurationPreviewProps> = ({ params }) => {
  const classes = useStyles();

  const duration = [3, 4].includes(params.typAuta || 0)
    ? '60 minut'
    : params.velikostDisku === 1 && params.typDisku === 2
    ? '30 minut'
    : '45 minut';
  return (
    <div className={classes.root}>
      Orientační doba přezutí je <span className={classes.duration}>{duration}</span>
    </div>
  );
};

TireChangeDurationPreview.displayName = 'TireChangeDurationPreview';

export default TireChangeDurationPreview;
