import invariant from 'tiny-invariant';
import { ApiClient } from '../../@sprinx/react-after-razzle';

export function tiresChangeIsAlive(
  apiClient: ApiClient,
): Promise<{ isAlive: boolean; status: 'success' } | { msg: string | Error; status: 'error' }> {
  return apiClient.post<{ isAlive: boolean; status: 'success' } | { msg: string | Error; status: 'error' }, {}>(
    '/v1/tire-change/is-alive',
    {},
  );
}

export function tiresChangeDateList(
  apiClient: ApiClient,
  params: TiresChangeDateListRequest,
): Promise<TiresChangeDateListResult> {
  return apiClient.post<TiresChangeDateListResult, TiresChangeDateListRequest>('/v1/tire-change/seznam-datumu', params);
}

export function tiresChangeDayTimeList(
  apiClient: ApiClient,
  params: TiresChangeDayTimeListRequest,
): Promise<TiresChangeDayTimeListResult> {
  return apiClient.post<TiresChangeDayTimeListResult, TiresChangeDayTimeListRequest>(
    '/v1/tire-change/seznam-casu-dne',
    params,
  );
}

export function tiresChangeRegister(
  apiClient: ApiClient,
  params: TiresChangeRegisterRequest,
): Promise<TiresChangeRegisterResult> {
  invariant(apiClient && params);

  return apiClient.post<TiresChangeRegisterResult, TiresChangeRegisterRequest>('/v1/tire-change/reservovat', params);
}

export interface TiresChangeDateListRequest {
  dokladOUskladneni?: string;
  kodProvozovny: KodProvozovny;
  spz: string;
  typAuta: 1 | 2 | 3 | 4;
  typDisku: 1 | 2;
  typPraceSDisky: 1 | 2;
  velikostDisku: 1 | 2;
}

export type TiresChangeDateListResult =
  | { dateList: TireChangeDateListItem; status: 'success' }
  | { msg: string | Error; status: 'error' };

export interface TireChangeDateListItem {
  datum: Date;
  kodProvozovny: KodProvozovny;
  moznoRezervovat: boolean;
  pocetMinutNaVymenu: number;
  typAuta: number;
}

export type TiresChangeDayTimeListRequest = TireChangeDateListItem;

export type TiresChangeDayTimeListResult =
  | { status: 'success'; timeList: TireChangeDayTimeListItem }
  | { msg: string | Error; status: 'error' };

export interface TireChangeDayTimeListItem {
  cas: null;
  hodina: number;
  minuta: number;
  moznoRezervovat: boolean;
}

export interface TiresChangeRegisterRequest {
  date: Date;
  dokladOUskladneni?: string;
  emailAddress: string;
  kodProvozovny: KodProvozovny;
  personName: string;
  phoneNumber: string;
  spz: string;
  time: TireChangeDayTimeListItem;
  typAuta: 1 | 2 | 3 | 4;
  typDisku: 1 | 2;
  typPraceSDisky: 1 | 2;
  velikostDisku: 1 | 2;
}

export type TiresChangeRegisterResult =
  | { reservationCode: string; status: 'success' }
  | { msg: string | Error; status: 'error' }
  | { status: 'notSaved' };

export type KodProvozovny = 1 | 2 | 4 | 6 | 7 | 8 | 9 | 10;
