export default function calculateSectionCompletedByFields(
  toCheckTuples: CalculateSectionCompletedByFieldsTuple[],
): boolean {
  const check = ([value, error, optional]: CalculateSectionCompletedByFieldsTuple): boolean =>
    optional ? !error : value && !error;

  return toCheckTuples.map(check).reduce((r, i) => r && i, true);
}

export type CalculateSectionCompletedByFieldsTuple = [value: any, error: any, optional?: boolean];
