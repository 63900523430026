import queryBuilder, {
  BuildedListQuery,
  FilterField,
  QueryOrderDirection,
  QueryOrderSettings,
  QueryPaginationSettings,
} from '@sprinx/query-builder';
import invariant from 'tiny-invariant';
import { MoleculerListResult } from '../knihovka-types';

export function createListInitialSettings(initialOptions: ListSettingsOptions): Readonly<ListCallParams> {
  const defaults = {
    orderDirection: 'asc' as QueryOrderDirection,
    rowsPerPage: 24,
    page: 1,
    filter: [] as FilterField<any>[],
    ...initialOptions,
  };

  invariant(
    defaults.orderBy && typeof defaults.orderBy === 'string',
    'useServideCallListInitials(): orderByField have to be non empty string.',
  );
  invariant(
    ['asc', 'desc'].includes(defaults.orderDirection),
    "useServideCallListInitials(): orderDirection have to be one of 'asc', 'desc'.",
  );
  invariant(typeof defaults.rowsPerPage === 'number', 'useServideCallListInitials(): rowsPerPage have to be an numer.');
  invariant(Array.isArray(defaults.filter), 'useServideCallListInitials(): filter have to be an Array.');

  return {
    filter: defaults.filter,
    order: {
      orderBy: defaults.orderBy,
      orderDirection: defaults.orderDirection,
    },
    pagination: {
      page: defaults.page,
      rowsPerPage: defaults.rowsPerPage,
    },
  };
}

export function createListDefaultResult<T>(pageSize = 12): MoleculerListResult<T> {
  return {
    page: 1,
    pageSize,
    rows: [],
    total: 0,
    totalPages: 0,
  };
}

export function buildListQuery<O extends Record<string, any>>(params: ListCallParams, other?: O): BuildedListQuery<O> {
  const callParams = queryBuilder(
    { async: false, dialect: 'moleculer' },
    params.filter,
    params.order,
    params.pagination,
  );

  return {
    ...(other || {}),
    ...callParams,
  } as BuildedListQuery<O>;
}

export function buildPaginationQuery<O extends Record<string, any>>(
  pagination: QueryPaginationSettings | undefined,
  other?: Record<string, any>,
): BuildedListQuery<O> {
  const pg = pagination ? { page: pagination.page, pageSize: pagination.rowsPerPage } : {};

  return {
    ...(other || {}),
    ...pg,
  } as BuildedListQuery<O>;
}

export type ListCallHookHandler = (params: ListCallParams) => void;
export type ListCallApiHandler = <T>(params: ListCallParams) => MoleculerListResult<T>;

export type ListCallParams = {
  filter: FilterField<any>[];
  order: QueryOrderSettings;
  pagination: QueryPaginationSettings;
};

export interface ListSettingsState {
  filter: FilterField<any>[];
  order: QueryOrderSettings;
  pagination: QueryPaginationSettings;
}

export interface ListSettingsOptions {
  filter?: FilterField<any>[];
  orderBy: string;
  orderDirection?: QueryOrderDirection;
  page?: number;
  rowsPerPage?: number;
}
