import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';

export type AppLayoutSearchBarSkeletonProps = StandardDivProps<AppLayoutSearchBarSkeletonClassKey>;

export type AppLayoutSearchBarSkeletonClassKey = 'root';

const themeSettings = { name: 'AppLayoutSearchBarSkeleton' };
const useStyles = makeStyles(
  createStyles<AppLayoutSearchBarSkeletonClassKey, {}>({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: 260,
      height: 240,
    },
  }),
  themeSettings,
);

const AppLayoutSearchBarSkeleton = React.forwardRef<HTMLDivElement, AppLayoutSearchBarSkeletonProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <Box display='flex' flexDirection='column' flexGrow={1}>
          <Skeleton width='100%' height={20} variant='text' />
          <Skeleton width='65%' height={20} variant='text' />
          <Skeleton width='50%' height={20} variant='text' />
          <Skeleton width='30%' height={20} variant='text' />
          <Skeleton width='100%' height={20} variant='text' />
          <Skeleton width='65%' height={20} variant='text' />
          <Skeleton width='50%' height={20} variant='text' />
          <Skeleton width='30%' height={20} variant='text' />
          <Skeleton width='100%' height={20} variant='text' />
          <Skeleton width='65%' height={20} variant='text' />
          <Skeleton width='50%' height={20} variant='text' />
          <Skeleton width='30%' height={20} variant='text' />
        </Box>
      </div>
    );
  },
);

AppLayoutSearchBarSkeleton.displayName = 'AppLayoutSearchBarSkeleton';

export default withThemeProps(themeSettings)(AppLayoutSearchBarSkeleton);
