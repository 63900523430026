import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import dateFormatter from '@sprinx/formatting/dateFormatter';
import React from 'react';
import { BranchListItem } from '../../api/articles/Branch';
import { TireChangeDateListItem } from '../../api/tiresChange';
import TireChangeWizzardStepper from './TireChangeWizzardStepper';

export interface TireChangeWizzardStep2Props {
  branch: BranchListItem;
  dateList: TireChangeDateListItem[];
  daysOffset: number;
  onClose: () => void;
  onNext: (values: TireChangeDateListItem) => void;
  onPrev: () => void;
  titlePrefix: string;
}

export type TireChangeWizzardStep2ClassKey = 'actions' | 'actionsRight' | 'title' | 'summary';
const useStyles = makeStyles((theme: Theme) =>
  createStyles<TireChangeWizzardStep2ClassKey, {}>({
    actions: {
      justifyContent: 'unset',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
        alignItems: 'stretch',
        '& > button': {
          margin: theme.spacing(0.5, 0),
          marginLeft: '0 !important',
        },
      },
    },
    actionsRight: {
      marginLeft: 'auto !important',
    },
    title: {
      ...theme.typography.subtitle1,
      textAlign: 'center',
      padding: theme.spacing(2),
    },
    summary: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      border: 'solid 1px',
      borderColor: theme.palette.divider,
    },
  }),
);

const TireChangeWizzardStep2: React.FC<TireChangeWizzardStep2Props> = ({
  branch,
  dateList,
  daysOffset,
  onClose,
  onNext,
  onPrev,
  titlePrefix,
}) => {
  const [selected, setSelected] = React.useState<TireChangeDateListItem | undefined>(undefined);
  const classes = useStyles();

  return (
    <>
      <DialogTitle>{titlePrefix}</DialogTitle>
      <TireChangeWizzardStepper activeStep={1} />
      <DialogContent>
        <div className={classes.summary}>
          <span>
            Storex - provozovna: <strong>{branch.title}</strong>
          </span>
        </div>
        <div className={classes.title}>
          Vyberte si, prosím, <strong>DEN</strong>, který vám vyhovuje.
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <DateSelectPanel dateList={dateList} daysOffset={daysOffset} value={selected} onChange={setSelected} />
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button color='secondary' variant='outlined' onClick={() => onPrev()}>
          Předchozí
        </Button>
        <Button className={classes.actionsRight} color='secondary' variant='outlined' onClick={() => onClose()}>
          Zrušit
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            if (selected) {
              onNext(selected);
            }
          }}
          disabled={!selected}
        >
          Vybrat čas
        </Button>
      </DialogActions>
    </>
  );
};

TireChangeWizzardStep2.displayName = 'TireChangeWizzardStep2';

export default TireChangeWizzardStep2;

export type DateSelectPanelClassKey = 'content' | 'headline' | 'narrowContent' | 'button';
export interface DateSelectPanelProps {
  dateList: TireChangeDateListItem[];
  daysOffset: number;
  onChange: (vaulue: TireChangeDateListItem | undefined) => void;
  value: TireChangeDateListItem | undefined;
}
const useStylesDateSelect = makeStyles((theme: Theme) =>
  createStyles<DateSelectPanelClassKey, {}>({
    content: {
      width: 752,
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
    },
    narrowContent: {
      display: 'flex',
      flexDirection: 'column',
      height: '50vh',
      overflow: 'auto',
    },

    button: {
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.divider,
      flex: '1 0 auto',
      '& .dateOfWeek': {
        width: 25,
      },
      '& .date': {
        width: 105,
        textAlign: 'right',
      },
    },
    headline: { fontWeight: theme.typography.fontWeightMedium, textAlign: 'center', paddingBottom: theme.spacing(1) },
  }),
);

const DateSelectPanel: React.FC<DateSelectPanelProps> = ({ dateList, daysOffset, value, onChange: handleChange }) => {
  const classes = useStylesDateSelect();
  const theme = useTheme();
  const narrow = useMediaQuery(theme.breakpoints.down('sm'));
  const $today = new Date();
  const tomorrow = new Date($today.getFullYear(), $today.getMonth(), $today.getDate() + daysOffset, 0, 0, 0, 0);

  return narrow ? (
    <DateSelectPannelNarrow
      classes={classes}
      className={classes.narrowContent}
      onChange={handleChange}
      value={value}
      tomorrow={tomorrow}
      dateList={dateList}
    />
  ) : (
    <DateSelectPannelWide
      classes={classes}
      onChange={handleChange}
      value={value}
      tomorrow={tomorrow}
      dateList={dateList}
    />
  );
};

const DateSelectPannelWide: React.FC<{
  classes: Record<'content' | 'headline', string>;
  dateList: TireChangeDateListItem[];
  onChange: (vaulue: TireChangeDateListItem | undefined) => void;
  tomorrow: Date;
  value: TireChangeDateListItem | undefined;
}> = ({ classes, onChange: handleChange, dateList, value, tomorrow }) => {
  const weekDay = ['Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota', 'Neděle'];

  return (
    <div className={classes.content}>
      {weekDay.map((i, index) => (
        <span key={index.toString()} className={classes.headline}>
          {i}
        </span>
      ))}
      {dateList.map((item, idx) => (
        <Button
          key={idx.toString()}
          disabled={!item.moznoRezervovat || item.datum < tomorrow}
          component='button'
          color={'primary'}
          variant={item === value ? 'contained' : 'text'}
          onClick={() => {
            handleChange(item);
          }}
        >
          {dateFormatter(item.datum)}
        </Button>
      ))}
    </div>
  );
};
const DateSelectPannelNarrow: React.FC<{
  classes: Record<'button', string>;
  className?: string;
  dateList: TireChangeDateListItem[];
  onChange: (vaulue: TireChangeDateListItem | undefined) => void;
  tomorrow: Date;
  value: TireChangeDateListItem | undefined;
}> = ({ classes, className, dateList, onChange: handleChange, tomorrow, value }) => {
  const sortedDateList = dateList.filter((i) => i.moznoRezervovat && i.datum > tomorrow);

  return (
    <div className={className}>
      {sortedDateList.map((item, idx) => (
        <Button
          className={classes.button}
          key={idx.toString()}
          disabled={!item.moznoRezervovat || item.datum < tomorrow}
          component='button'
          color={'primary'}
          variant={item === value ? 'contained' : 'text'}
          onClick={() => {
            handleChange(item);
          }}
        >
          <span className='dateOfWeek'>{dateFormatter(item.datum, { skeleton: 'E' })}</span>
          <span className='date'>{dateFormatter(item.datum)}</span>
        </Button>
      ))}
    </div>
  );
};
