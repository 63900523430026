import { AtomEffect, DefaultValue } from 'recoil';
import createApiClient from './createApiClient';

export function atomEffectSyncWithSession<T>(key: string): AtomEffect<T> {
  return ({ onSet }) => {
    onSet((newValue) => {
      if (!(newValue instanceof DefaultValue)) {
        createApiClient().put('/v1/state/sync', { [key]: newValue });
      }
    });
  };
}
