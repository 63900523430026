import { atom, selector } from 'recoil';
import { ProductParameterTypes } from '../../@sprinx/knihovka-types/products';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState } from '../appState';

export type ProductParameterTypesHandler = () => Promise<ProductParameterTypes>;

export function getProductParameterTypes(apiClient: ApiClient): ReturnType<ProductParameterTypesHandler> {
  return apiClient.get<ProductParameterTypes, {}>('/v1/product-parameter-types', {});
}

export const productParameterTypesQuery = selector<ProductParameterTypes>({
  key: 'productParameterTypesQuery',
  get: ({ get }) => {
    const initials = get(productParameterTypesInitialsState);
    if (initials) return initials;

    return getProductParameterTypes(get(apiClientState));
  },
});

export const productParameterTypesInitialsState = GlobalStateRegister.register(
  atom<ProductParameterTypes | undefined>({
    key: 'productParameterTypesInitials',
    default: undefined,
  }),
);
