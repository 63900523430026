import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@sprinx/react-mui-fields/TextField';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 500,
      [theme.breakpoints.down('xs')]: {
        minWidth: 'unset',
        width: '100%',
      },
    },
    input: {
      backgroundColor: theme.palette.common.white,
    },
  }),
);

export interface StorexTextFieldProps extends TextFieldProps {
  maxLength?: number;
  placeholder?: string;
}

const StorexTextField = React.forwardRef<HTMLInputElement, StorexTextFieldProps>(
  ({ classes, InputProps, margin = 'dense', variant = 'outlined', maxLength = undefined, ...rest }, ref) => {
    const xClasses = useStyles({ classes });
    return (
      <TextField
        ref={ref}
        margin={margin}
        variant={variant}
        InputProps={{
          ...InputProps,
          classes: {
            ...InputProps?.classes,
            ...xClasses,
          },
        }}
        inputProps={{
          maxLength: maxLength,
        }}
        {...rest}
      />
    );
  },
);

export default StorexTextField;
