import Container, { ContainerProps } from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import AppLayoutHeaderNarrowMainNavigationAppBar from './AppLayoutHeaderNarrowMainNavigationAppBar';
import AppLayoutHeaderWideMainNavigationInfo from './AppLayoutHeaderWideMainNavigationInfo';

export interface AppLayoutHeaderNarrowMainNavigationProps
  extends StandardDivProps<AppLayoutHeaderNarrowMainNavigationClassKey> {
  maxWidth?: ContainerProps['maxWidth'];
}

export type AppLayoutHeaderNarrowMainNavigationClassKey = 'root';

const themeSettings = { name: 'AppLayoutHeaderNarrowMainNavigation' };
const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: theme.palette.common.white,
      padding: theme.spacing(0, 1),
      backgroundColor: theme.palette.primary.dark,
    },
    info: {},
    navbar: {},
  }),
  themeSettings,
);

const AppLayoutHeaderNarrowMainNavigation: React.FC<AppLayoutHeaderNarrowMainNavigationProps> = React.forwardRef(
  ({ className, classes: pClasses, maxWidth }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <Container ref={ref} className={clsx(classes.root, className)} maxWidth={maxWidth}>
        <AppLayoutHeaderWideMainNavigationInfo className={classes.info} />
        <AppLayoutHeaderNarrowMainNavigationAppBar className={classes.navbar} />
      </Container>
    );
  },
);

AppLayoutHeaderNarrowMainNavigation.displayName = 'AppLayoutHeaderNarrowMainNavigation';

export default withThemeProps(themeSettings)(AppLayoutHeaderNarrowMainNavigation);
