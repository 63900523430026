import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';

const hostUri: string = process.env.RAZZLE_API_URI || '';
const secureClientId: string = process.env.RAZZLE_AUTH_CLIENT_ID || '';

const apiClientApp: AxiosInstance = axios.create({
  baseURL: hostUri,
  timeout: 5000,
  paramsSerializer: (params) => qs.stringify(params),
  headers: {
    common: {
      'x-sx-client-id': secureClientId,
    },
  },
});

const jsonParserReviver = (key: string, value: any) => {
  const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
  const reMsAjax = /^\/Date\((d|-|.*)\)[/|\\]$/;
  if (typeof value === 'string') {
    if (reISO.exec(value)) {
      return new Date(value);
    }

    const a = reMsAjax.exec(value);
    if (a) {
      const b = a[1].split(/[-+,.]/);
      return new Date(b[0] ? +b[0] : 0 - +b[1]);
    }
  }
  return value;
};

const sanitizeData = (data: any) => {
  return JSON.parse(JSON.stringify(data), jsonParserReviver);
};

/**
 * Vraci normalizovanou URI.
 *
 * @param {string} path
 *
 * @returns String root pathy bez duplicitnich lomitek
 */
const sanitizeUrl = (path = '') => {
  const result = path.replace(/^\//, '').replace(/\/+/g, '/').replace(/\/$/, '');

  return `/${result}/`;
};

const resultData = async (responsePromise: Promise<AxiosResponse>) => {
  const { data } = await responsePromise;
  return data ? sanitizeData(data) : data;
};

export const replaceRouteParams = <P extends {}>(
  path: string,
  params: P | null | undefined,
): [string, object | undefined] => {
  const replaced: Array<string | number> = [];
  const inp = params || ({} as P);

  const result = sanitizeUrl(
    path.replace(/\./g, '/').replace(/(:(\w+))/g, (str, keyWithColon, key) => {
      replaced.push(key);
      return encodeURIComponent(inp[key]);
    }),
  );

  const nextParams = Object.keys(inp).reduce(
    (np, key) => ({ ...np, ...(replaced.includes(key) ? {} : { [key]: inp[key] }) }),
    {},
  );
  return [result, params ? nextParams : undefined];
};

const buildUrlString = <R, P extends {}>(
  path: string,
  params: P | null | undefined,
  call: (url: string, data: object | undefined) => Promise<R>,
): Promise<R> => {
  const [url, data] = replaceRouteParams(path, params);
  return call(url, data);
};

const urlWithQueryString = (url: string, data: object | undefined) =>
  data && Object.keys(data).length ? `${url}${qs.stringify(data, { addQueryPrefix: true })}` : url;

const apiClient: ApiClient = {
  get<R, P extends {}>(path: string, params?: P | null | undefined, options?: AxiosRequestConfig): Promise<R> {
    return resultData(
      buildUrlString(path, params, (url, data) => apiClientApp.get(urlWithQueryString(url, data), options)),
    );
  },

  post<R, P extends {}>(path: string, params: P | null | undefined, options?: AxiosRequestConfig): Promise<R> {
    return resultData(buildUrlString(path, params, (url, data) => apiClientApp.post(url, data, options)));
  },

  put<R, P extends {}>(path: string, params: P | null | undefined, options?: AxiosRequestConfig): Promise<R> {
    return resultData(buildUrlString(path, params, (url, data) => apiClientApp.put(url, data, options)));
  },

  delete<R, P extends {}>(path: string, params: P | null | undefined, options?: AxiosRequestConfig): Promise<R> {
    return resultData(
      buildUrlString(path, params, (url, data) => apiClientApp.delete(urlWithQueryString(url, data), options)),
    );
  },
};

export default function createApiClient(settings?: ApiClientSettings): ApiClient {
  if (settings) {
    if (settings.accessToken && !apiClientApp.defaults.headers.common['Authorization']) {
      apiClientApp.defaults.headers.common['Authorization'] = `Bearer ${settings.accessToken}`;
    } else if (!settings.accessToken && apiClientApp.defaults.headers.common['Authorization']) {
      delete apiClientApp.defaults.headers.common['Authorization'];
    }
  }

  return apiClient;
}

export interface ApiClientSettings {
  accessToken?: string;
}

export interface ApiClient {
  delete: <R, P extends {}>(path: string, params: P | null | undefined, options?: AxiosRequestConfig) => Promise<R>;
  get: <R, P extends {}>(path: string, params?: P | null | undefined, options?: AxiosRequestConfig) => Promise<R>;
  post: <R, P extends {}>(path: string, params: P | null | undefined, options?: AxiosRequestConfig) => Promise<R>;
  put: <R, P extends {}>(path: string, params: P | null | undefined, options?: AxiosRequestConfig) => Promise<R>;
}
