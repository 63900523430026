import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import dateFormatter from '@sprinx/formatting/dateFormatter';
import React from 'react';
import { BranchListItem } from '../../api/articles/Branch';
import {
  TireChangeDateListItem,
  TireChangeDayTimeListItem,
  TiresChangeDateListRequest,
  TiresChangeRegisterRequest,
} from '../../api/tiresChange';
import TireChangeDurationPreview from './TireChangeDurationPreview';
import TireChangeWizzardStepper from './TireChangeWizzardStepper';

export interface TireChangeWizzardStep4Props {
  branch: BranchListItem;
  branchNumber: TiresChangeDateListRequest['kodProvozovny'];
  onClose: () => void;
  onNext: (values: TiresChangeRegisterRequest) => void;
  onPrev: () => void;
  personInfo: {
    emailAddress: string;
    personName: string;
    phoneNumber: string;
  };
  requestInfo: TiresChangeDateListRequest;
  selectedDay: TireChangeDateListItem;
  selectedTime: TireChangeDayTimeListItem;
  titlePrefix: string;
}

export type TireChangeWizzardStep4ClassKey = 'root' | 'actions' | 'actionsRight' | 'summary' | 'note';

const useStyles = makeStyles((theme: Theme) =>
  createStyles<TireChangeWizzardStep4ClassKey, {}>({
    root: {},
    actions: {
      justifyContent: 'unset',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
        alignItems: 'stretch',
        '& > button': {
          margin: theme.spacing(0.5, 0),
          marginLeft: '0 !important',
        },
      },
    },
    actionsRight: {
      marginLeft: 'auto !important',
    },
    summary: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      border: 'solid 1px',
      borderColor: theme.palette.divider,
    },
    note: {
      border: '1px solid',
      borderColor: theme.palette.warning.dark,
      padding: theme.spacing(1, 2),
      '& > p': {
        ...theme.typography.body1,
        fontSize: theme.typography.pxToRem(10),
      },
    },
  }),
);
const TireChangeWizzardStep4: React.FC<TireChangeWizzardStep4Props> = ({
  branch,
  branchNumber,
  onClose,
  onNext,
  onPrev,
  personInfo,
  requestInfo,
  selectedDay,
  selectedTime,
  titlePrefix,
}) => {
  const classes = useStyles();

  return (
    <>
      <DialogTitle>{titlePrefix}</DialogTitle>
      <TireChangeWizzardStepper activeStep={3} />
      <DialogContent>
        <div className={classes.summary}>
          <span>
            Jméno a příjmení: <strong>{personInfo.personName}</strong>
          </span>
          <span>
            Email: <strong>{personInfo.emailAddress}</strong>
          </span>
          <span>
            Telefon: <strong>{personInfo.phoneNumber}</strong>
          </span>
          <span>
            Storex - provozovna: <strong>{branch.title}</strong>
          </span>
          <span>
            Vybrané datum a čas:{' '}
            <strong>{`${dateFormatter(selectedDay.datum)} - ${selectedTime.hodina
              .toString(10)
              .padStart(2, '0')}:${selectedTime.minuta.toString(10).padStart(2, '0')}`}</strong>
          </span>
          <TireChangeDurationPreview params={requestInfo} />
          <span>
            Registrační značka vozidla: <strong>{requestInfo.spz}</strong>
          </span>
          <span>
            Typ auta:{' '}
            <strong>
              {{ 1: 'Osobní', 2: 'SUV+VAN', 3: 'VAN s 6 pneumatikami', 4: 'S pneu typu RUNFLAT' }[requestInfo.typAuta]}
            </strong>
          </span>
          <span>
            Typ disku: <strong>{{ 1: 'ALU', 2: 'Plech' }[requestInfo.typDisku]}</strong>
          </span>
          <span>
            Velikost disku: <strong>{{ 1: '17" a menší', 2: '18" a větší' }[requestInfo.velikostDisku]}</strong>
          </span>
          {requestInfo.dokladOUskladneni && (
            <span>
              Doklad o uskladnění: <strong>{requestInfo.dokladOUskladneni}</strong>
            </span>
          )}
          <span>
            Typ práce s disky:{' '}
            <strong>{{ 1: 'Mám kompletní kola', 2: 'Požaduji montáž na disk' }[requestInfo.typPraceSDisky]}</strong>
          </span>
        </div>
        <div className={classes.note}>
          Náhradní sadu kolových šroubů ev. matic a oříšek pro bezpečnostní šrouby prosíme připravte před montáží k
          řadící páce, tam je také najdete po montáži. <br />
          Děkujeme.
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button color='secondary' variant='outlined' onClick={() => onPrev()}>
          Předchozí
        </Button>
        <Button className={classes.actionsRight} color='secondary' variant='outlined' onClick={() => onClose()}>
          Zrušit
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            onNext({
              date: selectedDay.datum,
              emailAddress: personInfo.emailAddress,
              kodProvozovny: branchNumber,
              personName: personInfo.personName,
              phoneNumber: personInfo.phoneNumber,
              spz: requestInfo.spz,
              time: selectedTime,
              typAuta: requestInfo.typAuta,
              typDisku: requestInfo.typDisku,
              typPraceSDisky: requestInfo.typPraceSDisky,
              velikostDisku: requestInfo.velikostDisku,
              dokladOUskladneni: requestInfo.dokladOUskladneni,
            });
          }}
        >
          Objednat se na zvolený datum a čas
        </Button>
      </DialogActions>
    </>
  );
};

TireChangeWizzardStep4.displayName = 'TireChangeWizzardStep4';

export default TireChangeWizzardStep4;
