import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import formFieldError from '@sprinx/react-forms/formFieldError';
import FormikExtended from '@sprinx/react-forms/FormikExtended';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import CheckboxField from '@sprinx/react-mui-fields/CheckboxField';
import React from 'react';
import MaskedInput from 'react-text-mask';
import { BranchListItem } from '../../api/articles/Branch';
import { TiresChangeDateListRequest } from '../../api/tiresChange';
import FormInDialog from '../FormInDialog';
import StorexSelectFormField from '../StorexSelectFormField';
import StorexTextField from '../StorexTextField';
import diskAluImgSrc from './disk-alu.png';
import diskPlechImgSrc from './disk-plech.png';
import Tooltip from './LightTooltip';
import TireChangeDurationPreview from './TireChangeDurationPreview';
import TireChangeWizzardStepper from './TireChangeWizzardStepper';

export type TireChangeWizzardStep1ClassKey =
  | 'selectField'
  | 'field'
  | 'actions'
  | 'actionsRight'
  | 'textField'
  | 'textFieldContainer'
  | 'em'
  | 'helperText'
  | 'summary'
  | 'note';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<TireChangeWizzardStep1ClassKey, {}>({
      selectField: {
        minWidth: 250,
        [theme.breakpoints.down('xs')]: {
          minWidth: '100%',
          margin: theme.spacing(1, 0),
        },
      },
      field: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          margin: theme.spacing(1, 0),
          '& > span': {
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: theme.typography.pxToRem(15),
          },
        },
      },
      actions: {
        justifyContent: 'unset',
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column-reverse',
          alignItems: 'stretch',
          '& > button': {
            margin: theme.spacing(0.5, 0),
            marginLeft: '0 !important',
          },
        },
      },
      actionsRight: {
        marginLeft: 'auto',
      },
      textField: {
        minWidth: 250,
        '& input': {
          background: theme.palette.common.white,
        },
        [theme.breakpoints.down('xs')]: {
          minWidth: '100%',
        },
      },
      textFieldContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        '& > label': {
          position: 'relative',
          transform: 'translate(0,0)',
          color: 'inherit',
        },
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
          alignItems: 'stretch',
          justifyContent: 'center',
          '& > label': {
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: theme.typography.pxToRem(15),
            textAlign: 'center',
          },
        },
      },
      em: {
        textDecoration: 'underline',
        textDecorationStyle: 'dotted',
        textDecorationThickness: '2px',
        textDecorationColor: theme.palette.primary.main,
      },
      helperText: {
        marginBottom: theme.spacing(2),
      },
      summary: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(2, 0),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        border: 'solid 1px',
        borderColor: theme.palette.divider,
      },
      note: {
        border: '1px solid',
        borderColor: theme.palette.warning.dark,
        padding: theme.spacing(1, 2),
        '& > p': {
          ...theme.typography.body1,
          fontSize: theme.typography.pxToRem(10),
        },
      },
    }),
  { name: 'TireChangeWizzardStep1ClassKey' },
);

export interface TireChangeWizzardStep1Props {
  branch: BranchListItem;
  initialValues: TiresChangeDateListRequest;
  onClose: () => void;
  onNext: (values: TiresChangeDateListRequest) => void;
  titlePrefix: string;
}

const TireChangeWizzardStep1: React.FC<TireChangeWizzardStep1Props> = ({
  branch,
  initialValues,
  onClose,
  onNext,
  titlePrefix,
}) => {
  const classes = useStyles();
  const t = useTranslate();

  return (
    <FormikExtended<TiresChangeDateListRequest>
      translator={t}
      initialValues={initialValues}
      validationFastest
      validationSchema={{
        // dokladOUskladneni: { type: 'string', optional: true, pattern: 'USKL-\\d\\d-\\d\\d\\d\\d\\d', message: 'BOOOO' },
        dokladOUskladneni: {
          type: 'custom',
          optional: true,
          check: (value: any, errors: Array<any>) => {
            if (typeof value !== 'string' || !new RegExp('USKL-\\d\\d-\\d\\d\\d\\d\\d').test(value)) {
              errors.push({
                type: 'dokladOUskladneni',
                message: 'reservationForm/errorMessages/dokladOUskladneni',
                expected: null,
                actual: null,
              });
            }
            return value;
          },
        },
        kodProvozovny: { type: 'enum', values: [1, 2, 4, 6, 7, 8, 9, 10] },
        spz: { type: 'string', empty: false },
        typAuta: { type: 'enum', values: [1, 2, 3, 4] },
        typDisku: { type: 'enum', values: [1, 2] },
        typPraceSDisky: { type: 'enum', values: [1, 2] },
        velikostDisku: { type: 'enum', values: [1, 2] },
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        onNext(values);
      }}
      validateOnChange={false}
    >
      {(frm) => (
        <FormInDialog onSubmit={frm.onSubmit}>
          <DialogTitle>{titlePrefix}</DialogTitle>
          <TireChangeWizzardStepper activeStep={0} />
          <DialogContent>
            <div className={classes.summary}>
              <span>
                Storex - provozovna: <strong>{branch.title}</strong>
              </span>
            </div>

            <StorexTextField
              className={classes.field}
              label='Registrační značka vozidla'
              value={frm.values.spz}
              error={formFieldError(frm.errors.spz, frm.touched.spz)}
              onBlur={() => {
                frm.onBlur('spz')(true);
              }}
              onChange={(nv: any) => {
                frm.onChange('spz')(nv || '');
              }}
              fullWidth
              required
            />

            <StorexSelectFormField
              className={classes.field}
              label='Typ auta'
              helperText={
                <>
                  <span>
                    <strong>VAN</strong> = dodávka
                  </span>
                  {', '}
                  <span>
                    <strong>RUNFLAT</strong> = pneumatika navržená pro jízdu s defektem
                  </span>
                </>
              }
              value={frm.values.typAuta as any}
              error={formFieldError(frm.errors.typAuta, frm.touched.typAuta)}
              onBlur={() => {
                frm.onBlur('typAuta')(true);
              }}
              onChange={(nv: any) => {
                frm.onChange('typAuta')(nv || 1);
              }}
              fullWidth
              required
            >
              <MenuItem value={1}>Osobní</MenuItem>
              <MenuItem value={2}>SUV+VAN</MenuItem>
              <MenuItem value={3}>VAN s 6 pneumatikami</MenuItem>
              <MenuItem value={4}>S pneu typu RUNFLAT</MenuItem>
            </StorexSelectFormField>

            <StorexSelectFormField
              className={classes.field}
              label='Typ disku'
              helperText={
                <>
                  Disky, na které budete přezouvat{' '}
                  <Tooltip
                    arrow
                    title={
                      <span>
                        <img src={diskAluImgSrc} style={{ width: 200, height: 192 }} alt='ALU disk' />
                      </span>
                    }
                  >
                    <span className={classes.em}>
                      <strong>ALU</strong> = hliníkové
                    </span>
                  </Tooltip>
                  {', '}
                  <Tooltip
                    arrow
                    title={
                      <span>
                        <img src={diskPlechImgSrc} style={{ width: 200, height: 192 }} alt='Plech disk' />
                      </span>
                    }
                  >
                    <span className={classes.em}>
                      <strong>Plech</strong> = plechové
                    </span>
                  </Tooltip>
                </>
              }
              value={frm.values.typDisku as any}
              error={formFieldError(frm.errors.typDisku, frm.touched.typDisku)}
              onBlur={() => {
                frm.onBlur('typDisku')(true);
              }}
              onChange={(nv: any) => {
                frm.onChange('typDisku')(nv || 1);
              }}
              fullWidth
              required
            >
              <MenuItem value={1}>ALU</MenuItem>
              <MenuItem value={2}>Plech</MenuItem>
            </StorexSelectFormField>

            <StorexSelectFormField
              className={classes.field}
              label='Velikost disku'
              value={frm.values.velikostDisku as any}
              error={formFieldError(frm.errors.velikostDisku, frm.touched.velikostDisku)}
              onBlur={() => {
                frm.onBlur('velikostDisku')(true);
              }}
              onChange={(nv: any) => {
                frm.onChange('velikostDisku')(nv || 1);
              }}
              fullWidth
              required
            >
              <MenuItem value={1}>{'17" a menší'}</MenuItem>
              <MenuItem value={2}>{'18" a větší'}</MenuItem>
            </StorexSelectFormField>

            <div className={classes.field}>
              <CheckboxField
                switch
                label='Kola mám uskladněná ve Storex'
                value={frm.values.dokladOUskladneni !== undefined}
                onChange={(v: boolean) => {
                  if (v) {
                    frm.onChange('dokladOUskladneni')('USKL-');
                  } else {
                    frm.onChange('dokladOUskladneni')(undefined);
                  }
                  frm.onBlur('dokladOUskladneni')(true);
                }}
              />
              <Collapse in={frm.values.dokladOUskladneni !== undefined}>
                <StorexTextField
                  label='Doklad o uskladnění'
                  helperText={
                    <>
                      Máte-li kola uskladněná ve Storex, zadejte zde, prosím, číslo uskladnění. Číslo uskladnění je ve
                      formátu &quot;USKL-00-00000&quot;.
                    </>
                  }
                  InputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                  value={frm.values.dokladOUskladneni}
                  error={formFieldError(frm.errors.dokladOUskladneni, frm.touched.dokladOUskladneni)}
                  onBlur={() => {
                    frm.onBlur('dokladOUskladneni')(true);
                  }}
                  onChange={(nv: string) => {
                    frm.onChange('dokladOUskladneni')(nv);
                  }}
                  fullWidth
                />
              </Collapse>
            </div>

            {/* !!! Nasledujuci kus kodu chceme ponechat pre pripadne buduce pouzitie.

            <StorexSelectFormField
              className={classes.field}
              helperText={
                <>
                  <span>
                    <strong>Mám kompletní kola</strong> = mám připravená kompletní kola, montáž na disk není třeba
                  </span>
                  {', '}
                  <span>
                    <strong>Požaduji montáž na disk</strong> = právě jste objednal(a) nové pneumatiky v e-shopu
                  </span>
                </>
              }
              label='Typ práce s disky'
              value={frm.values.typPraceSDisky as any}
              error={frm.formFieldError(frm.errors.typPraceSDisky, frm.touched.typPraceSDisky)}
              onBlur={() => {
                frm.onBlur('typPraceSDisky')(true);
              }}
              onChange={(nv: any) => {
                frm.onChange('typPraceSDisky')(nv || 1);
              }}
              fullWidth
              required
            >
              <MenuItem value={1}>{'Mám kompletní kola'}</MenuItem>
              <MenuItem value={2}>{'Požaduji montáž na disk'}</MenuItem>
            </StorexSelectFormField> */}

            <TireChangeDurationPreview params={frm.values} />

            <div className={classes.note}>
              Náhradní sadu kolových šroubů ev. matic a oříšek pro bezpečnostní šrouby prosíme připravte před montáží k
              řadící páce, tam je také najdete po montáži. <br />
              Děkujeme.
            </div>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button
              className={classes.actionsRight}
              color='secondary'
              variant='outlined'
              onClick={() => onClose()}
              disabled={frm.isSubmitting}
            >
              Zrušit
            </Button>
            <Button color='primary' variant='contained' onClick={() => frm.onSubmit()} disabled={frm.isSubmitting}>
              Vybrat den
            </Button>
          </DialogActions>
        </FormInDialog>
      )}
    </FormikExtended>
  );
};

TireChangeWizzardStep1.displayName = 'TireChangeWizzardStep1';

export default TireChangeWizzardStep1;

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}
function TextMaskCustom(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['U', 'S', 'K', 'L', '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
