import React from 'react';
import { Route } from 'react-router-dom';

export function InternalError({ error }: { error?: any }): JSX.Element {
  const logError = (...args: any[]) => {
    console.error('Internal Error', ...args);
  };

  if (error instanceof Error) {
    logError(error.message, error);
  } else {
    logError(error);
  }

  return <div>Something went wrong...</div>;
}

export default function InternalErrorPage(): JSX.Element {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = 500;
        return <InternalError />;
      }}
    />
  );
}
