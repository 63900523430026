import { makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import AppLayoutFooterPartners from './AppLayoutFooterPartners';
import AppLayoutFooterWideNavigation from './AppLayoutFooterWideNavigation';

export type AppLayoutFooterNarrowProps = StandardDivProps<AppLayoutFooterNarrowClassKey>;

export type AppLayoutFooterNarrowClassKey = 'root';

const themeSettings = { name: 'AppLayoutFooterNarrow' };
const useStyles = makeStyles(
  (/* theme */) => ({
    root: {},
  }),
  themeSettings,
);

const AppLayoutFooterNarrow: React.FC<AppLayoutFooterNarrowProps> = React.forwardRef(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <AppLayoutFooterPartners />
        <AppLayoutFooterWideNavigation />
      </div>
    );
  },
);

AppLayoutFooterNarrow.displayName = 'AppLayoutFooterNarrow';

export default withThemeProps(themeSettings)(AppLayoutFooterNarrow);
