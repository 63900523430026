import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import React from 'react';

export interface TireChangeWizzardStep5Props {
  onClose: () => void;
  onNewDate: () => void;
  onRetrySave: () => void;
  saveResult: { status: 'success' } | { msg: string | Error; status: 'error' } | { status: 'notSaved' };
  titlePrefix: string;
}

export type TireChangeWizzardStep5ClassKey = 'root' | 'actions' | 'actionsRight';

const useStyles = makeStyles((theme: Theme) =>
  createStyles<TireChangeWizzardStep5ClassKey, {}>({
    root: {},
    actions: {
      justifyContent: 'unset',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
        alignItems: 'stretch',
        '& > button': {
          margin: theme.spacing(0.5, 0),
          marginLeft: '0 !important',
        },
      },
    },
    actionsRight: {
      marginLeft: 'auto !important',
    },
  }),
);

const TireChangeWizzardStep5: React.FC<TireChangeWizzardStep5Props> = ({
  onClose,
  onNewDate,
  onRetrySave,
  saveResult,
  titlePrefix,
}) => {
  if (saveResult.status === 'error') {
    if (process.env.NODE_ENV !== 'production') {
      console.error('Tire change saving error.', saveResult.msg);
    }
  }

  return (
    <>
      <DialogTitle>{titlePrefix}</DialogTitle>
      {saveResult.status === 'notSaved' ? (
        <TireChangeWizzardStep5NotSaved onClose={onClose} onNewDate={onNewDate} />
      ) : (
        <TireChangeWizzardStep5Error onClose={onClose} onRetrySave={onRetrySave} />
      )}
    </>
  );
};

TireChangeWizzardStep5.displayName = 'TireChangeWizzardStep5';

export default TireChangeWizzardStep5;

const TireChangeWizzardStep5NotSaved: React.FC<{
  onClose: () => void;
  onNewDate: () => void;
}> = ({ onClose, onNewDate }) => {
  const classes = useStyles();

  return (
    <>
      <DialogContent>
        <Alert severity='warning'>Vybraný termín je obsazený, vyberte si prosím nový.</Alert>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button color='secondary' variant='outlined' onClick={() => onNewDate()}>
          Vyberte nový termín
        </Button>
        <Button className={classes.actionsRight} color='secondary' variant='outlined' onClick={() => onClose()}>
          Zrušit
        </Button>
      </DialogActions>
    </>
  );
};

TireChangeWizzardStep5NotSaved.displayName = 'TireChangeWizzardStep5NotSaved';

const TireChangeWizzardStep5Error: React.FC<{
  onClose: () => void;
  onRetrySave: () => void;
}> = ({ onClose, onRetrySave }) => {
  const classes = useStyles();

  return (
    <>
      <DialogContent>
        <Alert severity='error'>
          Došlo k chybě při uložení objednávky s rezervací přezutí. Zkuste uložit ještě jednou.
        </Alert>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button color='secondary' variant='outlined' onClick={() => onRetrySave()}>
          Opakovat uložení
        </Button>
        <Button className={classes.actionsRight} color='secondary' variant='outlined' onClick={() => onClose()}>
          Zrušit
        </Button>
      </DialogActions>
    </>
  );
};

TireChangeWizzardStep5Error.displayName = 'TireChangeWizzardStep5Error';
