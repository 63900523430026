import { After, ensureReady } from '@jaredpalmer/after';
import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ForwardAppProps } from './@sprinx/react-after-razzle/ForwardAppProps';
import { restoreAppState } from './@sprinx/react-after-razzle/stateStore';
import App from './App';
import routes from './routes';

function Main({ data }: any) {
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  const appProps = restoreAppState('preloaded_app_props');

  return (
    <BrowserRouter>
      <App initialAppProps={appProps}>
        <ForwardAppProps>
          {({ getAppProps }) => (
            <>
              {/* @ts-ignore Component After is declared as cont without ability to override props. This maybe will fixed in AfterJs declarations in later versions.  */}
              <After data={data} routes={routes} transitionBehavior='blocking' getAppProps={getAppProps} />
            </>
          )}
        </ForwardAppProps>
      </App>
    </BrowserRouter>
  );
}

ensureReady(routes as any).then((data) => hydrate(<Main data={data} />, document.getElementById('root')));

if (module.hot) {
  module.hot.accept();
}
