import { atom, selector, useRecoilValue } from 'recoil';
import {
  ContactCompany,
  ContactCompanyRecord,
  ContactOffice,
  ContactOfficeRecord,
} from '../@sprinx/knihovka-types/contacts';
import { GlobalStateRegister } from '../@sprinx/react-after-razzle/stateStore';
import { authState } from './appState';

export const shoppingCustomerSelector = selector<ShoppingCustomerContact | undefined>({
  key: 'shoppingCustomer',
  get: ({ get }) => {
    const auth = get(authState);
    return auth?.profile?.customer;
  },
});
export const useShoppingCustomer = (): ShoppingCustomerContact | undefined => {
  return useRecoilValue(shoppingCustomerSelector);
};

export const shoppingSelectedDeliveryPlaceState = GlobalStateRegister.register(
  atom<ShoppingCustomerContact | undefined>({
    key: 'shoppingSelectedDeliveryPlace',
    default: undefined,
  }),
);

export const shoppingIsDeliveryPlaceSelectedSelector = selector<boolean>({
  key: 'shoppingIsDeliveryPlaceSelectedSelector',
  get: ({ get }) => {
    return !!get(shoppingSelectedDeliveryPlaceState);
  },
});

export const shoppingCustomerDeliveryPlacesSelector = selector({
  key: 'shoppingCustomerDeliveryPlaces',
  get: ({ get }) => {
    const auth = get(authState);
    return auth?.profile?.deliveryPlaces;
  },
});

export const shoppingEnabledDynamicPricingRulesState = atom<string[] | undefined>({
  key: 'shoppingEnabledDynamicPricingRules',
  default: undefined,
});

export type ShoppingCustomerContact =
  | ContactCompany<
      ContactCompanyRecord<ShoppingCustomerContactExtra>,
      never,
      'id' | 'name' | 'type' | 'avatar' | 'extra' | 'contactGroup' | 'priceGroup' | 'postaddresses' | 'regNo' | 'taxNo'
    >
  | ContactOffice<
      ContactOfficeRecord<ShoppingCustomerContactExtra>,
      never,
      'id' | 'name' | 'type' | 'avatar' | 'extra' | 'contactGroup' | 'priceGroup' | 'postaddresses' | 'parentCompany'
    >;

export interface ShoppingCustomerContactExtra {
  customerNo?: string;
  locationDelivery?: string;
  locationDocument?: string;
  postaddresses?: string;
  regNo?: string;
  salesman?: string;
  taxNo?: string;
}
