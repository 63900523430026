import MuiLink from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import phoneParser from '@sprinx/formatting/phoneParser';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';

export type AppLayoutHeaderWideMainNavigationInfoProps = StandardDivProps<AppLayoutHeaderWideMainNavigationInfoClassKey>;

export type AppLayoutHeaderWideMainNavigationInfoClassKey = 'root';

const themeSettings = { name: 'AppLayoutHeaderWideMainNavigationInfo' };
const useStyles = makeStyles(
  (/* theme */) => ({
    root: {
      color: 'inherit',
      '& span': {
        color: 'inherit',
        marginRight: '1ch', // 1 x of width of '0'
      },
      '& a': {
        color: 'inherit',
      },
    },
  }),
  themeSettings,
);

const AppLayoutHeaderWideMainNavigationInfo: React.FC<AppLayoutHeaderWideMainNavigationInfoProps> = React.forwardRef(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const t = useTranslate();
    const phoneNumber = phoneParser(t('appMainMenuInfo/phone'));

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <span>{t('appMainMenuInfo/call')}</span>
        <MuiLink component='a' href={phoneNumber?.getURI()}>
          {phoneNumber?.formatInternational()}
        </MuiLink>
      </div>
    );
  },
);

AppLayoutHeaderWideMainNavigationInfo.displayName = 'AppLayoutHeaderWideMainNavigationInfo';

export default withThemeProps(themeSettings)(AppLayoutHeaderWideMainNavigationInfo);
