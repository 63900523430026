import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Link, { LinkProps } from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import formFieldError from '@sprinx/react-forms/formFieldError';
import FormikExtended from '@sprinx/react-forms/FormikExtended';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import CheckboxField from '@sprinx/react-mui-fields/CheckboxField';
import clsx from 'clsx';
import React from 'react';
import { contactFormCreateInitialValues, ContactFormValues, useContactFormSend } from '../../../api/contactForm';
import StorexTextField from '../../../components/StorexTextField';

export interface AppLayoutFooterContactFormProps extends Omit<LinkProps<'button'>, 'onClick'> {
  children?: React.ReactNode;
}

export const AppLayoutFooterContactForm = React.forwardRef<AppLayoutFooterContactFormProps, any>(
  ({ children, ...rest }, ref) => {
    const [openForm, setOpenForm] = React.useState(false);
    const [openAgreement, setOpenAgreement] = React.useState(false);

    const handleOpenForm = React.useCallback(() => {
      setOpenForm(true);
    }, []);

    const handleCloseForm = React.useCallback(() => {
      setOpenForm(false);
    }, []);

    const handleSaveForm = useContactFormSend();

    const handleFormReleased = React.useCallback(async (r: any): Promise<void> => {
      setOpenForm(false);
      return r;
    }, []);

    const handleOpenAgreement = React.useCallback(() => {
      setOpenAgreement(true);
    }, []);

    const handleCloseAgreement = React.useCallback(() => {
      setOpenAgreement(false);
    }, []);

    return (
      <>
        <Link ref={ref} component={'button'} {...rest} onClick={handleOpenForm}>
          {children}
        </Link>
        {openForm && (
          <AppLayoutFooterContactFormDialog
            open
            onClose={handleCloseForm}
            onFormReleased={handleFormReleased}
            onOpenAgreement={handleOpenAgreement}
            onSave={handleSaveForm}
          />
        )}
        {openAgreement && <DdprAgreementTextDialog open={openAgreement} onClose={handleCloseAgreement} />}
      </>
    );
  },
);

AppLayoutFooterContactForm.displayName = 'AppLayoutFooterContactForm';

export default AppLayoutFooterContactForm;

export interface AppLayoutFooterContactFormDialogProps {
  classes?: Partial<Record<AppLayoutFooterContactFormDialogClassKey, string>>;
  className?: string;
  onClose: () => void;
  onFormReleased: (arg: any) => Promise<any>;
  onOpenAgreement: () => void;
  onSave: (values: ContactFormValues) => Promise<boolean>;
  open: boolean;
}

export type AppLayoutFooterContactFormDialogClassKey = 'root' | 'checkboxButton' | 'title' | 'subtitle';

const themeSettings = { name: 'AppLayoutFooterContactFormDialog' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<AppLayoutFooterContactFormDialogClassKey, {}>({
      root: { ...theme.typography.body1 },
      checkboxButton: { ...theme.typography.body1 },
      title: { '& h2': { display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' } },
      subtitle: { marginBottom: theme.spacing(1) },
    }),
  themeSettings,
);

const AppLayoutFooterContactFormDialog: React.FC<AppLayoutFooterContactFormDialogProps> = ({
  className,
  classes: pClasses,
  onClose: handleClose,
  onFormReleased: handleFormReleased,
  onOpenAgreement: handleOpenAgreement,
  onSave: handleSave,
  open,
}) => {
  const t = useTranslate();
  const classes = useStyles({ classes: pClasses });

  return (
    <FormikExtended<ContactFormValues>
      translator={t}
      initialValues={contactFormCreateInitialValues()}
      onSave={handleSave}
      onFormReleased={handleFormReleased}
      validationFastest={{
        messages: {
          emailEmpty: [true, 'Nesmí být prázdný.'],
          gdprAgreement: [true, 'Pro odeslání formuláře musíte odsouhlasit podmínky!'],
        },
        // rules: [
        //   [
        //     'gdprAgreement',
        //     function gdprAgreementRule({ messages }) {
        //       return {
        //         source: `
        //         if (value !== false) {
        //           ${this.makeError({ type: 'gdprAgreement', messages })}
        //         }
        //         return value;
        //       `,
        //       };
        //     },
        //     [true, 'Pro odeslání formuláře musíte odsouhlasit podmínky!'],
        //   ],
        // ],
      }}
      validationSchema={{
        firstName: { type: 'string', empty: false },
        lastName: { type: 'string', empty: false },
        email: { type: 'email', empty: false },
        message: { type: 'string', empty: false },
        gdprAgreement: {
          type: 'custom',
          check(value: any, errors: any[]) {
            if (value !== true) {
              errors.push({ type: 'gdprAgreement' });
            }
            return value;
          },
        },
      }}
      validateOnChange={false}
    >
      {({ isSubmitting, onSubmit, onChange: handleChange, onBlur: handleBlur, values, errors, touched }) => (
        <Dialog className={clsx(classes.root, className)} open={open} onClose={handleClose}>
          <form onSubmit={onSubmit} noValidate>
            <DialogTitle className={classes.title}>
              Napište nám
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div className={classes.subtitle}>
                Máte dotazy, či připomínky? <br />
                Napište nám, budeme Vás obratem kontaktovat.
              </div>
              <StorexTextField
                label='Jméno'
                value={values.firstName}
                error={formFieldError(errors.firstName, touched.firstName)}
                onChange={handleChange('firstName')}
                onBlur={handleBlur('firstName')}
                required
                fullWidth
                variant='outlined'
              />
              <StorexTextField
                label='Příjmení'
                value={values.lastName}
                error={formFieldError(errors.lastName, touched.lastName)}
                onChange={handleChange('lastName')}
                onBlur={handleBlur('lastName')}
                required
                fullWidth
                variant='outlined'
              />
              <StorexTextField
                label='E-mail'
                value={values.email}
                error={formFieldError(errors.email, touched.email)}
                onChange={handleChange('email')}
                onBlur={handleBlur('email')}
                required
                fullWidth
                variant='outlined'
              />
              <StorexTextField
                label='Zpráva pro nás'
                value={values.message}
                error={formFieldError(errors.message, touched.message)}
                onChange={handleChange('message')}
                onBlur={handleBlur('message')}
                required
                multiline
                rows={4}
                fullWidth
                variant='outlined'
              />
              <CheckboxField
                label={
                  <>
                    Přečetl/a jsem podmínky ochrany{' '}
                    <Link
                      component='button'
                      tabIndex={-1}
                      onClick={(e: any) => {
                        e.preventDefault();
                        handleOpenAgreement();
                      }}
                      className={classes.checkboxButton}
                    >
                      osobních údajů
                    </Link>
                  </>
                }
                value={values.gdprAgreement}
                error={formFieldError(errors.gdprAgreement, touched.gdprAgreement)}
                onChange={handleChange('gdprAgreement')}
                onBlur={handleBlur('gdprAgreement')}
                required
              />
            </DialogContent>
            <DialogActions>
              <Button color='primary' variant='text' onClick={handleClose}>
                Zavřít
              </Button>
              <Button color='primary' variant='contained' type='submit' disabled={isSubmitting}>
                Odeslat
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </FormikExtended>
  );
};

AppLayoutFooterContactFormDialog.displayName = 'AppLayoutFooterContactFormDialog';

const DdprAgreementTextDialog: React.FC<{ onClose: () => void; open: boolean }> = ({ onClose: handleClose, open }) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
      <DialogTitle>Souhlas s podínkami</DialogTitle>
      <DialogContent>
        <DialogContentText component='div'>
          <h3>Ochrana osobních údajů</h3>
          <h4>1. Jaké informace zpracováváme pro marketingové účely společnosti Storex FST?</h4>
          <p>
            Údaje, které budeme na základě Vašeho souhlasu zpracovávat, zahrnují: jméno a příjmení (u podnikatele i
            obchodní firmu, IČO a sídlo), kontaktní údaje, RZ a informace o využívání produktů a služeb (nákupní
            historii), dále pak údaje o poskytnutí tohoto souhlasu včetně informací o jeho případném odvolání.
          </p>
          <p>
            <strong>Základní identifikační údaje</strong>
          </p>
          <p>Zejména jméno, příjmení (u podnikatele i obchodní firmu, IČO a sídlo)</p>
          <p>
            <strong>Kontaktní údaje</strong>
          </p>
          <p>Zejména poštovní adresa, telefon a/nebo e-mail</p>
          <p>
            <strong>Informace o využívání produktů a služeb</strong>
          </p>
          <p>
            Pro lepší cílení v našich kampaních využíváme informace o tom, jaké produkty nebo služby jste u nás v
            minulosti využil a zda je využíváte i nadále.
          </p>
          <p>
            <strong>Informace z telefonických rozhovorů nebo jiné interakce mezi Vámi a námi</strong>
          </p>
          <p>
            V případě, že dojde k navázání kontaktu ať už z Vaší nebo naší strany, a to jak osobně, telefonicky nebo
            e-mailem a vyplyne-li z této vzájemné interakce nějaká důležitá informace, která by mohla pomoci při
            sestavování cílené nabídky, můžeme si tuto informaci uložit do našeho interního počítačového systému v
            podobě strukturované poznámky, a to například to, o čem jsme se spolu hovořili nebo jak jste reagoval na
            nabídku produktu – ať nám to nemusíte příště znovu opakovat.
          </p>
          <p>
            <strong>Informace ze sociálních sítí</strong>
          </p>
          <p>
            Při realizaci našich marketingových kampaní pracujeme například i s Facebookem („FB“). Data, která
            zveřejňujete na svojí hlavní stránce, u nás neskladujeme, ale využíváme je k lepšímu zacílení našich kampaní
            prostřednictvím služby od FB, tj. FB je pro nás jen kanálem k oslovení s přidanou službou zacílení. Pro
            lepší představu to funguje asi takto: do aplikace FB předáme e-mailové adresy našich klientů a spárujeme je
            s e-mailovými adresami, které uvádějí uživatelé na svých FB profilech. Pokud nastane shoda, na tyto klienty
            se aplikují další filtry, které si v aplikaci nadefinujeme (například „má dítě“, „rád cestuje“, „má mnoho
            přátel“ apod.). Výsledkem je, že těmto FB uživatelům se na jejich stránce zobrazí naše reklama.
          </p>
          <p>
            <strong>Externí zdroje</strong>
          </p>
          <p>
            Naše interní počítačové systémy využívají zejména pro urychlení přenosu dat i informace z externích zdrojů.
            Jedná se zejména o veřejně dostupné rejstříky, například: obchodní rejstřík, registr ekonomických subjektů,
            registr dlužníků, profesní registry apod.
          </p>
          <h4>2. Z jakých zdrojů tyto informace pocházejí?</h4>
          <p>
            Údaje zpracovávané v rámci souhlasu pocházejí z různých zdrojů. Nejčastěji je poskytne sám klient. V celé
            řadě případů si údaje k dalšímu zpracování vytváří společnost Storex FST (analýzy, reporty apod.). Využíváme
            i další informace, které získáme o zákazníkovi v rámci naší činnosti z veřejných zdrojů (například veřejné
            seznamy a rejstříky, internetové aplikace, sociální sítě, jiné veřejné informační zdroje).
          </p>
          <h4>3. Pro jaké účely tento souhlas udělujete?</h4>
          <p>
            Účelem zpracování osobních údajů se rozumí nabídka produktů a služeb poskytovaných společností Storex FST, a
            to i prostřednictvím elektronických kanálů, dále marketingové zpracování a případné analýzy s cílem
            přizpůsobit nabídku potřebám zákazníka a zkvalitnit poskytované služby.
          </p>
          <p>Ke komunikaci využíváme těchto kanálů – klasickou poštu, e-mail, telefon, SMS.</p>
          <p>
            Samotnému použití informací předchází jejich zpracování, jedná se zejména o tzv. marketingové zpracování
            dat. Pod tím si můžete představit statistické a matematické analýzy s cílem získat vhled do chování klienta
            a odhadnout jeho budoucí chování či obchodní potenciál, dále pak reporting apod. Zpracování může být jak
            manuální, tak automatizované.
          </p>
          <h4>4. Komu souhlas udělujete?</h4>
          <p>Váš souhlas udělujete společnosti Storex FST, spol. s r.o. – IČO 63146801</p>
          <h4>5. Kdo může Vaše údaje zpracovávat?</h4>
          <p>
            Regulace ochrany osobních údajů umožňuje, aby správce pověřil zpracováním osobních údajů zpracovatele.
            Zpracovatel osobních údajů je každý subjekt, který na základě zvláštního zákona nebo pověření či zmocnění
            správcem zpracovává osobní údaje. Tento postup používá v některých případech i společnost Storex FST při
            zpracování osobních údajů pro marketingové účely. V těchto případech je smluvně i regulací garantována
            stejná ochrana Vašich údajů, jako je tomu ze strany Storex FST. Mezi zpracovatele, které společnost Storex
            FST využívá pro zpracování osobních údajů pro marketingové účely, patří:
          </p>
          <ul>
            <li>Ing.Tomáš Mikulenka (IČO: 66075653, 17. listopadu 604/2, 400 10 Ústí nad Labem)</li>
          </ul>
          <h4>6. Jak dlouho osobní údaje uchováváme?</h4>
          <p>
            Souhlas udělujete společnosti Storex FST jen jednou a zůstává v platnosti a účinnosti primárně po dobu, než
            souhlas odvoláte. Údaje o tom, že jste nám souhlas udělil či jej odvolal, můžeme zpracovávat ještě po
            přiměřenou dobu po odvolání souhlasu za účelem prokázání, na jakém základě jsme Vám nabídku produktů či
            služeb předávali.
          </p>
          <p>
            V případě, že se naším zákazníkem nestanete (tj. nebude založen smluvní vztah se společností Storex FST),
            bude Váš souhlas platit 1 rok od jeho udělení. Po skončení platnosti a účinnosti souhlasu budou Vaše osobní
            údaje vymazány nebo zpracovávány pouze v rozsahu a pro účely, ke kterým není dle právních předpisů nutný
            souhlas.
          </p>
          <h4>7. Jak můžete souhlas udělit?</h4>
          <p>
            Souhlas můžete udělit osobně v prodejní síti společnosti Storex FST. Souhlas obsahuje dvě políčka:
            „Souhlasím“ a „Nesouhlasím“. Zaškrtnutím políčka „Souhlasím“ a podpisem dokumentu poskytujete souhlas se
            zpracováním Vašich osobních údajů k marketingovým účelům společnosti Storex FST (viz bod 4.).
          </p>
          <p>
            Zaškrtnutím políčka „Nesouhlasím“ a podpisem dokumentu vyjadřujete nesouhlas se zpracováním Vašich osobních
            údajů pro marketingové účely společnosti Storex FST (viz bod 4.) v rozsahu definovaném dokumentem.
          </p>
          <p>
            Pokud využíváte našich služeb jako fyzická osoba - nepodnikající i jako fyzická osoba - podnikatel stačí nám
            od Vás jeden souhlas. Tento se vztahuje k oběma subjektům.
          </p>
          <h4>8. Jak můžete svůj souhlas odvolat?</h4>
          <p>
            Souhlas je dobrovolný, můžete jej odmítnout udělit nebo udělený souhlas kdykoli odvolat, a to na kterékoliv
            z prodejen společnosti Storex FST. Neudělení souhlasu nebo jeho odvolání, nemá žádné důsledky na smluvní
            vztah mezi zákazníkem a společností Storex FST ani na využívání jejích produktů a služeb, pro které není
            tento souhlas potřebný.
          </p>
          <p>
            Souhlas můžete odvolat na kterékoliv z prodejen společnosti Storex FST. V případě odvolání souhlasu budeme
            vycházet z předpokladu, že si již nepřejete, abychom nadále zpracovávali Vaše osobní údaje k marketingovým
            účelům, a to ani na základě případně dříve udělených marketingových souhlasů nebo případných specifických
            marketingových souhlasů. Tyto případné další marketingové souhlasy tedy rovněž budeme považovat za odvolané.
          </p>
          <h4>9. Jaká máte zákonná práva při zpracování osobních údajů?</h4>
          <p>
            Máte právo nás požádat o poskytnutí informace o osobních údajích o Vás zpracovávaných, účelu a povaze
            zpracování osobních údajů a o příjemcích osobních údajů. Pokud byste zjistil nebo se domníval, že provádíme
            zpracování Vašich osobních údajů v rozporu s ochranou Vašeho soukromého a osobního života nebo v rozporu s
            právními předpisy, jste oprávněn požadovat po nás vysvětlení, případně požadovat, abychom odstranili takto
            vzniklý závadný stav.
          </p>
          <p>
            <em>
              V případě jakýchkoliv dotazů nebo pokud chcete uplatnit Vaše zákonná práva či odvolat souhlas se
              zpracováním osobních údajů udělený naší společnosti, můžete nás kontaktovat zasláním e-mailu na
              gdpr@storex.cz.
            </em>
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='text' onClick={handleClose}>
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>
  );
};
