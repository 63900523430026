import { atom } from 'recoil';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { BranchListItem } from '../articles/Branch';

export const userPrefferedBranchState = GlobalStateRegister.register(
  atom<BranchListItem | undefined>({
    key: 'userPrefferedBranch',
    default: undefined,
  }),
);
