import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import SelectField, { SelectFieldProps } from '@sprinx/react-mui-fields/SelectField';
import React from 'react';

export type StorexSelectFormFieldProps<T> = SelectFieldProps<T>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles<'selectRoot' | 'icon', {}>({
    selectRoot: {
      background: theme.palette.common.white,
    },
    icon: {
      color: theme.palette.primary.main,
    },
  }),
);

const StorexSelectFormField = <T extends any>({
  children,
  SelectProps,
  variant = 'outlined',
  margin = 'dense',
  ...rest
}: StorexSelectFormFieldProps<T>): JSX.Element => {
  const classes = useStyles();

  return (
    <SelectField
      margin={margin}
      SelectProps={{
        ...SelectProps,
        IconComponent: ExpandIcon,
        classes: {
          ...SelectProps?.classes,
          icon: classes.icon,
          root: classes.selectRoot,
        },
      }}
      variant={variant}
      {...rest}
    >
      {children}
    </SelectField>
  );
};

StorexSelectFormField.displayName = 'StorexSelectFormField';

export default StorexSelectFormField;
