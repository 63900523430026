import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { mainNavigationNarrowNavigationToggleState } from '../../../api/mainNavigation';
import AppLayoutHeaderNarrowHamburgerNavigation from './AppLayoutHeaderNarrowHamburgerNavigation';
import AppLayoutHeaderNarrowList from './AppLayoutHeaderNarrowList';
import AppLayoutHeaderNarrowMainNavigation from './AppLayoutHeaderNarrowMainNavigation';
import AppLayoutSearchBar from './AppLayoutSearchBar';

export type AppLayoutHeaderNarrowProps = StandardDivProps<AppLayoutHeaderNarrowClassKey>;

export type AppLayoutHeaderNarrowClassKey = 'root';

const themeSettings = { name: 'AppLayoutHeaderNarrow' };
const useStyles = makeStyles(
  (/* theme */) => ({
    root: {},
  }),
  themeSettings,
);

const AppLayoutHeaderNarrow: React.FC<AppLayoutHeaderNarrowProps> = React.forwardRef(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <AppLayoutHeaderNarrowMainNavigation />
        <AppLayoutHeaderNarrowHamburgerNavigation />
        <AppLayoutHeaderNarrowListCollapsable />
        <AppLayoutSearchBar />
      </div>
    );
  },
);

AppLayoutHeaderNarrow.displayName = 'AppLayoutHeaderNarrow';

export default withThemeProps(themeSettings)(AppLayoutHeaderNarrow);

const AppLayoutHeaderNarrowListCollapsable = () => {
  const open = useRecoilValue(mainNavigationNarrowNavigationToggleState);
  return (
    <Collapse in={open}>
      <AppLayoutHeaderNarrowList />
    </Collapse>
  );
};
