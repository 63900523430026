import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { CompassControl, KeyboardControl, Map, MarkerLayer, MouseControl, ZoomControl } from 'react-mapycz';
import { MarkerLayerContext } from 'react-mapycz/lib/MarkerLayer';
import { BranchListItem } from '../../api/articles/Branch';
import ChooseWarehouseList from './ChooseWarehouseList';

export interface ChooseWarehouseOnMapDialogProps
  extends Omit<StandardDivProps<ChooseWarehouseOnMapDialogClassKey>, 'onChange'> {
  branches: BranchListItem[];
  onChange: (value: BranchListItem) => void;
  onClose: () => void;
  open: boolean;
}

export type ChooseWarehouseOnMapDialogClassKey = 'root' | 'content' | 'map' | 'dialogContent' | 'whList';

const themeSettings = { name: 'ChooseWarehouseOnMapDialog' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<ChooseWarehouseOnMapDialogClassKey, {}>({
      root: { width: '100%' },
      content: {
        display: 'flex',
        flexDirection: 'column',
      },
      map: {
        width: '100vw',
        height: '100vh',
        '& a': { color: theme.palette.primary.main, fontSize: 17, textDecoration: 'none' },
        '& a, h3, p': { margin: 0 },
        '& p': { fontSize: 14, color: theme.palette.text.secondary },
        '& .smap .card': {
          padding: 0,
          ...theme.typography.body1,
        },
        '& .card .card-header': {
          padding: theme.spacing(1),
        },
        '& .card .card-body': {
          padding: theme.spacing(1),
        },
        '& .card-footer button': {
          alignSelf: 'center',
          margin: theme.spacing(1, 0),
          background: theme.palette.primary.main,
          color: theme.palette.common.white,
          border: 'none',
          borderRadius: theme.spacing(0.5),
          padding: theme.spacing(0.5, 1),
          textTransform: 'uppercase',
          cursor: 'pointer',
          transition: 'all .2s ease',
          '&:hover': {
            background: theme.palette.primary.dark,
          },
        },
        '& .card-footer': { display: 'flex', flexDirection: 'column' },
        '& .card-footer img': { height: 200 },
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          height: '40vh',
        },
      },
      dialogContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
        },
      },
      whList: {
        marginRight: theme.spacing(2),
      },
    }),
  themeSettings,
);

const ChooseWarehouseOnMapDialog: React.FC<ChooseWarehouseOnMapDialogProps> = ({
  branches,
  className,
  classes: pClasses,
  onClose: handleClose,
  onChange,
  open,
}) => {
  const classes = useStyles({ classes: pClasses });

  const handleChange = React.useCallback(
    (v: BranchListItem) => {
      onChange(v);
      handleClose();
    },
    [onChange, handleClose],
  );

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.selectMapMarker = (e: string) => {
        const b = branches.find((x) => x.id === e);
        if (b) handleChange(b);
      };
      return () => (window.selectMapMarker = undefined);
    }
    return undefined;
  }, [branches, handleChange]);

  return (
    <Dialog classes={{ root: clsx(classes.root, className) }} open={open} onClose={handleClose} maxWidth='lg'>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <ChooseWarehouseList branches={branches} classes={{ root: classes.whList }} onChange={handleChange} />
        <div className={classes.map}>
          <Map height='100%' width='100%' center={{ lat: 49.828501, lng: 15.0308385 }} zoom={8}>
            <KeyboardControl />
            <ZoomControl />
            <MouseControl zoom={true} pan={true} wheel={true} />
            <CompassControl right={10} top={50} />
            <MarkerLayer>
              {branches?.map((branch, idx) => (
                <MarkerWithCard key={idx} branch={branch} />
              ))}
            </MarkerLayer>
          </Map>
        </div>
      </DialogContent>
    </Dialog>
  );
};

ChooseWarehouseOnMapDialog.displayName = 'ChooseWarehouseOnMapDialog';

export default withThemeProps(themeSettings)(ChooseWarehouseOnMapDialog);

declare global {
  interface Window {
    selectMapMarker?: (e: string) => void;
    SMap: any;
  }
}

const MarkerWithCard = ({
  branch: { id, title, href, contactPostAddress, contactPhone, contactEmail, teaserImage, latitude, longitude },
}: {
  branch: BranchListItem;
}) => {
  const markerLayer = React.useContext<any>(MarkerLayerContext);

  const coords = window.SMap.Coords.fromWGS84(longitude, latitude);
  const sMarker = new window.SMap.Marker(coords, false);

  const card = new window.SMap.Card();

  card.getHeader().innerHTML = `<a href="${href}">${title}</a>`;
  card.getBody().innerHTML = `<p>Adresa: ${contactPostAddress}</p><p>Tel: ${contactPhone}</p><p>E-mail: ${contactEmail}</p>`;
  card.getFooter().innerHTML = `<button type='button' class='marker-button' onClick='window.selectMapMarker("${id}")'>Vybrat pobočku</button><img src='${teaserImage}' alt=${title} />`;

  sMarker.decorate(window.SMap.Marker.Feature.Card, card);
  markerLayer?.addMarker(sMarker);

  React.useEffect(() => {
    return () => {
      markerLayer.removeMarker(sMarker, true);
    };
  });

  return null;
};
