import { atom, constSelector, selector, useRecoilValue } from 'recoil';
import { ApiClient, createApiClient } from '../@sprinx/react-after-razzle';
import { ApplicationAuthState, GlobalStateRegister } from '../@sprinx/react-after-razzle/stateStore';
import appSettings from '../applicationSettings';
import { SupportedCurrency, SupportedLocale } from '../supported';

export const currencyState = GlobalStateRegister.register(
  atom<SupportedCurrency>({
    key: 'currency',
    default: appSettings.currency,
  }),
);

export const supportedCurrenciesState = constSelector<string[]>(appSettings.supportedCurrencies);

export const localeState = GlobalStateRegister.register(
  atom<SupportedLocale>({
    key: 'locale',
    default: appSettings.locale,
  }),
);

export const supportedLocalesState = constSelector<{ label: string; locale: string }[]>(appSettings.supportedLocales);

export const supportedLocaleCodesState = constSelector<string[]>(appSettings.supportedLocales.map((l) => l.locale));

export const pricesTypeState = GlobalStateRegister.register(
  atom<'B2B' | 'B2C' | 'B2BForeign' | undefined>({
    key: 'pricesType',
    default: appSettings.pricesType || 'B2C',
  }),
);

export const regionState = GlobalStateRegister.register(
  atom<'_default' | string>({
    key: 'region',
    default: appSettings.region || '_default',
  }),
);

export const anonymouseUserIdState = GlobalStateRegister.register(
  atom<string | undefined>({
    key: 'anonymouseUserId',
    default: appSettings.anonymouseUserId || 'anonymous',
  }),
);

export const authState = GlobalStateRegister.register(
  atom<ApplicationAuthState<any> | undefined>({
    key: 'auth',
    default: undefined,
  }),
);

export const isSSRState = GlobalStateRegister.register(
  atom<boolean>({
    key: 'isSSR',
    default: false,
  }),
);

export const isAuthenticatedState = selector<boolean>({
  key: 'isAuthenticated',
  get: ({ get }) => {
    const auth = get(authState);
    return Boolean(auth && auth.user && auth.isAuthenticated);
  },
});

export const isMasterScopeState = selector<boolean>({
  key: 'isMasterScope',
  get: ({ get }) => {
    const auth = get(authState);
    return Boolean(auth && auth.user && (auth.scope || []).includes('vrp:master'));
  },
});

export const authUserState = selector<ApplicationAuthState<any>['user']>({
  key: 'authUser',
  get: ({ get }) => {
    const auth = get(authState);
    return auth?.user;
  },
});

export const apiClientState = selector<ApiClient>({
  key: 'apiClient',
  get: ({ get }) => {
    const auth = get(authState);
    return createApiClient({ accessToken: auth?.accessToken });
  },
});

/**
 * useApiClient
 */
export function useApiClient(): ApiClient {
  const auth = useRecoilValue(authState);
  return createApiClient({ accessToken: auth?.accessToken });
}
