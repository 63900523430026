import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { localeState } from '../../../api/appState';
import { routeUrl } from '../../../routesBuilder';
import AppLayoutFooterContactForm from './AppLayoutFooterContactForm';

export type AppLayoutFooterWideNavigationProps = StandardDivProps<AppLayoutFooterWideNavigationClassKey>;

export type AppLayoutFooterWideNavigationClassKey = 'root' | 'main' | 'aside' | 'empty' | 'content';

const themeSettings = { name: 'AppLayoutFooterWideNavigation' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<AppLayoutFooterWideNavigationClassKey, {}>({
      root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(6, 0),
        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(6, 0),
        },
      },
      main: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > a': {
          paddingRight: theme.spacing(2),
          '&:last-child': {
            paddingRight: 0,
          },
        },
        '& > button': {
          ...theme.typography.body1,
          paddingRight: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          '& > a': {
            padding: theme.spacing(1, 0),
          },
          '& > button': {
            padding: theme.spacing(1, 0),
          },
        },
      },
      aside: {
        width: theme.spacing(23),
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
          padding: theme.spacing(3, 0),
          color: theme.palette.grey[300],
        },
      },
      empty: { [theme.breakpoints.down('sm')]: { display: 'none' } },
      content: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column-reverse',
        },
      },
    }),
  themeSettings,
);

const AppLayoutFooterWideNavigation: React.FC<AppLayoutFooterWideNavigationProps> = React.forwardRef(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const locale = useRecoilValue(localeState);

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <Container className={classes.content} maxWidth='lg' component='div'>
          <div className={classes.aside}>&copy; {new Date().getFullYear()} prodej-pneu.cz</div>
          <div className={classes.main}>
            <AppLayoutFooterContactForm color='inherit'>Napište nám</AppLayoutFooterContactForm>
            <Link color='inherit' component={RouterLink} to={routeUrl('tiresDictionary', { locale, params: {} })}>
              Slovník o pneumatikách
            </Link>
            <Link color='inherit' href='https://www.velkoobchodniprodejpneumatik.cz/' target='_blank' rel='noreferrer'>
              Velkoobchod
            </Link>
          </div>
          <div className={clsx(classes.aside, classes.empty)} />
        </Container>
      </div>
    );
  },
);

AppLayoutFooterWideNavigation.displayName = 'AppLayoutFooterWideNavigation';

export default withThemeProps(themeSettings)(AppLayoutFooterWideNavigation);
