import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import React from 'react';

export interface TireChangeWizzardStepperProps {
  activeStep: number;
}

const TireChangeWizzardStepper: React.FC<TireChangeWizzardStepperProps> = ({ activeStep }) => {
  return (
    <Stepper activeStep={activeStep} alternativeLabel orientation='horizontal'>
      <Step>
        <StepLabel>Parametry</StepLabel>
      </Step>
      <Step>
        <StepLabel>Výběr dne</StepLabel>
      </Step>
      <Step>
        <StepLabel>Výběr času</StepLabel>
      </Step>
      <Step>
        <StepLabel>Rekapitulace</StepLabel>
      </Step>
    </Stepper>
  );
};

TireChangeWizzardStepper.displayName = 'TireChangeWizzardStepper';

export default TireChangeWizzardStepper;
