import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';

export interface FormSectionSubHeadlineProps extends StandardDivProps<FormSectionSubHeadlineClassKey> {
  children?: React.ReactNode;
}

export type FormSectionSubHeadlineClassKey = 'root';

const themeSettings = { name: 'FormSectionSubHeadline' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<FormSectionSubHeadlineClassKey, {}>({
      root: {
        ...theme.typography.body1,
        fontSize: 18,
        fontWeight: theme.typography.fontWeightMedium,
      },
    }),
  themeSettings,
);

const FormSectionSubHeadline = React.forwardRef<HTMLDivElement, FormSectionSubHeadlineProps>(
  ({ className, classes: pClasses, children }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        {children}
      </div>
    );
  },
);

FormSectionSubHeadline.displayName = 'FormSectionSubHeadline';

export default withThemeProps(themeSettings)(FormSectionSubHeadline);
