import { AppInitializationState, useGetAppProps } from './stateStore';

export const ForwardAppProps = <T extends AppInitializationState<any, any, any, any>>({
  children,
}: {
  children: (props: { getAppProps: () => T }) => JSX.Element | null;
}): JSX.Element | null => {
  const getAppProps = useGetAppProps<T>();
  return children({ getAppProps });
};
