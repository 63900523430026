import { getShoppingCartProductSku } from '../shoppingCart';
import { CHECKOUT_SHIPPING_RESERVATION_SKU } from './constants';

export default function isPersonalPickupReservationProduct(
  shippingProduct: { extra?: Record<string, any>; id: string; sku: string } | string | undefined,
): false | [yes: true, complete: boolean] {
  const shippingProductSku = getShoppingCartProductSku(shippingProduct);
  if (
    shippingProduct &&
    typeof shippingProduct !== 'string' &&
    shippingProductSku &&
    shippingProductSku.startsWith(CHECKOUT_SHIPPING_RESERVATION_SKU)
  ) {
    const { extra } = shippingProduct;

    if (!extra) return [true, false];

    return [true, true];
  }

  return false;
}
