import { ApiClient } from '../@sprinx/react-after-razzle';
import * as BillingInfoSection from '../api/checkout/billingInfoSection';

export const saveRegistration = (apiClient: ApiClient, data: RegistrationState): Promise<RegistrationResult> => {
  return apiClient.post<RegistrationResult, {}>('/v1/registration', data);
};

interface RegistrationResultSuccess {
  status: 'success';
}

interface RegistrationResultUserNameAlreadyRegistered {
  status: 'userNameAlreadyRegistered';
}

interface RegistrationResultError {
  status: 'error';
}

type RegistrationResult =
  | RegistrationResultSuccess
  | RegistrationResultUserNameAlreadyRegistered
  | RegistrationResultError;

export const createdefaultRegistrationState = (): RegistrationState => ({
  ...BillingInfoSection.createDefaultData({
    companyName: '',
    companyRegNo: '',
    companyTaxNo: '',
  }),
});

export type RegistrationState = BillingInfoSection.SectionStateData;
