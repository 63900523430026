import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { apiClientState } from './appState';
import { enqueueSnackbarMessage } from './snackbars';

export const useContactFormSend = (): ((values: ContactFormValues) => Promise<boolean>) => {
  const apiClient = useRecoilValue(apiClientState);
  const showMessage = useSetRecoilState(enqueueSnackbarMessage);

  return useCallback(
    (values: ContactFormValues): Promise<boolean> =>
      apiClient
        .post<{ status: 'success' } | { status: 'error' }, {}>('/v1/contact-form', values, { timeout: 10000 })
        .then((res) => {
          if (res.status === 'success') {
            showMessage({
              message: 'Vaše zpráva pro nás byla úspěšně uložena.',
              variant: 'success',
            });
          } else if (res.status === 'error') {
            showMessage({
              message: 'Nastal problém při uložení vaší zprávy pro nás.',
              variant: 'error',
            });
          }

          return true;
        })
        .catch((err) => {
          showMessage({
            message: 'Nastal problém při uložení vaší zprávy pro nás',
            variant: 'error',
          });

          if (process.env.NODE_ENV !== 'production') {
            console.error('Contact use form save error.', err);
          }

          return false;
        }),
    [apiClient, showMessage],
  );
};

export const contactFormCreateInitialValues = (): ContactFormValues => ({
  firstName: '',
  lastName: '',
  email: '',
  message: '',
  gdprAgreement: false,
});

export interface ContactFormValues {
  email: string;
  firstName: string;
  gdprAgreement: boolean;
  lastName: string;
  message: string;
}
