import { atom } from 'recoil';
import { atomEffectSyncWithSession } from '../../@sprinx/react-after-razzle/stateEffects';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import * as BillingInfoSection from './billingInfoSection';
import * as DeliveryInfoSection from './deliveryInfoSection';
import { CheckoutNoteState, CheckoutSectionCommonState, CheckoutStateUserRegistration } from './types';

export interface CheckoutState {
  action: 'none' | 'order' | 'orderAndChange' | 'reservation' | 'reservationAndChange';
  billingInfoSection: BillingInfoSection.SectionState;
  deliveryInfoSection: DeliveryInfoSection.SectionState;
  doNotSendToHeureka: boolean | undefined;
  makeUserRegistration: boolean | undefined;
  parcelNote: CheckoutNoteState;
  paymentSection: CheckoutSectionCommonState;
  sellerNote: CheckoutNoteState;
  shippingSection: CheckoutSectionCommonState;
  userRegistration: CheckoutStateUserRegistration | undefined;
}

export const checkoutState = GlobalStateRegister.register(
  atom<CheckoutState>({
    key: 'checkout',
    default: createCheckoutStateDefault(),
    effects_UNSTABLE: [atomEffectSyncWithSession('checkout')],
  }),
);

export function createCheckoutStateDefault(): CheckoutState {
  return {
    action: 'none',
    billingInfoSection: BillingInfoSection.createDefaultState(),
    deliveryInfoSection: DeliveryInfoSection.createDefaultState(),
    makeUserRegistration: false,
    doNotSendToHeureka: false,
    userRegistration: undefined,
    parcelNote: createCheckoutStateNoteDefault(),
    sellerNote: createCheckoutStateNoteDefault(),
    shippingSection: createCheckoutCommonSectionState(),
    paymentSection: createCheckoutCommonSectionState(),
  };
}

function createCheckoutStateNoteDefault(): CheckoutNoteState {
  return {
    expanded: false,
    note: undefined,
  };
}
function createCheckoutCommonSectionState(): CheckoutSectionCommonState {
  return {
    completed: false,
  };
}
