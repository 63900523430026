import { atom, selector } from 'recoil';
import sortBy from 'lodash/sortBy';
import { ProductTaxonomyType } from '../../@sprinx/knihovka-types';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState } from '../appState';

const CATALOGUE_TAXONOMY_ROOT = '/catalogue';

export const getProductTaxonomyTypes = (apiClient: ApiClient): Promise<ProductTaxonomyType[]> => {
  return apiClient.get<ProductTaxonomyType[], {}>('/v1/product-taxonomy-types');
};

export const productTaxonomyTypesInitialState = GlobalStateRegister.register(
  atom<ProductTaxonomyType[] | undefined>({
    key: 'productTaxonomyTypesInitial',
    default: undefined,
  }),
);

export const productTaxonomyTypesQuery = selector<ProductTaxonomyType[]>({
  key: 'productTaxonomyTypes',
  get: ({ get }) => {
    const initial = get(productTaxonomyTypesInitialState);
    if (initial !== undefined) return initial;

    const apiClient = get(apiClientState);
    return getProductTaxonomyTypes(apiClient);
  },
});

export const catalogueTaxonomyTypesSelector = selector<ProductTaxonomyType[]>({
  key: 'catalogueTaxonomyTypes',
  get: ({ get }) => {
    const allTaxonomies = get(productTaxonomyTypesQuery);

    return sortBy(
      allTaxonomies.filter(
        (tx) => tx.code.startsWith(`${CATALOGUE_TAXONOMY_ROOT}/`) || tx.code === CATALOGUE_TAXONOMY_ROOT,
      ),
      [(i) => i.code],
    );
  },
});

export const catalogueTaxonomyTypesLeveledSelector = selector<{ items: ProductTaxonomyType[]; maxLevel: number }>({
  key: 'catalogueTaxonomyTypesLeveled',
  get: ({ get }) => {
    const catalogueTaxonomies = get(catalogueTaxonomyTypesSelector);
    const maxLevel = catalogueTaxonomies
      .map((i) => (i.code.match(/\//g) || []).length)
      .reduce((max, i) => (max < i ? i : max), 0);

    return {
      items: catalogueTaxonomies,
      maxLevel,
    };
  },
});

export const catalogueTaxonomiesHierarchySelector = selector<ProductTaxonomyTypeHierarchyNode>({
  key: 'catalogueTaxonomiesHierarchySelector',
  get: ({ get }) => {
    const catalogueTaxonomiesLeveled = get(catalogueTaxonomyTypesLeveledSelector);

    const traverse = (node: ProductTaxonomyType, items: ProductTaxonomyType[]): ProductTaxonomyTypeHierarchyNode => {
      const rx = new RegExp(`^${node.code}/[^/]+$`, 'i');
      const children = items.filter((i) => rx.test(i.code)).map((prt) => traverse(prt, items));

      return children.length > 0 ? { ...node, children } : node;
    };

    const rootTaxonomy = catalogueTaxonomiesLeveled.items.find((n) => n.code === CATALOGUE_TAXONOMY_ROOT);

    if (!rootTaxonomy) {
      throw new Error('Catalogue root taxonomy not found...');
    }

    const root = traverse(rootTaxonomy, catalogueTaxonomiesLeveled.items);

    return root;
  },
});

export interface ProductTaxonomyTypeHierarchyNode extends ProductTaxonomyType {
  children?: ProductTaxonomyTypeHierarchyNode[];
}
