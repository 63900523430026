import { atom, selector } from 'recoil';
import { ProductParameterGroupTypes } from '../../@sprinx/knihovka-types/products';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { apiClientState } from '../appState';

export type ProductParameterGroupTypesHandler = () => Promise<ProductParameterGroupTypes>;

export function getProductParameterGroupTypes(apiClient: ApiClient): ReturnType<ProductParameterGroupTypesHandler> {
  return apiClient.get<ProductParameterGroupTypes, {}>('/v1/product-parameter-group-types', {});
}

export const productParameterGroupTypesQuery = selector({
  key: 'productParameterGroupTypesQuery',
  get: ({ get }) => {
    const initials = get(productParameterGroupTypesInitialsState);
    if (initials) return initials;

    return getProductParameterGroupTypes(get(apiClientState));
  },
});

export const productParameterGroupTypesInitialsState = GlobalStateRegister.register(
  atom<ProductParameterGroupTypes | undefined>({
    key: 'productParameterGroupTypesInitials',
    default: undefined,
  }),
);
