import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import ListPretty from '@sprinx/react-mui-components/ListPretty';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { BranchListItem } from '../../api/articles/Branch';

export interface ChooseWarehouseListProps extends Omit<StandardDivProps<ChooseWarehouseListClassKey>, 'onChange'> {
  branches: BranchListItem[];
  onChange?: (value: BranchListItem) => void;
}

export type ChooseWarehouseListClassKey = 'root' | 'text';

const themeSettings = { name: 'ChooseWarehouseList' };
const useStyles = makeStyles(
  (theme) => ({
    root: {},
    text: {
      fontSize: theme.typography.body2.fontSize,
    },
  }),
  themeSettings,
);

const ChooseWarehouseList: React.FC<ChooseWarehouseListProps> = React.forwardRef(
  ({ branches, className, classes: pClasses, onChange }, ref) => {
    const classes = useStyles({ classes: pClasses });

    const handleChange = React.useCallback(
      (value: BranchListItem) => {
        if (onChange) {
          onChange(value);
        }
      },
      [onChange],
    );

    return (
      <ListPretty ref={ref} className={clsx(classes.root, className)}>
        {(branches || []).map((wh, idx) => (
          <ListItem button onClick={() => handleChange(wh)} key={idx.toString()}>
            <ListItemText primary={wh.teaser} className={classes.text} />
          </ListItem>
        ))}
      </ListPretty>
    );
  },
);

ChooseWarehouseList.displayName = 'ChooseWarehouseList';

export default withThemeProps(themeSettings)(ChooseWarehouseList);
