import { ContainerProps } from '@material-ui/core/Container';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { canUseDom } from '../../../@sprinx/react-after-razzle';
import AppLayoutHeaderNarrow from './AppLayoutHeaderNarrow';
import AppLayoutHeaderWide from './AppLayoutHeaderWide';

export interface AppLayoutHeaderProps {
  maxWidth: ContainerProps['maxWidth'];
  ref?: React.Ref<HTMLDivElement>;
  wideBreakPoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

/**
 * AppLayoutHeader
 */
const AppLayoutHeader: React.FC<AppLayoutHeaderProps> = React.forwardRef(({ maxWidth, wideBreakPoint = 'md' }, ref) => {
  const theme = useTheme();
  const isWideB = useMediaQuery(theme.breakpoints.up(wideBreakPoint));
  const isWide = canUseDom ? isWideB : true;

  return isWide ? <AppLayoutHeaderWide ref={ref} maxWidth={maxWidth} /> : <AppLayoutHeaderNarrow ref={ref} />;
});

AppLayoutHeader.displayName = 'AppLayoutHeader';

export default AppLayoutHeader;
