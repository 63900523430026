import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import Barum from '../../../../public/Barum.jpg';
import Bridgestone from '../../../../public/Bridgestone.png';
import Continental from '../../../../public/Continental.jpg';
import Davanti from '../../../../public/Davanti.png';
import Dunlop from '../../../../public/Dunlop.jpg';
import Firestone from '../../../../public/Firestone.png';
import Fulda from '../../../../public/Fulda.jpg';
import Goodyear from '../../../../public/Goodyear.jpg';
import Gopay from '../../../../public/gopay.png';
import Hankook from '../../../../public/Hankook.png';
import Heureka from '../../../../public/Heureka.png';
import Mastercard from '../../../../public/Mastercard.png';
import Matador from '../../../../public/Matador.jpg';
import Nexen from '../../../../public/Nexen.png';
import Nokian from '../../../../public/Nokian.jpg';
import Pirelli from '../../../../public/Pirelli.png';
import Sava from '../../../../public/Sava.jpg';
import Semperit from '../../../../public/Semperit.jpg';
import Visa from '../../../../public/Visa.png';

export type AppLayoutFooterPartnersProps = StandardDivProps<AppLayoutFooterPartnersClassKey>;

export type AppLayoutFooterPartnersClassKey = 'root';

const themeSettings = { name: 'AppLayoutFooterPartners' };
const useStyles = makeStyles(
  (theme) => ({
    root: {},
    container: {
      padding: theme.spacing(12, 0),
      borderTop: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      alignContent: 'space-between',
      flexWrap: 'wrap',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4, 0),
      },
    },
    imgContainer: {
      maxWidth: 150,
      padding: theme.spacing(1),
      '& > img': {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '33%',
        textAlign: 'center',
        padding: theme.spacing(1),
      },
    },
    infotext: {
      textAlign: 'center',
      fontSize: '14px',
    },
  }),
  themeSettings,
);

const AppLayoutFooterPartners: React.FC<AppLayoutFooterPartnersProps> = React.forwardRef(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <Container className={classes.container} maxWidth='lg'>
          <PartnerImg alt='Barum' src={Barum} />
          <PartnerImg alt='Bridgestone' src={Bridgestone} />
          <PartnerImg alt='Continental' src={Continental} />
          <PartnerImg alt='Davanti' src={Davanti} />
          <PartnerImg alt='Dunlop' src={Dunlop} />
          <PartnerImg alt='Firestone' src={Firestone} />
          <PartnerImg alt='Fulda' src={Fulda} />
          <PartnerImg alt='Goodyear' src={Goodyear} />
          <PartnerImg alt='Hankook' src={Hankook} />
          <PartnerImg alt='Matador' src={Matador} />
          <PartnerImg alt='Nexen' src={Nexen} />
          <PartnerImg alt='Nokian' src={Nokian} />
          <PartnerImg alt='Pirelli' src={Pirelli} />
          <PartnerImg alt='Sava' src={Sava} />
          <PartnerImg alt='Semperit' src={Semperit} />
        </Container>
        <Container className={classes.container} maxWidth='lg'>
          <PartnerImg alt='Payu' src={Gopay} />
          <PartnerImg alt='Heureka' src={Heureka} />
          <PartnerImg alt='Visa' src={Visa} />
          <PartnerImg alt='Mastercard' src={Mastercard} />
          <p className={clsx(classes.infotext, className)}>
            Tento projekt byl realizován za finanční podpory z prostředků státního rozpočtu prostřednictvím Ministerstva
            průmyslu a obchodu v programu The Country for the Future
          </p>
        </Container>
      </div>
    );
  },
);

AppLayoutFooterPartners.displayName = 'AppLayoutFooterPartners';

export default withThemeProps(themeSettings)(AppLayoutFooterPartners);

const PartnerImg: React.FC<{ alt: string; src: string }> = ({ alt, src }) => {
  const classes = useStyles();
  return (
    <div className={classes.imgContainer}>
      <img alt={alt} src={src} />
    </div>
  );
};
