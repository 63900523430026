import { createMuiTheme } from '@material-ui/core/styles';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';

const htmlFontSize: TypographyOptions['htmlFontSize'] = 10;
const fontSize: TypographyOptions['fontSize'] = 10;
const fontFamily: TypographyOptions['fontFamily'] = '"Montserrat", "Arial", sans-serif';
const fontWeightLight: TypographyOptions['fontWeightLight'] = 300;
const fontWeightRegular: TypographyOptions['fontWeightRegular'] = 400;
const fontWeightMedium: TypographyOptions['fontWeightMedium'] = 600;
const fontWeightBold: TypographyOptions['fontWeightBold'] = 700;

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    background: {
      paper: '#f6f6f6',
      default: '#fff',
      video: '#f6f6f6',
    },
    primary: {
      main: '#ed1c24',
      dark: '#970000',
    },
    secondary: {
      main: '#960001',
    },
    text: {
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
    },
  },

  typography: {
    htmlFontSize,
    fontFamily,
    fontSize,
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
    h1: {
      fontSize: '2rem',
      letterSpacing: 0,
      fontWeight: fontWeightMedium,
      lineHeight: 1.167,
      '@media(max-width: 599px)': {
        fontSize: '1.6rem',
        fontWeight: '500',
      },
    },
    h2: {
      fontSize: '1.9rem',
      lineHeight: 1.1,
      fontWeight: fontWeightMedium,
      letterSpacing: 0,
      marginTop: 8,
      marginBottom: 8,
      '@media(min-width: 0px)': {
        fontSize: 20,
      },
      '@media(min-width: 600px)': {
        fontSize: 30,
      },
    },
    h3: {
      fontSize: '1.8rem',
      fontWeight: fontWeightMedium,
      lineHeight: 1.1,
      letterSpacing: 0,
      marginTop: 8,
      marginBottom: 8,
      '@media(min-width: 0px)': {
        fontSize: 20,
      },
      '@media(min-width: 600px)': {
        fontSize: 22,
      },
    },
    h4: {
      fontSize: '1.75rem',
      fontWeight: fontWeightMedium,
      '@media(min-width: 600px)': {
        fontSize: 18,
      },
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: fontWeightMedium,
      '@media(max-width: 599px)': {
        fontWeight: '500',
        fontSize: '1.25rem',
      },
    },
    h6: {
      fontSize: '1.5rem',
      fontWeight: fontWeightMedium,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: fontWeightRegular,
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
    },
    subtitle2: {
      fontFamily: fontFamily,
      fontWeight: fontWeightMedium,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: fontWeightRegular,
      // '@media(min-width: 600px)': {
      //   fontSize: 15,
      // },
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.4,
      letterSpacing: 0.2,
      '@media(max-width: 599px)': {
        fontSize: '.875rem',
      },
    },
    body2: {
      '@media(max-width: 600px)': {
        fontSize: '.75rem',
      },
    },
    button: {
      fontWeight: fontWeightMedium,
      fontSize: '0.875rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },

    // custom typography settings
    productDetail: {
      price: {
        primary: {
          fontSize: '2.1rem',
          fontWeight: 500,
          '@media(max-width: 599px)': {
            fontSize: '1.5rem',
          },
        },
      },
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
        fontFamily: '"Montserrat", "Arial", sans-serif',
      },
      textSizeSmall: {
        fontSize: 13,
      },
    },
    MuiIconButton: {
      root: {
        padding: 8,
      },
    },
    MuiLink: {
      root: {
        fontFamily: '"Montserrat", "Arial", sans-serif',
      },
    },
    MuiContainer: {
      root: {
        '@media(max-width: 959px)': {
          paddingLeft: 8,
          paddingRight: 8,
        },
      },
    },
    MuiDialog: {
      container: {
        fontFamily: '"Montserrat", "Arial", sans-serif',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
      input: {
        fontWeight: 600,
      },
    },
    MuiInputLabel: {
      outlined: {
        fontWeight: 500,
        fontSize: 14,
      },
    },
    MuiChip: {
      label: {
        display: 'flex',
      },
    },
  },
});

export default theme;
