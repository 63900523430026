import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import type { FormikErrors, FormikTouched } from '@sprinx/react-forms';
import formFieldError from '@sprinx/react-forms/formFieldError';
import FormikExtended from '@sprinx/react-forms/FormikExtended';
import formSectionErrorSub from '@sprinx/react-forms/formSectionErrorSub';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import CheckboxField from '@sprinx/react-mui-fields/CheckboxField';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { apiClientState, authUserState, isAuthenticatedState, localeState } from '../../../api/appState';
import useLoginHandler from '../../../api/auth/useLoginHandler';
import useLogoutHandler from '../../../api/auth/useLogoutHandler';
import { createdefaultRegistrationState, RegistrationState, saveRegistration } from '../../../api/registration';
import { enqueueSnackbarMessage } from '../../../api/snackbars';
import FormInDialog from '../../../components/FormInDialog';
import FormSectionSubHeadline from '../../../components/FormSectionSubHeadline';
import StorexPasswordField from '../../../components/StorexPasswordField';
import StorexTextField from '../../../components/StorexTextField';
import companySelectedStringFastestValidatorRule from '../../../fastestValidationRules/companySelectedStringFastestValidatorRule';
import { routeUrl } from '../../../routesBuilder';

export type AppLayoutPersonIconActionsProps = StandardDivProps<AppLayoutPersonIconActionsClassKey>;

export type AppLayoutPersonIconActionsClassKey =
  | 'root'
  | 'avatar'
  | 'alone'
  | 'userName'
  | 'divider'
  | 'paper'
  | 'icon';

const themeSettings = { name: 'AppLayoutPersonIconActions' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<AppLayoutPersonIconActionsClassKey, {}>({
      root: {},
      alone: { marginLeft: 'auto' },
      userName: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        padding: theme.spacing(1, 2),
        justifyContent: 'center',
        fontStyle: 'italic',
        opacity: '1 !important',
      },
      icon: {},
      divider: {
        margin: theme.spacing(1, 0),
      },
      paper: {},
      avatar: { background: theme.palette.primary.dark },
    }),
  themeSettings,
);

const AppLayoutPersonIconActions: React.FC<AppLayoutPersonIconActionsProps> = ({ className, classes: pClasses }) => {
  const classes = useStyles({ classes: pClasses });
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [manuOpen, setMenuOpen] = React.useState(false);
  const [crossRoad, setCrossRoad] = React.useState<'login' | 'registration' | 'passwordRenew' | undefined>(undefined);
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const user = useRecoilValue(authUserState);

  const handleSetCrossRoad = React.useCallback(
    (value: 'login' | 'registration' | 'passwordRenew') => (): void => {
      setMenuOpen(false);
      if (value && ['login', 'registration', 'passwordRenew'].includes(value)) {
        setCrossRoad(value);
      }
    },
    [],
  );

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = React.useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleDialogClose = React.useCallback((nextDialog?: 'login' | 'passwordRenew') => {
    setMenuOpen(false);
    setCrossRoad(nextDialog);
  }, []);

  const label = isAuthenticated ? (user || { name: '' }).name.split(' ').map((i) => i[0]) : [];

  return (
    <>
      <IconButton onClick={handleMenuOpen} ref={anchorRef} className={clsx(classes.root, className)}>
        {isAuthenticated ? (
          <Avatar className={classes.avatar}>{`${label[0] || ''}${label[1] || ''}`}</Avatar>
        ) : (
          <PersonIcon className={clsx(classes.icon)} />
        )}
      </IconButton>
      {isAuthenticated ? (
        <MenuAuthorized anchorRef={anchorRef} onClose={handleMenuClose} open={manuOpen} />
      ) : (
        <MenuUnauthenticated
          anchorRef={anchorRef}
          onClose={handleMenuClose}
          onDialogOpen={handleSetCrossRoad}
          open={manuOpen}
        />
      )}

      {crossRoad === 'login' && <LoginDialog onClose={handleDialogClose} />}
      {crossRoad === 'registration' && <RegistrationDialog onClose={handleDialogClose} />}
      {crossRoad === 'passwordRenew' && <PasswordRenewDialog onClose={handleDialogClose} />}
    </>
  );
};

AppLayoutPersonIconActions.displayName = 'AppLayoutPersonIconActions';

export default withThemeProps(themeSettings)(AppLayoutPersonIconActions);

const MenuUnauthenticated: React.FC<{
  anchorRef: React.RefObject<HTMLButtonElement>;
  onClose: () => void;
  onDialogOpen: (dialog: 'login' | 'registration') => () => void;
  open: boolean;
}> = ({ anchorRef, onClose: handleMenuClose, onDialogOpen: handleDialogOpen, open }) => {
  return (
    <Menu
      open={open}
      anchorEl={anchorRef.current}
      anchorReference='anchorPosition'
      anchorPosition={{
        top: (anchorRef.current?.offsetTop || 0) + 40,
        left: anchorRef.current?.offsetLeft || 0,
      }}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleDialogOpen('login')}>Přihlásit se</MenuItem>
      <MenuItem onClick={handleDialogOpen('registration')}>Registrovat</MenuItem>
    </Menu>
  );
};

MenuUnauthenticated.displayName = 'MenuUnauthenticated';

const MenuAuthorized = withStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      padding: theme.spacing(1, 2),
      justifyContent: 'center',
      fontStyle: 'italic',
      opacity: '1 !important',
    },
    divider: { margin: theme.spacing(1, 0) },
  }),
  { name: 'AuthorizedMenuItems' },
)(
  ({
    anchorRef,
    classes,
    className,
    onClose: handleMenuClose,
    open,
  }: {
    anchorRef: React.RefObject<HTMLButtonElement>;
    classes: Record<'root' | 'divider', string>;
    className?: string;
    onClose: () => void;
    open: boolean;
  }) => {
    const user = useRecoilValue(authUserState);
    const locale = useRecoilValue(localeState);
    const showMessage = useSetRecoilState(enqueueSnackbarMessage);
    const t = useTranslate();
    const onLogout = useLogoutHandler();

    const handleLogout = React.useCallback(() => {
      onLogout().then(() => {
        handleMenuClose();
        showMessage({
          message: 'Odhlášení proběhlo úspěšně.',
        });
        window.location.href = '/';
      });
    }, [onLogout, handleMenuClose, showMessage]);

    if (!user) {
      return null;
    }

    return (
      <Menu
        open={open}
        anchorEl={anchorRef.current}
        anchorReference='anchorPosition'
        anchorPosition={{
          top: (anchorRef.current?.offsetTop || 0) + 40,
          left: anchorRef.current?.offsetLeft || 0,
        }}
        onClose={handleMenuClose}
      >
        <MenuItem disabled classes={{ root: clsx(classes.root, className) }}>
          {user.name}
        </MenuItem>
        <Divider className={classes.divider} variant='middle' />
        <MenuItem component={RouterLink} to={routeUrl('profilePurchases', { locale, params: undefined })}>
          {t('personalIcon/menu/orders')}
        </MenuItem>
        <MenuItem component={RouterLink} to={routeUrl('profileReservations', { locale, params: undefined })}>
          Moje rezervace
        </MenuItem>
        <MenuItem component={RouterLink} to={routeUrl('profileSettings', { locale, params: undefined })}>
          {t('personalIcon/menu/settings')}
        </MenuItem>
        <Divider className={classes.divider} variant='middle' />
        <MenuItem onClick={handleLogout}>{t('personalIcon/menu/logout')}</MenuItem>
      </Menu>
    );
  },
);

MenuAuthorized.displayName = 'MenuAuthorized';

const LoginDialog = withStyles((theme: Theme) => ({
  root: {},
  loginButton: {
    margin: theme.spacing(1, 0),
  },
  forgottenPwd: {
    margin: theme.spacing(1, 0),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    width: '100%',
    maxWidth: 611,
  },
  passwordField: {
    minWidth: 350,
    [theme.breakpoints.down('xs')]: {
      minWidth: 'unset',
    },
  },
  error: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(12),
  },
}))(
  ({
    classes,
    className,
    onClose: handleClose,
  }: {
    classes: Record<'root' | 'container' | 'passwordField' | 'loginButton' | 'error', string>;
    className?: string;
    onClose: (nextDialog?: 'passwordRenew') => void;
    onSuccess?: () => void;
  }) => {
    const [isSubmitting, setSubmitting] = React.useState<boolean>(false);
    const [userName, setUserName] = React.useState<string | undefined>('');
    const [password, setPassword] = React.useState<string | undefined>('');
    const [error, setError] = React.useState<boolean | undefined>(undefined);
    const t = useTranslate();

    const handleLogin = useLoginHandler(
      () => {
        handleClose();
        setSubmitting(false);
      },
      (err) => {
        if (process.env.NODE_ENV !== 'production') {
          console.error('Login Error ', err);
        }
        setError(true);
        setSubmitting(false);
      },
    );

    const handleInputChange = React.useCallback(
      (field: 'userName' | 'password') => (value: string | undefined) => {
        if (error) {
          setError(undefined);
        }
        switch (field) {
          case 'userName':
            return setUserName(value);
          case 'password':
            return setPassword(value);
        }
      },
      [setUserName, setPassword, setError, error],
    );

    const handleSubmit = React.useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        setSubmitting(true);
        event.preventDefault();
        handleLogin(userName as string, password as string);
      },
      [handleLogin, userName, password],
    );

    return (
      <Dialog
        classes={{ root: clsx(classes.root, className) }}
        open
        onClose={() => {
          handleClose();
        }}
      >
        <DialogTitle>{t('personalIcon/dialog/title')}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} className={classes.container}>
            {error && <div className={classes.error}>CHYBA! Uživatelské jméno nebo heslo není správně</div>}
            <StorexTextField
              type='text'
              value={userName}
              onChange={handleInputChange('userName')}
              label={t('personalIcon/dialog/userName')}
              required
            />
            <StorexPasswordField
              value={password}
              onChange={handleInputChange('password')}
              label={t('personalIcon/dialog/password')}
              required
              fullWidth
              className={classes.passwordField}
            />

            <Button
              disabled={isSubmitting}
              classes={{
                root: classes.loginButton,
              }}
              variant='contained'
              color='primary'
              type='submit'
            >
              {t('personalIcon/dialog/button')}
            </Button>
          </form>
          <Box display='flex' justifyContent='center'>
            <Link
              component='button'
              onClick={() => {
                handleClose('passwordRenew');
              }}
            >
              {t('personalIcon/dialog/forgottenPwd')}
            </Link>
          </Box>
        </DialogContent>
      </Dialog>
    );
  },
);

LoginDialog.displayName = 'LoginMenuItems';

const RegistrationDialog = withStyles(
  (theme) => ({
    root: {},
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        alignItems: 'stretch',
      },
    },
    passwordField: {
      minWidth: 350,
      [theme.breakpoints.down('xs')]: {
        minWidth: 'unset',
      },
    },
    section: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    button: { marginTop: theme.spacing(1) },
    actions: { justifyContent: 'center', padding: theme.spacing(2, 0) },
  }),
  { name: 'RegistrationDialog' },
)(
  ({
    classes,
    className,
    onClose: handleClose,
  }: {
    classes: Record<'root' | 'container' | 'passwordField' | 'button' | 'section' | 'actions', string>;
    className?: string;
    onClose: (nextDialog?: 'login') => void;
  }) => {
    const apiClient = useRecoilValue(apiClientState);
    const t = useTranslate();
    const showMessage = useSetRecoilState(enqueueSnackbarMessage);
    const handleRegistrationSave = React.useCallback(
      (
        values: RegistrationState,
        {
          setErrors,
          setSubmitting,
        }: { setErrors: (errors: FormikErrors<RegistrationState>) => void; setSubmitting: (s: boolean) => void },
      ) => {
        return saveRegistration(apiClient, values)
          .then((res) => {
            setSubmitting(false);
            if (res.status === 'success') {
              handleClose('login');
              return;
            }

            if (res.status === 'userNameAlreadyRegistered') {
              setErrors({ emailAddress: 'Uživatel s touto email adresou je již registrován.' });
              showMessage({
                message: 'Ve formuláři registrace byly nalezeny chyby.',
                variant: 'warning',
              });
              return;
            }
          })
          .catch((err) => {
            if (process.env.NODE_ENV !== 'production') {
              console.error('Při vytváření registrace došlo k chybě.', err);
            }

            setSubmitting(false);
            showMessage({
              message: 'Při vytváření registrace došlo k chybě. Zkuste opakovat znova.',
              variant: 'error',
            });
          });
      },
      [apiClient, handleClose, showMessage],
    );

    return (
      <FormikExtended<RegistrationState>
        translator={t}
        initialValues={createdefaultRegistrationState()}
        validationFastest={{
          rules: [companySelectedStringFastestValidatorRule()],
        }}
        validationSchema={{
          companyName: { type: 'companyStringRequired', optional: true },
          companyRegNo: { type: 'companyStringRequired', optional: true },
          companyTaxNo: { type: 'companyStringRequired', optional: true },
          emailAddress: { type: 'email' },
          personName: { type: 'string', empty: false },
          personOrCompany: { type: 'enum', values: ['person', 'company'] },
          phoneNumber: { type: 'phoneNumber', empty: false },
          postAddress: {
            type: 'object',
            props: {
              city: { type: 'string', empty: false },
              country: { type: 'string', empty: false },
              postalCode: { type: 'string', empty: false },
              street: { type: 'string', empty: false },
            },
          },
        }}
        onSubmit={handleRegistrationSave}
        validateOnChange={false}
      >
        {({ onChange: handleChange, values, errors, touched, setTouched, onSubmit: handleSubmit }) => (
          <Dialog classes={{ root: clsx(classes.root, className) }} open onClose={() => handleClose()}>
            <FormInDialog onSubmit={handleSubmit}>
              <DialogTitle>Registrace</DialogTitle>
              <DialogContent>
                <RegistrationFields
                  className={classes.container}
                  value={values}
                  error={errors}
                  touched={touched}
                  setTouched={setTouched}
                  onChange={handleChange}
                />
              </DialogContent>
              <DialogActions className={classes.actions}>
                <Button variant='contained' color='primary' type='submit' className={classes.button}>
                  Registrovat
                </Button>
              </DialogActions>
            </FormInDialog>
          </Dialog>
        )}
      </FormikExtended>
    );
  },
);

RegistrationDialog.displayName = 'RegistrationDialog';

const useStylesRegistrationFields = makeStyles(
  (theme: Theme) =>
    createStyles<'notify' | 'passwordField', {}>({
      notify: {
        border: '1px solid',
        borderColor: theme.palette.warning.dark,
        padding: theme.spacing(1, 2),
        '& > p': {
          ...theme.typography.body1,
          fontSize: theme.typography.pxToRem(10),
        },
      },
      passwordField: {
        minWidth: 350,
        [theme.breakpoints.down('xs')]: {
          minWidth: 'unset',
        },
      },
    }),
  { name: 'RegistrationFields' },
);

const RegistrationFields: React.FC<{
  className?: string;
  error: FormikErrors<RegistrationState> | undefined;
  onChange: (fn: keyof RegistrationState) => (value: any) => void;
  setTouched: (nextTouched: FormikTouched<RegistrationState>, shouldValidate?: boolean) => void;
  touched: FormikTouched<RegistrationState> | undefined;
  value: RegistrationState;
}> = ({ className, error = {}, onChange: handleChange, setTouched, touched = {}, value }) => {
  const classes = useStylesRegistrationFields();

  const handleBlur = React.useCallback(
    <FN extends keyof FormikTouched<RegistrationState>>(fn: FN) =>
      (nextValue: any): void => {
        const nextTouched = {
          ...touched,
          [fn]: fn === 'postAddress' ? nextValue : true,
        };

        setTouched(nextTouched as FormikTouched<RegistrationState>, true);
      },
    [setTouched, touched],
  );

  const handlePostAddressChange = React.useCallback(
    (fn: keyof RegistrationState['postAddress']) =>
      (nextValue: any): void => {
        handleChange('postAddress')({
          ...value.postAddress,
          [fn]: nextValue,
        });
      },
    [handleChange, value.postAddress],
  );

  const handlePostAddressBlur = React.useCallback(
    (fn: keyof Required<FormikTouched<RegistrationState>>['postAddress']) => (/* event: any */): void => {
      handleBlur('postAddress')({
        ...touched.postAddress,
        [fn]: true,
      });
    },
    [handleBlur, touched.postAddress],
  );

  const postAddressError = formSectionErrorSub(formSectionErrorSub(error)?.postAddress);

  return (
    <div className={className}>
      <StorexTextField
        label='Jméno a přímení'
        value={value.personName}
        error={formFieldError(error.personName, touched.personName)}
        onBlur={handleBlur('personName')}
        onChange={handleChange('personName')}
        required
        fullWidth
      />
      <StorexTextField
        label='Email'
        value={value.emailAddress}
        error={formFieldError(error.emailAddress, touched.emailAddress)}
        onBlur={handleBlur('emailAddress')}
        onChange={handleChange('emailAddress')}
        required
        fullWidth
      />

      <div className={classes.notify}>
        <p>Po registraci Vám bude zaslán email s vygenerovaným heslem.</p>
        <p>Doporučujeme po prvním přihlášení vygenerované heslo změnit.</p>
        <p>Pro Vaši větší bezpečnost heslo uchovejte v tajnosti, zapamatujte si jej nebo uložte do prohlížeče.</p>
      </div>

      <StorexTextField
        label='Telefon'
        value={value.phoneNumber}
        error={formFieldError(error.phoneNumber, touched.phoneNumber)}
        onBlur={handleBlur('phoneNumber')}
        onChange={handleChange('phoneNumber')}
        required
        fullWidth
      />

      <FormSectionSubHeadline>Fakturační údaje</FormSectionSubHeadline>

      <CheckboxField
        switch
        label='Nakupuji na firmu'
        value={value.personOrCompany === 'company'}
        onChange={(v) => handleChange('personOrCompany')(v === true ? 'company' : 'person')}
        error={error.personOrCompany}
      />

      {value?.personOrCompany === 'company' && (
        <>
          <StorexTextField
            label='Název společnosti'
            value={value.companyName}
            onBlur={handleBlur('companyName')}
            onChange={handleChange('companyName')}
            error={formFieldError(error.companyName, touched.companyName)}
            required
            fullWidth
          />
          <StorexTextField
            label='IČO'
            value={value.companyRegNo}
            onBlur={handleBlur('companyRegNo')}
            onChange={handleChange('companyRegNo')}
            error={formFieldError(error.companyRegNo, touched.companyRegNo)}
            required
            fullWidth
          />
          <StorexTextField
            label='DIČ'
            value={value.companyTaxNo}
            onBlur={handleBlur('companyTaxNo')}
            onChange={handleChange('companyTaxNo')}
            error={formFieldError(error.companyTaxNo, touched.companyTaxNo)}
            required
            fullWidth
          />
        </>
      )}

      <StorexTextField
        label='Ulice'
        value={value.postAddress.street}
        error={formFieldError(postAddressError?.street, touched.postAddress?.street)}
        onBlur={handlePostAddressBlur('street')}
        onChange={handlePostAddressChange('street')}
        required
        fullWidth
      />

      <StorexTextField
        label='Město'
        value={value.postAddress.city}
        error={formFieldError(postAddressError?.city, touched.postAddress?.city)}
        onBlur={handlePostAddressBlur('city')}
        onChange={handlePostAddressChange('city')}
        required
        fullWidth
      />
      <StorexTextField
        label='PSČ'
        value={value.postAddress.postalCode}
        error={formFieldError(postAddressError?.postalCode, touched.postAddress?.postalCode)}
        onBlur={handlePostAddressBlur('postalCode')}
        onChange={handlePostAddressChange('postalCode')}
        required
        fullWidth
      />
      <StorexTextField
        label='Země'
        value={value?.postAddress.country === 'CZ' ? 'Česká republika' : undefined}
        error={formFieldError(postAddressError?.country, touched.postAddress?.country)}
        onChange={handlePostAddressChange('country')}
        fullWidth
        disabled
      />
    </div>
  );
};

RegistrationFields.displayName = 'RegistrationFields';

interface PasswordRenewDialogProps {
  onClose: () => void;
}
type PasswordRenewDialogClassKey = 'notify';
const usePasswordRenewDialogStyles = makeStyles(
  (theme: Theme) =>
    createStyles<PasswordRenewDialogClassKey, {}>({
      notify: {
        border: '1px solid',
        borderColor: theme.palette.warning.dark,
        padding: theme.spacing(1, 2),
        '& > p': {
          ...theme.typography.body1,
          fontSize: theme.typography.pxToRem(10),
        },
      },
    }),
  { name: 'PasswordRenewDialog' },
);

const PasswordRenewDialog: React.FC<PasswordRenewDialogProps> = ({ onClose: handleClose }) => {
  const t = useTranslate();
  const classes = usePasswordRenewDialogStyles();
  const apiClient = useRecoilValue(apiClientState);
  const showMessage = useSetRecoilState(enqueueSnackbarMessage);

  const sendPasswordRenew = React.useCallback(
    (values: { email: string }) => {
      return apiClient
        .post<{ msg: Error | string; status: 'error' } | { status: 'success' }, { email: string }>(
          '/v1/auth/renewPassword',
          { email: values.email },
        )
        .then((res) => {
          if (res.status === 'success') {
            showMessage({
              message: 'Zpráva odeslaná. Očekávejte své nové heslo na uvedené email adrese.',
              variant: 'success',
            });
            handleClose();
            //
          } else if (res.status === 'error') {
            if (process.env.NODE_ENV !== 'production') {
              console.error('Chyba pri odeslani passwordReniew.', res.msg);
            }
            showMessage({
              message: 'Zpráva neodeslána. Email neověřen nebo nastal problém při odesílání. Zkuste to znovu prosím.',
              variant: 'error',
            });
            handleClose();
          }
        })
        .catch((err) => {
          if (process.env.NODE_ENV !== 'production') {
            console.error('Chyba pri odeslani passwordReniew.', err);
          }
          showMessage({
            message: 'Zpráva neodeslána. Email neověřen nebo nastal problém při odesílání. Zkuste to znovu prosím.',
            variant: 'error',
          });
          handleClose();
        });
    },
    [apiClient, handleClose, showMessage],
  );

  return (
    <FormikExtended<{ email: string }>
      translator={t}
      initialValues={{ email: '' }}
      validationFastest
      validationSchema={{
        email: { type: 'email' },
      }}
      onSubmit={sendPasswordRenew}
      validateOnChange={false}
    >
      {({ values, errors, touched, setTouched, setValues, onSubmit: handleSubmit, isSubmitting }) => (
        <Dialog open onClose={handleClose}>
          <FormInDialog onSubmit={handleSubmit}>
            <DialogTitle>Zpomenuté heslo</DialogTitle>
            <DialogContent>
              <div className={classes.notify}>
                <p>
                  Po odeslání formuláře bude ověřeno, že uvedený email je registrován a bude Vám na něj doručena zpráva
                  s novým heslem.
                </p>
                <p>Doporučujeme po prvním přihlášení vygenerované heslo změnit.</p>
                <p>
                  Pro Vaši větší bezpečnost heslo uchovejte v tajnosti, zapamatujte si jej nebo uložte do prohlížeče.
                </p>
              </div>
              <StorexTextField
                label='Email'
                value={values.email}
                error={formFieldError(errors.email, touched.email)}
                onBlur={() => {
                  setTouched({ email: true });
                }}
                onChange={(v) => {
                  setValues({ email: v });
                }}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                type='button'
                variant='outlined'
                color='secondary'
                onClick={() => {
                  handleClose();
                }}
                disabled={isSubmitting}
              >
                Zrušit
              </Button>
              <Button type='submit' variant='contained' color='primary' disabled={isSubmitting}>
                Odeslat
              </Button>
            </DialogActions>
          </FormInDialog>
        </Dialog>
      )}
    </FormikExtended>
  );
};
