import Link, { LinkProps } from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface AppLayoutHeaderWideLogoProps extends Omit<LinkProps, 'classes' | 'ref'> {
  classes?: Partial<Record<AppLayoutHeaderWideLogoClassKey, string>>;
  ref?: React.Ref<any>;
}

export type AppLayoutHeaderWideLogoClassKey = 'root';

const themeSettings = { name: 'AppLayoutHeaderWideLogo' };
const useStyles = makeStyles(
  (theme) => ({
    root: {
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'flex-start',
      minWidth: theme.spacing(32),
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
      fontSize: '1.8rem',
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.white,
    },
  }),
  themeSettings,
);

const AppLayoutHeaderWideLogo: React.FC<AppLayoutHeaderWideLogoProps> = React.forwardRef(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const t = useTranslate();

    return (
      <Link ref={ref} component={RouterLink} className={clsx(classes.root, className)} to='/'>
        {t('homepage/logo')}
      </Link>
    );
  },
);

AppLayoutHeaderWideLogo.displayName = 'AppLayoutHeaderWideLogo';

export default AppLayoutHeaderWideLogo;
