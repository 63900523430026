import uniq from 'lodash/uniq';
import { useCallback } from 'react';
import { selectorFamily, useRecoilState, useRecoilValue } from 'recoil';
import { PickByValue, ValuesType } from 'utility-types';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { apiClientState } from '../appState';
import { useParameterTypeValueEnum } from '../products/productParameters';
import { productParameterTypesQuery } from '../products/productParameterTypes';
import { TireSeasonType } from '../tireSeasonTypes';
import { VehicleType } from '../vehicleTypes';
// import { productParameterTypesQuery } from '../products/productParameterTypes';
import { CatalogueFilter, catalogueFilterState, CataloguePriceRange } from './catalogue';

const extendWithVehicleType = (paramCode: string, vehicleType: VehicleType | null | undefined): string =>
  `${paramCode}${vehicleType ? `/${vehicleType}` : ''}`;

/**
 * Sezona | Obdobi
 */
export const useCatalogueFilterFieldStateTireSeason = (): CatalogueFilterFiledArrayValue<TireSeasonType> => {
  const [filter, updateFilter] = useRecoilState(catalogueFilterState);
  const data: { label: string; value: TireSeasonType }[] = [
    { value: 'summer', label: 'Letní' },
    { value: 'winter', label: 'Zimní' },
    { value: 'all-season', label: 'Celoroční' },
  ];

  const handleChange = useCallback(
    (ts: TireSeasonType) => (flag: boolean | undefined) => {
      updateFilter(updateFilterValueArray('tireSeasons', ts, flag));
    },
    [updateFilter],
  );

  const isSelected = useCallback(
    (ts: TireSeasonType): boolean => (filter.tireSeasons || []).includes(ts),
    [filter.tireSeasons],
  );

  return {
    data,
    handleChange,
    isSelected,
  };
};

/**
 * Rozmery | sirka | profil | prumer
 */
export const useCatalogueFilterFieldStateDimensions = (
  subField: 'diameter' | 'profile' | 'width',
): CatalogueFilterFiledSingleValue<string> => {
  const [filter, updateFilter] = useRecoilState(catalogueFilterState);

  const vehicleType = filter.vehicleType;
  const productParameterTypes = useRecoilValue(productParameterTypesQuery);

  const parameterCode = {
    diameter: extendWithVehicleType('/diameter', vehicleType),
    profile: extendWithVehicleType('/profile', vehicleType),
    width: extendWithVehicleType('/width', vehicleType),
  }[subField];

  const data = useParameterTypeValueEnum(productParameterTypes, parameterCode).map((i) => ({
    label: i,
    value: i,
  }));

  const handleChange = useCallback(
    (nextV: undefined | string) => {
      updateFilter((prev) => ({
        ...prev,
        dimensions: {
          ...prev.dimensions,
          [subField]: nextV,
        },
      }));
    },
    [updateFilter, subField],
  );

  return {
    data,
    handleChange,
    value: (filter.dimensions || {})[subField],
  };
};

/**
 * Vyrobce | Manufacturer
 */
export const useCatalogueFilterFieldStateManufacturers = (): CatalogueFilterFiledArrayValue<string> & {
  handleReset: () => void;
} => {
  const filter = useRecoilValue(catalogueFilterState);
  const data = useRecoilValue(catalogueFilterManufacturersFamilyQuery({ ...filter, manufacturers: undefined })).map(
    (i) => ({
      value: i.id,
      label: i.name,
    }),
  );
  return useCatalogueFilterFieldArrayHandlers('manufacturers', data);
};

/**
 * Rozsah | cena | pricerange
 */
export const useCatalogueFilterFieldStatePriceRange = (): {
  data: CataloguePriceRange;
  handleChange: (nextPriceRange: CataloguePriceRange | undefined) => void;
  value: CataloguePriceRange;
} => {
  const [filter, updateFilter] = useRecoilState(catalogueFilterState);
  const data = useRecoilValue(catalogueFilterPriceRangeFamilyQuery({ ...filter, priceRange: undefined }));

  const handleChange = useCallback(
    (nextPriceRange: CataloguePriceRange | undefined): void => {
      updateFilter((prev) => ({
        ...prev,
        priceRange: nextPriceRange,
      }));
    },
    [updateFilter],
  );

  const value = filter.priceRange || data;

  return {
    data,
    handleChange,
    value,
  };
};

/**
 * Naprava
 */
export const useCatalogueFilterFieldStateAxles = (): CatalogueFilterFiledArrayValue<string> & {
  handleReset: () => void;
} => {
  const filter = useRecoilValue(catalogueFilterState);
  const productParameterTypes = useRecoilValue(productParameterTypesQuery);

  const data = useParameterTypeValueEnum(productParameterTypes, extendWithVehicleType('/axle', filter.vehicleType)).map(
    (i) => ({
      label: i,
      value: i,
    }),
  );

  return useCatalogueFilterFieldArrayHandlers('axles', data);
};

/**
 * Provoz | usage
 *
 * toto je u tir `Provoz`
 */
export const useCatalogueFilterFieldStateUsages = (): CatalogueFilterFiledArrayValue<string> & {
  handleReset: () => void;
} => {
  const filter = useRecoilValue(catalogueFilterState);
  const productParameterTypes = useRecoilValue(productParameterTypesQuery);

  const data = useParameterTypeValueEnum(
    productParameterTypes,
    extendWithVehicleType('/usage', filter.vehicleType),
  ).map((i) => ({
    label: i,
    value: i,
  }));

  return useCatalogueFilterFieldArrayHandlers('usages', data);
};

/**
 * Vyuziti | utilization
 *
 * toto je u moto `Vyuziti`
 */
export const useCatalogueFilterFieldStateUtilizations = (): CatalogueFilterFiledArrayValue<string> & {
  handleReset: () => void;
} => {
  const filter = useRecoilValue(catalogueFilterState);
  const productParameterTypes = useRecoilValue(productParameterTypesQuery);

  const data = useParameterTypeValueEnum(
    productParameterTypes,
    extendWithVehicleType('/utilization', filter.vehicleType),
  ).map((i) => ({
    label: i,
    value: i,
  }));

  return useCatalogueFilterFieldArrayHandlers('utilizations', data);
};

/**
 * Index nosnosti
 */
export const useCatalogueFilterFieldStateLoadIndexes = (): CatalogueFilterFiledArrayValue<string> & {
  handleReset: () => void;
} => {
  const filter = useRecoilValue(catalogueFilterState);
  const productParameterTypes = useRecoilValue(productParameterTypesQuery);
  const data = useParameterTypeValueEnum(
    productParameterTypes,
    extendWithVehicleType('/loadIndex', filter.vehicleType),
  ).map((i) => ({
    label: i,
    value: i,
  }));

  return useCatalogueFilterFieldArrayHandlers('loadIndexes', data);
};

/**
 * Index rychlosti
 */
export const useCatalogueFilterFieldStateSpeedIndexes = (): CatalogueFilterFiledArrayValue<string> & {
  handleReset: () => void;
} => {
  const filter = useRecoilValue(catalogueFilterState);
  const productParameterTypes = useRecoilValue(productParameterTypesQuery);
  const data = useParameterTypeValueEnum(
    productParameterTypes,
    extendWithVehicleType('/speed', filter.vehicleType),
  ).map((i) => ({
    label: i,
    value: i.split(' ')[0], // z hodnoty 'R (170 km/h)' => 'R'
  }));

  return useCatalogueFilterFieldArrayHandlers('speedIndexes', data);
};

/**
 * Index rychlosti
 */
export const useCatalogueFilterFieldStateHomologations = (): CatalogueFilterFiledArrayValue<string> & {
  handleReset: () => void;
} => {
  const filter = useRecoilValue(catalogueFilterState);
  const productParameterTypes = useRecoilValue(productParameterTypesQuery);
  const data = useParameterTypeValueEnum(
    productParameterTypes,
    extendWithVehicleType('/homologation', filter.vehicleType),
  ).map((i) => ({
    label: i,
    value: i.split(' ')[0], // z hodnoty 'R (170 km/h)' => 'R'
  }));

  return useCatalogueFilterFieldArrayHandlers('homologations', data);
};

/**
 * Skladovost | Skladem | Availability
 */
export const useCatalogueFilterFieldStateOnStock = (): CatalogueFilterFiledArrayValue<'available' | 'distributor'> & {
  handleReset: () => void;
} => {
  const data: { label: string; value: 'available' | 'distributor' }[] = [
    { value: 'available', label: 'Skladem' },
    { value: 'distributor', label: 'Externí sklad' },
  ];

  return useCatalogueFilterFieldArrayHandlers('onStock', data);
};

/**
 * Zvyhodnena nabidka | inAction
 */
export const useCatalogueFilterFieldStateInAction = (): CatalogueFilterFiledArrayValue<'action' | 'new' | 'sale'> & {
  handleReset: () => void;
} => {
  const data: { label: string; value: 'action' | 'new' | 'sale' }[] = [
    { value: 'action', label: 'Cenový hit' },
    { value: 'sale', label: 'Výprodej' },
    { value: 'new', label: 'Novinka' },
  ];

  return useCatalogueFilterFieldArrayHandlers('inAction', data);
};

/**
 * Vlastnosti | options
 */
export const useCatalogueFilterFieldStateOptions = (): CatalogueFilterFiledArrayValue<'runflat'> & {
  handleReset: () => void;
} => {
  const data: { label: string; value: 'runflat' }[] = [{ value: 'runflat', label: 'Runflat' }];

  return useCatalogueFilterFieldArrayHandlers('options', data);
};

/**
 * Vyhledavany text
 */
export const useCatalogueFilterFieldStateSearchTerm = (): {
  handleChange: (newValue: string | undefined) => void;
  handleReset: () => void;
  value: string | undefined;
} => {
  const [filter, updateFilter] = useRecoilState(catalogueFilterState);

  const handleChange = useCallback(
    (nextV: undefined | string) => {
      updateFilter((prev) => ({
        ...prev,
        searchTerm: nextV,
      }));
    },
    [updateFilter],
  );

  const handleReset = useCallback((): void => {
    updateFilter((prev) => ({
      ...prev,
      searchTerm: undefined,
    }));
  }, [updateFilter]);

  return {
    handleChange,
    handleReset,
    value: filter.searchTerm,
  };
};

export const catalogueFilterManufacturersFamilyQuery = selectorFamily<
  CatalogueFilterManufacturer[],
  Readonly<CatalogueFilter>
>({
  key: 'catalogueFilterManufacturersFamilyQuery',
  get:
    (filter) =>
    ({ get }) => {
      return getCatalogueFilterManufacturers(get(apiClientState), filter);
    },
});

export const getCatalogueFilterManufacturers = (
  apiClient: ApiClient,
  filter: CatalogueFilter,
): Promise<CatalogueFilterManufacturer[]> => {
  return apiClient.get('/v1/catalogue/filter/manufacturers', { filter });
};

export const catalogueFilterPriceRangeFamilyQuery = selectorFamily<CataloguePriceRange, Readonly<CatalogueFilter>>({
  key: 'catalogueFilterPriceRangeFamilyQuery',
  get:
    (filter) =>
    ({ get }) => {
      return getCatalogueFilterPriceRange(get(apiClientState), filter);
    },
});

export const getCatalogueFilterPriceRange = (
  apiClient: ApiClient,
  filter: CatalogueFilter,
): Promise<CataloguePriceRange> => {
  return apiClient
    .get<{ max: number; min: number } | null, {}>('/v1/catalogue/filter/price-range', { filter })
    .then((r) => r || { min: 0, max: Number.MAX_SAFE_INTEGER })
    .then((r) => [r.min, r.max]);
};
const useCatalogueFilterFieldArrayHandlers = <
  FN extends CatalogueFilterArrayKey,
  T extends ValuesType<Required<CatalogueFilter>[FN]>,
>(
  fieldName: FN,
  data: { label: string; value: T }[],
): CatalogueFilterFiledArrayValue<T> & {
  handleReset: () => void;
} => {
  const [filter, updateFilter] = useRecoilState(catalogueFilterState);
  const handleChange = useCallback(
    (code: T) => (flag: boolean | undefined) => {
      updateFilter(updateFilterValueArray(fieldName, code, flag));
    },
    [fieldName, updateFilter],
  );

  const isSelected = useCallback(
    (code: T): boolean => ((filter[fieldName] || []) as any).includes(code),
    [fieldName, filter],
  );

  const handleReset = useCallback((): void => {
    updateFilter((prev) => ({
      ...prev,
      [fieldName]: undefined,
    }));
  }, [fieldName, updateFilter]);

  return {
    data,
    handleChange,
    handleReset,
    isSelected,
  };
};

// type CatalogueFilterKey = keyof CatalogueFilter;
type CatalogueFilterArrayKey = keyof PickByValue<Required<CatalogueFilter>, any[]>;

const updateFilterValueArray =
  <FN extends CatalogueFilterArrayKey>(
    fn: FN,
    value: ValuesType<Required<CatalogueFilter>[FN]>,
    flag: boolean | undefined,
  ) =>
  (prev: CatalogueFilter): CatalogueFilter => ({
    ...prev,
    [fn]: uniq(
      flag
        ? [...((prev[fn] as any[]) || []), value]
        : ((y) =>
            y > -1
              ? [...((prev[fn] || []) as any[]).slice(0, y), ...((prev[fn] || []) as any[]).slice(y + 1)]
              : prev[fn])(((prev[fn] || []) as any[]).findIndex((i) => i === value)),
    ),
  });

export interface CatalogueFilterManufacturer {
  count?: number;
  id: string;
  name: string;
}

export interface CatalogueFilterFiledSingleValue<T> {
  data: { label: string; value: T }[];
  handleChange: (newValue: T | undefined) => void;
  value: T | undefined;
}

export interface CatalogueFilterFiledArrayValue<T> {
  data: { label: string; value: T }[];
  handleChange: (ts: T) => (flag: boolean | undefined) => void;
  isSelected: (ts: T) => boolean;
}
