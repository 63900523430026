import { makeStyles, Theme } from '@material-ui/core/styles';
import PasswordField, { PasswordFieldProps } from '@sprinx/react-mui-fields/PasswordField';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 500,
    // TODO: toto je fix nakoolko zrejme password field neposiela spravne dalej InputPros
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      minWidth: 'unset',
      width: '100%',
    },
  },
  input: {
    backgroundColor: theme.palette.common.white,
  },
}));

const StorexPasswordField: React.FC<PasswordFieldProps> = ({
  className,
  InputProps,
  margin = 'dense',
  variant = 'outlined',
  ...rest
}) => {
  const xClasses = useStyles({});
  return (
    <PasswordField
      className={clsx(xClasses.root, className)}
      margin={margin}
      variant={variant}
      InputProps={{
        ...InputProps,
        classes: {
          ...InputProps?.classes,
          input: xClasses.input,
        },
      }}
      {...rest}
    />
  );
};

StorexPasswordField.displayName = 'StorexPasswordField';

export default StorexPasswordField;
