import Badge from '@material-ui/core/Badge';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import BasketIcon from '@material-ui/icons/ShoppingBasket';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { localeState } from '../../api/appState';
import { shoppingCartNumberOfSubjectsSelector } from '../../api/shoppingCart';
import { routeUrl } from '../../routesBuilder';

export interface ShoppingCartAppBarActionProps extends Omit<IconButtonProps<RouterLink>, 'classes' | 'to'> {
  classes?: Partial<Record<ShoppingCartAppBarActionClassKey, string>>;
}

export type ShoppingCartAppBarActionClassKey = 'root' | 'icon';

const themeSettings = { name: 'ShoppingCartAppBarAction' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<ShoppingCartAppBarActionClassKey, {}>({
      root: {
        '&:hover $icon': { fill: theme.palette.common.white },
      },
      icon: {},
    }),
  themeSettings,
);

const ShoppingCartAppBarAction = React.forwardRef<HTMLAnchorElement, ShoppingCartAppBarActionProps>(
  ({ className, classes: pClasses, ...rest }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const locale = useRecoilValue(localeState);
    const subjectsNumber = useRecoilValue(shoppingCartNumberOfSubjectsSelector);

    return (
      <Tooltip title='Košík'>
        <IconButton
          ref={ref}
          {...rest}
          className={clsx(classes.root, className)}
          component={RouterLink}
          to={routeUrl('cartSummary', { locale, params: undefined })}
        >
          <Badge badgeContent={subjectsNumber} color='secondary'>
            <BasketIcon className={classes.icon} />
          </Badge>
        </IconButton>
      </Tooltip>
    );
  },
);

ShoppingCartAppBarAction.displayName = 'ShoppingCartAppBarAction';

export default withThemeProps(themeSettings)(ShoppingCartAppBarAction);
