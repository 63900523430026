import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Alert from '@material-ui/lab/Alert';
import dateFormatter from '@sprinx/formatting/dateFormatter';
import React from 'react';
import { BranchListItem } from '../../api/articles/Branch';
import { TireChangeDateListItem, TireChangeDayTimeListItem } from '../../api/tiresChange';
import TireChangeWizzardStepper from './TireChangeWizzardStepper';

export interface TireChangeWizzardStep3Props {
  branch: BranchListItem;
  onClose: () => void;
  onNext: (values: TireChangeDayTimeListItem) => void;
  onPrev: () => void;
  selectedDay: TireChangeDateListItem;
  timeList: TireChangeDayTimeListItem[];
  titlePrefix: string;
}

export type TireChangeWizzardStep3ClassKey =
  | 'content'
  | 'headline'
  | 'actions'
  | 'actionsRight'
  | 'summary'
  | 'title'
  | 'alert';
const useStyles = makeStyles((theme: Theme) =>
  createStyles<TireChangeWizzardStep3ClassKey, {}>({
    content: {
      width: 500,
      display: 'grid',
      alignSelf: 'center',
      gridTemplateColumns: 'auto auto auto auto',
      '& button': {
        flex: '1 0 auto',
      },

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '50vh',
        overflow: 'auto',
      },
    },
    headline: { fontWeight: theme.typography.fontWeightMedium, textAlign: 'center', paddingBottom: theme.spacing(1) },
    actions: {
      justifyContent: 'unset',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
        alignItems: 'stretch',
        '& > button': {
          margin: theme.spacing(0.5, 0),
          marginLeft: '0 !important',
        },
      },
    },
    actionsRight: {
      marginLeft: 'auto !important',
    },
    title: {
      ...theme.typography.subtitle1,
      textAlign: 'center',
      padding: theme.spacing(2),
    },
    summary: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      border: 'solid 1px',
      borderColor: theme.palette.divider,
    },
    alert: {
      margin: theme.spacing(2, 0),
    },
  }),
);

const TireChangeWizzardStep3: React.FC<TireChangeWizzardStep3Props> = ({
  branch,
  onClose,
  onNext,
  onPrev,
  selectedDay,
  timeList,
  titlePrefix,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const narrow = useMediaQuery(theme.breakpoints.down('sm'));
  const [selected, setSelected] = React.useState<TireChangeDayTimeListItem | undefined>(undefined);
  const TimeListComponent = narrow ? TimeNarrowButton : TimeWideButton;

  return (
    <>
      <DialogTitle>{titlePrefix}</DialogTitle>
      <TireChangeWizzardStepper activeStep={2} />
      <DialogContent>
        <div className={classes.summary}>
          <span>
            Storex - provozovna: <strong>{branch.title}</strong>
          </span>
          <span>
            Vybrané datum: <strong>{dateFormatter(selectedDay.datum)}</strong>
          </span>
        </div>
        {!timeList || !Array.isArray(timeList) || !timeList.length ? (
          <Alert
            className={classes.alert}
            severity='warning'
            action={
              <Button color='inherit' size='small' variant='outlined' onClick={() => onPrev()}>
                Předchozí
              </Button>
            }
          >
            Pro zvolený den nebyly nalezeny žádné časové sloty.
          </Alert>
        ) : (
          <>
            <div className={classes.title}>
              Vyberte si, prosím, <strong>ČAS</strong>, který vám vyhovuje.
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className={classes.content}>
                <TimeListComponent value={selected} onChange={setSelected} timeList={timeList} />
              </div>
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button color='secondary' variant='outlined' onClick={() => onPrev()}>
          Předchozí
        </Button>
        <Button className={classes.actionsRight} color='secondary' variant='outlined' onClick={() => onClose()}>
          Zrušit
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            if (selected) {
              onNext(selected);
            }
          }}
          disabled={!selected}
        >
          Rekapitulace před objednáním
        </Button>
      </DialogActions>
    </>
  );
};

TireChangeWizzardStep3.displayName = 'TireChangeWizzardStep3';

export default TireChangeWizzardStep3;

const TimeNarrowButton: React.FC<{
  onChange: (value: TireChangeDayTimeListItem | undefined) => void;
  timeList: TireChangeDayTimeListItem[];
  value: TireChangeDayTimeListItem | undefined;
}> = ({ timeList = [], onChange: handleChange, value }) => {
  const onlyAvailableTime = timeList.filter((i) => i.moznoRezervovat);
  return (
    <>
      {onlyAvailableTime.map((item, idx) => (
        <Button
          key={idx.toString()}
          component='button'
          color={'primary'}
          variant={item === value ? 'contained' : 'text'}
          onClick={() => {
            handleChange(item);
          }}
        >
          {`${item.hodina}:${item.minuta.toString().padStart(2, '0')}`}
        </Button>
      ))}
    </>
  );
};
const TimeWideButton: React.FC<{
  onChange: (value: TireChangeDayTimeListItem | undefined) => void;
  timeList: TireChangeDayTimeListItem[];
  value: TireChangeDayTimeListItem | undefined;
}> = ({ timeList = [], onChange: handleChange, value }) => {
  return (
    <>
      {timeList.map((item, idx) => (
        <Button
          key={idx.toString()}
          disabled={!item.moznoRezervovat}
          component='button'
          color={'primary'}
          variant={item === value ? 'contained' : 'text'}
          onClick={() => {
            handleChange(item);
          }}
        >
          {`${item.hodina}:${item.minuta.toString().padStart(2, '0')}`}
        </Button>
      ))}
    </>
  );
};
