import { useMemo } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { ProductStockQuantity } from '../@sprinx/knihovka-types';
import { BranchListItem } from '../api/articles/Branch';
import { branchListQuery } from '../api/articles/branchList';

export interface FilterAvailabilityByItem {
  product: {
    id: string;
    stockQuantity?: ProductStockQuantity;
  };
  quantity: number;
}

export default function useFilteredBranches(
  filterAvailabilityBy?: FilterAvailabilityByItem[],
):
  | {
      contents: BranchListItem[];
      state: 'hasValue';
    }
  | {
      contents: Promise<BranchListItem[]>;
      state: 'loading';
    }
  | {
      contents: Error;
      state: 'hasError';
    } {
  const branchesLoadable = useRecoilValueLoadable(branchListQuery);
  const branchesLoadableCmp =
    branchesLoadable.state === 'loading'
      ? '$$loading$$'
      : branchesLoadable.state === 'hasError'
      ? '$$error$$'
      : !branchesLoadable.contents.rows.length
      ? '$$empty$$'
      : branchesLoadable.contents.rows.reduce((s, i) => `${s}:${i.costCenterCode}`, '');
  const filterAvailabilityByCmp = filterAvailabilityBy
    ? filterAvailabilityBy.reduce((s, i) => `${s}:${i.product.id}:${i.quantity}`, '')
    : '$$undefined$$';

  return useMemo(
    ():
      | {
          contents: BranchListItem[];
          state: 'hasValue';
        }
      | {
          contents: Promise<BranchListItem[]>;
          state: 'loading';
        }
      | {
          contents: Error;
          state: 'hasError';
        } => {
      if (branchesLoadable.state === 'loading' || branchesLoadable.state === 'hasError') return branchesLoadable as any;
      const branches = branchesLoadable.contents.rows.filter((i) => i.body);

      const availableBranches = filterAvailabilityBy
        ? branches.filter((b) => {
            const ccc = b.costCenterCode;
            return filterAvailabilityBy
              .reduce((r, i) => {
                const idx = r.findIndex((j) => j.product.id === i.product.id);

                return idx === -1
                  ? [...r, i]
                  : [...r.slice(0, idx), { ...r[idx], quantity: r[idx].quantity + i.quantity }, ...r.slice(idx + 1)];
              }, [] as FilterAvailabilityByItem[])
              .reduce((c, i) => {
                const q = i.product.stockQuantity?.detail?.find((stc) => stc.warehouse === ccc)?.quantity || 0;
                return c && q >= i.quantity;
              }, true as boolean);
          })
        : branches;

      return { contents: availableBranches, state: 'hasValue' };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [branchesLoadableCmp, filterAvailabilityByCmp],
  );
}
