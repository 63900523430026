import { SupportedLocale } from '../supported';

export const DEFAULT_VEHICLE_TYPE: VehicleType = 'passenger';

export type VehicleType = 'passenger' | 'van' | 'truck' | 'moto';

const vehicleTypeT: Record<VehicleType, Record<SupportedLocale, string>> = {
  truck: {
    cs: 'nakladni-auta',
  },
  moto: {
    cs: 'motocykly',
  },
  passenger: {
    cs: 'osobni-auta',
  },
  van: {
    cs: 'dodavky',
  },
};

export const vehicleTypeToRoute = (
  locale: SupportedLocale,
  vehicleType: VehicleType | undefined,
): string | undefined => {
  return vehicleType ? vehicleTypeT[vehicleType][locale] : undefined;
};

export const routeParamToVehicleType = (
  locale: SupportedLocale,
  param: string | undefined,
): VehicleType | false | undefined => {
  if (param === undefined) return undefined;

  const found = Object.entries(vehicleTypeT).reduce<VehicleType | undefined>(
    (a, [vt, t]) => (t[locale] === param ? (vt as VehicleType) : a),
    undefined,
  );

  return found || false;
};
