import type { CreateValidatorOptionsRuleDefinition, ValidatonRuleFunction } from '@sprinx/react-forms';

export default function companySelectedStringFastestValidatorRule(): CreateValidatorOptionsRuleDefinition {
  return ['companyStringRequired', companySelectedStringFastestValidatorRuleCheck];
}

const companySelectedStringFastestValidatorRuleCheck: ValidatonRuleFunction = function ({ messages }) {
  const src = `
    if (parent.personOrCompany === 'company') {
      if (typeof value !== 'string') {
        ${this.makeError({ type: 'string', messages })}
      }

      if (value.length === 0) {
        ${this.makeError({ type: 'stringEmpty', messages })}
      }
    }
    return value;
  `;

  return { source: src };
};
