import { makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import AppLayoutPersonIconActions from './AppLayoutPersonIconActions';

export type AppLayoutHeaderNarrowMainNavigationAppBarProps = StandardDivProps<AppLayoutHeaderNarrowMainNavigationAppBarClassKey>;

export type AppLayoutHeaderNarrowMainNavigationAppBarClassKey = 'root';

const themeSettings = { name: 'AppLayoutHeaderNarrowMainNavigationAppBar' };
const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexGrow: 1,
      paddingLeft: theme.spacing(1),
      // '& svg': { fill: theme.palette.primary.dark },
      '& button:hover': {
        '& svg': { fill: theme.palette.common.white },
      },
      '& > div:last-of-type': { marginLeft: 'auto', zIndex: theme.zIndex.appBar + 1 },
    },
    person: {
      paddingRight: 0,
    },
    icon: {
      fill: theme.palette.common.white,
    },
  }),
  themeSettings,
);

const AppLayoutHeaderNarrowMainNavigationAppBar: React.FC<AppLayoutHeaderNarrowMainNavigationAppBarProps> = React.forwardRef(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <AppLayoutPersonIconActions classes={{ root: classes.person, icon: classes.icon }} />
      </div>
    );
  },
);

AppLayoutHeaderNarrowMainNavigationAppBar.displayName = 'AppLayoutHeaderNarrowMainNavigationAppBar';

export default withThemeProps(themeSettings)(AppLayoutHeaderNarrowMainNavigationAppBar);
