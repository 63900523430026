import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme, useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import SelectFieldItem from '@sprinx/react-mui-fields/SelectFieldItem';
import clsx from 'clsx';
import React from 'react';
import { BranchListItem } from '../../api/articles/Branch';
import {
  ChooseWarehouseStoksFilterAvailabilityByStatus,
  ChooseWarehouseStoksLoadable,
} from '../ChooseWarehouseStoks/ChooseWarehouseStoks';
import StorexSelectFormField from '../StorexSelectFormField';
import ChooseWarehouseOnMapDialog from './ChooseWarehouseOnMapDialog';

export interface ChooseWarehouseProps extends Omit<StandardDivProps<ChooseWarehouseClassKey>, 'onChange'> {
  branchesLoadable: ChooseWarehouseStoksLoadable;
  filterAvailabilityByStatus: ChooseWarehouseStoksFilterAvailabilityByStatus;
  onChange: (value: BranchListItem | undefined) => void;
  showConfirmationButton?: boolean;
  value: BranchListItem | undefined;
}

export type ChooseWarehouseClassKey = 'root' | 'button';

const themeSettings = { name: 'ChooseWarehouse' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<ChooseWarehouseClassKey, {}>({
      root: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
        flexGrow: 1,
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column',
        },
      },
      button: {
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
          margin: 0,
        },
      },
    }),
  themeSettings,
);

const ChooseWarehouse = React.forwardRef<HTMLDivElement, ChooseWarehouseProps>(
  (
    {
      branchesLoadable,
      className,
      classes: pClasses,
      filterAvailabilityByStatus,
      onChange: handleChange,
      showConfirmationButton = false,
      value,
    },
    ref,
  ) => {
    const classes = useStyles({ classes: pClasses });
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('xs'));
    const [showChooseOnMapDialog, setShowChooseOnMapDialog] = React.useState<boolean>(false);

    if (branchesLoadable.state === 'hasError') {
      return <Alert severity='error'>Chyba při získání poboček.</Alert>;
    }

    if (branchesLoadable.state === 'loading') {
      return <Skeleton width={200} height={56} />;
    }

    if (branchesLoadable.contents.length === 0) {
      return (
        <Alert severity='warning'>
          {filterAvailabilityByStatus === 'none' && 'Nejsou dostupné prodejny.'}
          {filterAvailabilityByStatus === 'single' &&
            'Produkt není dostupný na žádné prodejně v požadovaném množství. Zkuste upravit množství produktu.'}
          {filterAvailabilityByStatus === 'multi' &&
            'Některý nebo všechny produkty nejsou dostupné na žádné prodejně v požadovaném množství. Zkuste upravit množství produktů.'}
        </Alert>
      );
    }

    const branches = branchesLoadable.contents;

    return (
      <>
        <div ref={ref} className={clsx(classes.root, className)}>
          <BranchSelectField onChange={handleChange} value={value} branches={branches} />
          {!mobile && (
            <Button
              color='secondary'
              onClick={() => setShowChooseOnMapDialog(true)}
              size='small'
              className={classes.button}
            >
              Zobrazit na mapě
            </Button>
          )}
          {showConfirmationButton && (
            <Button
              color='secondary'
              onClick={() => handleChange(value)}
              size='small'
              className={classes.button}
              disabled={!value}
            >
              Potvrdit
            </Button>
          )}
        </div>
        {showChooseOnMapDialog && (
          <ChooseWarehouseOnMapDialog
            branches={branches}
            open
            onClose={() => setShowChooseOnMapDialog(false)}
            onChange={handleChange}
          />
        )}
      </>
    );
  },
);

ChooseWarehouse.displayName = 'ChooseWarehouse';

export default withThemeProps(themeSettings)(ChooseWarehouse);

const BranchSelectField = withStyles(() => ({
  root: {
    flexGrow: 1,
  },
}))(
  ({
    branches,
    classes,
    value,
    onChange: handleChange,
  }: {
    branches: BranchListItem[];
    classes: Record<'root', string>;
    onChange: (value: BranchListItem) => void;
    value: BranchListItem | undefined;
  }) => {
    return (
      <StorexSelectFormField
        className={classes.root}
        value={value}
        onChange={handleChange}
        label='Vyberte pobočku'
        variant='outlined'
      >
        {(branches || []).map((branch, idx) => (
          <SelectFieldItem key={idx.toString()} value={branch}>
            {branch.teaser}
          </SelectFieldItem>
        ))}
      </StorexSelectFormField>
    );
  },
);
