import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  shoppingCartInitializationDecisionState,
  shoppingCartState,
  useShoppingCartPrepareForSessionCall,
} from '../../api/shoppingCart';

const ShoppingBootstrap: React.FC = () => {
  const setShoppingCart = useSetRecoilState(shoppingCartState);
  const setDecistion = useSetRecoilState(shoppingCartInitializationDecisionState);

  const prepareCart = useShoppingCartPrepareForSessionCall();

  const cart = useRecoilValue(shoppingCartState);

  React.useEffect(() => {
    if (!cart) {
      prepareCart().then((res) => {
        // TODO: handle result error

        if (res.status) {
          setDecistion(res);
        } else if (res.cart) {
          setShoppingCart(res.cart);
        }
      });
    }
  }, [cart, prepareCart, setDecistion, setShoppingCart]);

  return null;
};

ShoppingBootstrap.displayName = 'ShoppingBootstrap';

export default ShoppingBootstrap;
