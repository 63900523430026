import { DefaultValue, selector } from 'recoil';
import { BranchListItem } from '../articles/Branch';
import { userPrefferedBranchState } from '../auth/user';

export interface CheckoutShippingPersonalPickupState {
  branch: BranchListItem | undefined;
}

export const chceckoutPersonalPickupState = selector<CheckoutShippingPersonalPickupState>({
  key: 'chceckoutPersonalPickup',
  get: ({ get }) => {
    const userPrefferedBranch = get(userPrefferedBranchState);

    return {
      branch: userPrefferedBranch,
    };
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(userPrefferedBranchState, undefined);
    } else {
      const { branch } = newValue;
      set(userPrefferedBranchState, branch);
    }
  },
});
