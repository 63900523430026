import { QueryOrderSettings, QueryPaginationSettings } from '@sprinx/query-builder';
import { History } from 'history';
import qs from 'qs';
import { atom, DefaultValue, selector } from 'recoil';
import { MoleculerListResult, Product, ProductRecord } from '../../@sprinx/knihovka-types';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { createListInitialSettings } from '../../@sprinx/react-after-razzle/filteredLists';
import { atomEffectSyncWithSession } from '../../@sprinx/react-after-razzle/stateEffects';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { routeUrl } from '../../routesBuilder';
import { SupportedLocale } from '../../supported';
import { apiClientState, currencyState, localeState, pricesTypeState, regionState } from '../appState';
import transformProduct from '../products/transformProduct';
import { DEFAULT_TIRE_SEASON, TireSeasonType } from '../tireSeasonTypes';
import { DEFAULT_VEHICLE_TYPE, VehicleType } from '../vehicleTypes';

let history: History | undefined;

export const setHistory = (h: History | undefined): void => {
  history = h;
};

export type CataloguePageProduct = Product<
  ProductRecord,
  // Populates
  'displayNameAsName' | 'prices' | 'stockQuantity' | 'parameters' | 'calculatedPrices',
  // Fields
  | 'id'
  | 'name'
  | 'displayName'
  | 'sku'
  | 'pn'
  | 'ean'
  | 'description'
  | 'image'
  | 'taxonomies'
  // | 'listPrice'
  // | 'listPriceWithTax'
  // | 'salePrice'
  // | 'salePriceWithTax'
  | 'taxes'
  // | 'stockQuantity'
  | 'externals'
  // | 'parameters'
  | 'quantityDefault'
  | 'quantityMin'
  | 'quantityMax'
> & {
  href: string;

  // custom manufacturer
  manufacturer?: {
    name: string;
  };
};

export const catalogueQuery = selector<MoleculerListResult<CataloguePageProduct>>({
  key: 'catalogue',
  get: ({ get }) => {
    const initial = get(catalogueInitialState);
    if (initial) return initial;

    const params = get(catalogueCallParamsState);

    return getProductCatalogue(
      get(apiClientState),
      get(localeState),
      get(currencyState),
      undefined, // decimalPrecision
      undefined, // enabledDynamicRules
      get(pricesTypeState),
      get(regionState),
      params.filter,
      params.order,
      params.pagination,
    );
  },

  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(catalogueQueryTrigger, (n) => n + 1);
    }
  },
});

const catalogueQueryTrigger = atom<number>({
  key: 'catalogueQueryTrigger',
  default: 0,
});

export const catalogueInitialState = GlobalStateRegister.register(
  atom<MoleculerListResult<CataloguePageProduct> | undefined>({
    key: 'catalogueInitialState',
    default: undefined,
  }),
);

export const createDefaultCatalogueCallParams = (
  opts: {
    tireSeasons?: TireSeasonType[];
    vehicleType?: VehicleType;
  } = {},
): CatalogueCallParams => {
  const { order, pagination } = createListInitialSettings({
    orderBy: ['name', 'cs'].join('.'),
  });

  const filter = createDefaultCatalogueFilter(opts);

  return {
    filter,
    order,
    pagination,
  };
};

export const createDefaultCatalogueFilter = (
  opts: {
    tireSeasons?: TireSeasonType[];
    vehicleType?: VehicleType;
  } = {},
): CatalogueFilter => {
  const { vehicleType, tireSeasons } = opts;
  return {
    vehicleType,
    tireSeasons,
  };
};

export const catalogueCallParamsState = GlobalStateRegister.register(
  atom<CatalogueCallParams>({
    key: 'catalogueCallParams',
    default: createDefaultCatalogueCallParams({ vehicleType: DEFAULT_VEHICLE_TYPE }),
    effects_UNSTABLE: [
      // // Reset filter if vehicle type changes
      // ({ onSet, setSelf }) => {
      //   onSet((newValue, oldValue) => {
      //     if (
      //       !(newValue instanceof DefaultValue) &&
      //       !(oldValue instanceof DefaultValue) &&
      //       newValue.filter.vehicleType !== oldValue.filter.vehicleType
      //     ) {
      //       setSelf(() => createDefaultCatalogueCallParams({ vehicleType: newValue.filter.vehicleType }));
      //     }
      //   });
      // },

      // Effect to set browser location if catalogue page is displayed and filter changes
      ({ onSet }) => {
        onSet((newValue) => {
          if (!(newValue instanceof DefaultValue)) {
            const basePath = routeUrl('catalogue', { locale: 'cs', otherParamsToQs: true, params: {} });
            if (history && history.location && history.location.pathname.startsWith(basePath)) {
              const url = routeUrl('catalogue', { locale: 'cs', otherParamsToQs: true, params: newValue as any });
              history.replace(url);
            }
          }
        });
      },
      atomEffectSyncWithSession('catalogueCallParams'),
    ],
  }),
);

export function getProductCatalogue(
  apiClient: ApiClient,
  locale: SupportedLocale,
  currency: string,
  decimalPrecision: undefined | number,
  enabledDynamicRules: undefined | string[],
  pricesType: undefined | 'B2B' | 'B2C' | 'B2BForeign',
  region: undefined | string,
  filter: CatalogueFilter,
  ordering: QueryOrderSettings,
  pagination: QueryPaginationSettings,
): Promise<MoleculerListResult<CataloguePageProduct>> {
  return apiClient
    .post<MoleculerListResult<Omit<CataloguePageProduct, 'href'>>, {}>('/v1/catalogue', {
      currency,
      decimalPrecision,
      enabledDynamicRules,
      pricesType,
      region,
      filter,
      ordering,
      pagination,
    })
    .then((r) => ({
      ...r,
      rows: transformProduct(locale, r.rows),
    }));
}

export const catalogueFilterState = selector<CatalogueFilter>({
  key: 'catalogueFilterState',
  get: ({ get }) => {
    const params = get(catalogueCallParamsState);

    return params.filter;
  },
  set: ({ set }, newValue) => {
    set(catalogueCallParamsState, (prev) => {
      const nextFilter: CatalogueFilter =
        newValue === undefined || newValue instanceof DefaultValue
          ? ((): CatalogueFilter => {
              // reset filter
              const { vehicleType } = prev.filter;
              const nf = createDefaultCatalogueFilter();
              return {
                ...nf,
                vehicleType: vehicleType || nf.vehicleType,
              };
            })()
          : newValue;

      return {
        ...prev,
        filter: nextFilter,
        pagination: {
          ...prev.pagination,
          page: 1,
        },
      };
    });
  },
});

export const catalogueOrderByState = selector<QueryOrderSettings>({
  key: 'catalogueOrderBy',
  get: ({ get }) => {
    const params = get(catalogueCallParamsState);
    return params.order;
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(catalogueCallParamsState, (prev) => ({
        ...prev,
        order: newValue,
        pagination: {
          ...prev.pagination,
          page: 1,
        },
      }));
    }
  },
});

export const cataloguePaginationState = selector<
  Partial<QueryPaginationSettings & { total: number; totalPages: number }>
>({
  key: 'cataloguePagination',
  get: ({ get }) => {
    const params = get(catalogueCallParamsState);
    const queryResult = get(catalogueQuery);

    return {
      page: params.pagination.page || queryResult.page,
      rowsPerPage: queryResult.pageSize || params.pagination.rowsPerPage,
      total: queryResult.total,
      totalPages: queryResult.totalPages,
    };
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(catalogueCallParamsState, (prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          ...newValue,
        },
      }));
    }
  },
});

export const catalogueFilterVehicleTypeState = selector<VehicleType | undefined>({
  key: 'catalogueFilterVehicleTypeState',
  get: ({ get }) => {
    const { filter } = get(catalogueCallParamsState);
    return filter.vehicleType;
  },
  set: ({ set }, newValue) => {
    set(catalogueFilterState, (prev) => ({
      ...prev,
      vehicleType: newValue instanceof DefaultValue ? DEFAULT_VEHICLE_TYPE : newValue,
    }));
  },
});

export const catalogueFilterTireSeasonTypesState = selector<TireSeasonType[] | undefined>({
  key: 'catalogueFilterTireSeasonTypesState',
  get: ({ get }) => {
    const { filter } = get(catalogueCallParamsState);
    return filter.tireSeasons;
  },
  set: ({ set }, newValue) => {
    set(catalogueFilterState, (prev) => ({
      ...prev,
      tireSeasons: newValue instanceof DefaultValue ? [DEFAULT_TIRE_SEASON] : newValue,
    }));
  },
});

export const routeParamToCatalogueFilter = (
  locale: SupportedLocale,
  vehicleType: VehicleType | undefined,
  tireSeasons: TireSeasonType[] | undefined,
  search: string | undefined,
): CatalogueCallParams => {
  const { pagination = {}, order = {}, ...restOfFilter } = search ? qs.parse(search, { ignoreQueryPrefix: true }) : {};

  const def = createDefaultCatalogueCallParams({
    vehicleType,
    tireSeasons,
  });

  const res = {
    filter: {
      ...def.filter,
      ...restOfFilter,
    },
    pagination: {
      ...def.pagination,
      ...(pagination
        ? {
            ...((pagination as any).page !== undefined ? { page: Number.parseInt((pagination as any).page, 10) } : {}),
            ...((pagination as any).rowsPerPage !== undefined
              ? { rowsPerPage: Number.parseInt((pagination as any).rowsPerPage, 10) }
              : {}),
          }
        : {}),
    },
    order: {
      ...def.order,
      ...(order as any),
    },
  };

  return res;

  // if (param === undefined) return undefined;

  // const found = Object.entries(vehicleTypeT).reduce<VehicleType | undefined>(
  //   (a, [vt, t]) => (t[locale] === param ? (vt as VehicleType) : a),
  //   undefined,
  // );

  // return found || false;
};

export interface CatalogueCallParams {
  filter: CatalogueFilter;
  order: QueryOrderSettings;
  pagination: QueryPaginationSettings;
}

export interface CatalogueFilter {
  axles?: string[];
  dimensions?: {
    diameter?: string;
    profile?: string;
    width?: string;
  };
  homologations?: string[];
  inAction?: ('action' | 'new' | 'sale')[];
  loadIndexes?: string[];
  manufacturers?: string[];
  onStock?: ('available' | 'distributor')[];
  options?: 'runflat'[];
  priceRange?: CataloguePriceRange;
  searchTerm?: string;
  speedIndexes?: string[];
  taxonomy?: string[];
  tireSeasons?: TireSeasonType[];
  usages?: string[];
  utilizations?: string[];
  vehicleType?: VehicleType;
}

export type CataloguePriceRange = [max: number, min: number];
