import { makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import ShoppingCartAppBarAction from '../../../components/ShoppingCartAppBarAction';
import AppLayoutPersonIconActions from './AppLayoutPersonIconActions';
import AppLayoutSearchBar from './AppLayoutSearchBar';

export type AppLayoutHeaderWideActionsProps = StandardDivProps<AppLayoutHeaderWideActionsClassKey>;

export type AppLayoutHeaderWideActionsClassKey = 'root' | 'alone';

const themeSettings = { name: 'AppLayoutHeaderWideActions' };
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      paddingLeft: theme.spacing(1),
      '& button:hover': {
        '& svg': { fill: theme.palette.common.white },
      },
    },
  }),
  themeSettings,
);

const AppLayoutHeaderWideActions: React.FC<AppLayoutHeaderWideActionsProps> = React.forwardRef(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <AppLayoutPersonIconActions />
        <AppLayoutSearchBar />
        <ShoppingCartAppBarAction />
      </div>
    );
  },
);

AppLayoutHeaderWideActions.displayName = 'AppLayoutHeaderWideActions';

export default withThemeProps(themeSettings)(AppLayoutHeaderWideActions);
