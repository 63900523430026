import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import Container, { ContainerProps } from '@material-ui/core/Container';
import AppLayoutHeaderWideLogo from './AppLayoutHeaderWideLogo';
import AppLayoutHeaderWideActions from './AppLayoutHeaderWideActions';
import AppLayoutHeaderWideVehicleTypeList from './AppLayoutHeaderWideVehicleTypeList';

export interface AppLayoutHeaderWideAppBarProps extends StandardDivProps<AppLayoutHeaderWideAppBarClassKey> {
  maxWidth: ContainerProps['maxWidth'];
}

export type AppLayoutHeaderWideAppBarClassKey = 'root' | 'centralArea' | 'vehicleList';

const themeSettings = { name: 'AppLayoutHeaderWideAppBar' };
const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      height: theme.spacing(8),
    },
    sideArea: {
      width: '25%',
      flexGrow: 1,
    },
    vehicleList: {},
  }),
  themeSettings,
);

const AppLayoutHeaderWideAppBar: React.FC<AppLayoutHeaderWideAppBarProps> = React.forwardRef(
  ({ className, classes: pClasses, maxWidth }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <Container ref={ref} className={clsx(classes.root, className)} maxWidth={maxWidth}>
        <AppLayoutHeaderWideLogo className={classes.sideArea} />
        <AppLayoutHeaderWideVehicleTypeList className={classes.vehicleList} />
        <AppLayoutHeaderWideActions className={classes.sideArea} />
      </Container>
    );
  },
);

AppLayoutHeaderWideAppBar.displayName = 'AppLayoutHeaderWideAppBar';

export default withThemeProps(themeSettings)(AppLayoutHeaderWideAppBar);
