import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import invariant from 'tiny-invariant';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { apiClientState } from '../../api/appState';
import { BranchListItem } from '../../api/articles/Branch';
import { chceckoutPersonalPickupState } from '../../api/checkout/checkoutShippingPersonalPickup';
import {
  KodProvozovny,
  TireChangeDateListItem,
  TireChangeDayTimeListItem,
  tiresChangeDateList,
  TiresChangeDateListRequest,
  tiresChangeDayTimeList,
  TiresChangeDayTimeListRequest,
  tiresChangeRegister,
  TiresChangeRegisterRequest,
  TiresChangeRegisterResult,
} from '../../api/tiresChange';
import { TIRE_CHANGE_DEFAULT_DAYS_OFFSET } from '../../api/tiresChange/defautDaysOffset';
import { tiresChangeDialogShowState, TiresChangeDialogShowStateOnDoneHandler } from '../../api/tiresChange/showDialog';
import ChooseWarehouse from '../ChooseWarehouse';
import ChooseWarehouseStoks from '../ChooseWarehouseStoks/ChooseWarehouseStoks';
import TireChangeWizzardStep1 from './TireChangeWizzardStep1';
import TireChangeWizzardStep2 from './TireChangeWizzardStep2';
import TireChangeWizzardStep3 from './TireChangeWizzardStep3';
import TireChangeWizzardStep4 from './TireChangeWizzardStep4';
import TireChangeWizzardStep5 from './TireChangeWizzardStep5';

const TireChangeWizzard: React.FC = () => {
  const [tiresChange, setTiresChange] = useRecoilState(tiresChangeDialogShowState);

  const handleDialogClose = React.useCallback(() => {
    if (tiresChange?.onDone) {
      tiresChange.onDone(null, { canceled: true });
    }
    setTiresChange(null);
  }, [tiresChange, setTiresChange]);

  return tiresChange !== null ? (
    <TireChangeWizzardDialog
      daysOffset={tiresChange.daysOffset || 1}
      onDialogClose={handleDialogClose}
      onDone={tiresChange.onDone}
      personInfo={tiresChange.personInfo}
      selectBranch={!!tiresChange.selectBranch}
      titlePrefix={tiresChange.titlePrefix}
    />
  ) : null;
};

TireChangeWizzard.displayName = 'TireChangeWizzard';

export default TireChangeWizzard;

interface TireChangeWizzardDialogProps {
  daysOffset: number;
  onDialogClose: () => void;
  onDone: TiresChangeDialogShowStateOnDoneHandler;
  personInfo: {
    emailAddress: string;
    personName: string;
    phoneNumber: string;
  };
  selectBranch: boolean;
  titlePrefix: string;
}

const TireChangeWizzardDialog: React.FC<TireChangeWizzardDialogProps> = ({
  daysOffset,
  onDialogClose: handleDialogClose,
  onDone,
  personInfo,
  selectBranch,
  titlePrefix,
}) => {
  const [forceBranchSelection, setForceBranchSelection] = React.useState(!!selectBranch);
  const [chceckoutPersonalPickup, setChceckoutPersonalPickup] = useRecoilState(chceckoutPersonalPickupState);
  const resetPersonalCheckout = useResetRecoilState(chceckoutPersonalPickupState);

  return (
    <Dialog open onClose={handleDialogClose}>
      {chceckoutPersonalPickup.branch && !forceBranchSelection ? (
        <TireChangeWizzardStepsHandler
          onDialogClose={handleDialogClose}
          onDone={onDone}
          branch={chceckoutPersonalPickup.branch}
          branchNumber={branchToNumber(chceckoutPersonalPickup.branch)}
          daysOffset={daysOffset}
          personInfo={personInfo}
          titlePrefix={titlePrefix}
        />
      ) : (
        <>
          <DialogTitle>{titlePrefix}</DialogTitle>
          <DialogContent>
            {selectBranch ? (
              <>
                <div>Zvolte si prosím Vámi preferovanou prodejnu.</div>
                <ChooseWarehouseStoks>
                  {(fwd) => (
                    <ChooseWarehouse
                      {...fwd}
                      onChange={(br) => {
                        if (br) {
                          setChceckoutPersonalPickup((prev) => ({
                            ...prev,
                            branch: br,
                          }));
                          setForceBranchSelection(false);
                        } else {
                          resetPersonalCheckout();
                          setForceBranchSelection(true);
                        }
                      }}
                      value={chceckoutPersonalPickup.branch}
                    />
                  )}
                </ChooseWarehouseStoks>
              </>
            ) : (
              <Alert severity='warning'>
                Není zvolena prodejna, vraťe se prosím do předchozího kroku a zvolte prodejnu.
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button color='secondary' variant='outlined' onClick={() => handleDialogClose()}>
              Zavřít
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

TireChangeWizzardDialog.displayName = 'TireChangeWizzardDialog';

interface TireChangeWizzardStepsHandlerProps {
  branch: BranchListItem;
  branchNumber: TiresChangeDateListRequest['kodProvozovny'];
  daysOffset: number;
  onDialogClose: () => void;
  onDone: TiresChangeDialogShowStateOnDoneHandler;
  personInfo: {
    emailAddress: string;
    personName: string;
    phoneNumber: string;
  };
  titlePrefix: string;
}

const TireChangeWizzardStepsHandler: React.FC<TireChangeWizzardStepsHandlerProps> = ({
  branch,
  branchNumber,
  daysOffset,
  onDialogClose: handleDialogClose,
  onDone,
  personInfo,
  titlePrefix,
}) => {
  const apiClient = useRecoilValue(apiClientState);
  const nextStepDataRef = React.useRef<any>(undefined);
  const theme = useTheme();
  const narrow = useMediaQuery(theme.breakpoints.down('xs'));
  const [dialogStep, setDialogStep] = React.useState<'step1' | 'step2' | 'step3' | 'step4' | 'step5'>('step1');
  const [stepData, setStepData] = React.useState<InnerState>(createInitialInnerState(branchNumber));

  const handleNext = React.useCallback(
    <FN extends keyof InnerState>(step: FN) => (val: InnerState[FN]) => {
      setStepData((prev) => ({
        ...prev,
        [step]: val,
      }));

      const nextStepSetup: Record<keyof InnerState, keyof InnerState> = {
        step1: 'step2',
        step2: 'step3',
        step3: 'step4',
        step4: 'step5',
        step5: 'step5',
      };

      const nextStep = nextStepSetup[step];

      valuesForStep(apiClient, val, nextStep).then((vc) => {
        nextStepDataRef.current = vc;

        if (nextStep === 'step5' && vc.status === 'success') {
          onDone(null, { reservationCode: vc.reservationCode });
        } else {
          setDialogStep(nextStep);
        }
      });
    },
    [apiClient, onDone],
  );

  const handlePrev = React.useCallback(
    <FN extends keyof InnerState>(step: FN) => () => {
      const prevStepSetup: Record<keyof InnerState, keyof InnerState> = {
        step5: 'step4',
        step4: 'step3',
        step3: 'step2',
        step2: 'step1',
        step1: 'step1',
      };
      const prevStep = prevStepSetup[step];

      if (prevStep !== 'step1') {
        valuesForStep(apiClient, stepData[prevStepSetup[prevStep]], prevStep).then((vc) => {
          nextStepDataRef.current = vc;
          setDialogStep(prevStep);
        });
      } else {
        setDialogStep(prevStep);
      }
    },
    [apiClient, stepData],
  );

  return (
    <Dialog open onClose={handleDialogClose} maxWidth='md' fullWidth fullScreen={narrow}>
      {dialogStep === 'step1' && (
        <TireChangeWizzardStep1
          onClose={handleDialogClose}
          onNext={handleNext('step1')}
          branch={branch}
          initialValues={stepData.step1}
          titlePrefix={titlePrefix}
        />
      )}
      {dialogStep === 'step2' && (
        <TireChangeWizzardStep2
          onClose={handleDialogClose}
          onNext={handleNext('step2')}
          onPrev={handlePrev('step2')}
          branch={branch}
          daysOffset={
            stepData.step1.dokladOUskladneni &&
            typeof stepData.step1.dokladOUskladneni === 'string' &&
            stepData.step1.dokladOUskladneni.length > 0
              ? TIRE_CHANGE_DEFAULT_DAYS_OFFSET
              : daysOffset
          }
          dateList={nextStepDataRef.current as TireChangeDateListItem[]}
          titlePrefix={titlePrefix}
        />
      )}
      {dialogStep === 'step3' && (
        <TireChangeWizzardStep3
          onClose={handleDialogClose}
          onNext={handleNext('step3')}
          onPrev={handlePrev('step3')}
          branch={branch}
          selectedDay={stepData['step2'] as TireChangeDateListItem}
          timeList={nextStepDataRef.current as TireChangeDayTimeListItem[]}
          titlePrefix={titlePrefix}
        />
      )}
      {dialogStep === 'step4' && (
        <TireChangeWizzardStep4
          onClose={handleDialogClose}
          onNext={handleNext('step4')}
          onPrev={handlePrev('step4')}
          branch={branch}
          branchNumber={branchNumber}
          personInfo={personInfo}
          requestInfo={stepData['step1'] as any}
          selectedDay={stepData['step2'] as TireChangeDateListItem}
          selectedTime={stepData['step3'] as TireChangeDayTimeListItem}
          titlePrefix={titlePrefix}
        />
      )}
      {dialogStep === 'step5' && (
        <TireChangeWizzardStep5
          onClose={handleDialogClose}
          onNewDate={handlePrev('step3')}
          onRetrySave={handlePrev('step5')}
          saveResult={nextStepDataRef.current as TiresChangeRegisterResult}
          titlePrefix={titlePrefix}
        />
      )}
    </Dialog>
  );
};

interface InnerState {
  step1: TiresChangeDateListRequest;
  step2: TireChangeDateListItem | undefined;
  step3: TireChangeDayTimeListItem | undefined;
  step4: TiresChangeRegisterRequest | undefined;
  step5: true | undefined;
}

function createInitialInnerState(branchNumber: TiresChangeDateListRequest['kodProvozovny']): InnerState {
  return {
    step1: {
      dokladOUskladneni: undefined,
      kodProvozovny: branchNumber,
      spz: '',
      typAuta: undefined as any,
      typDisku: undefined as any,
      typPraceSDisky: 2,
      velikostDisku: undefined as any,
    },
    step2: undefined,
    step3: undefined,
    step4: undefined,
    step5: undefined,
  };
}

const branchNumberMap: Record<string, KodProvozovny> = {
  '01': 1, // Havířská 366/33, 400 10 Ústí nad Labem
  '02': 2, // Žižkova 864/73, 400 01, Ústí nad Labem
  '03': 10, // Bynovská 340, 405 05, Děčín
  '04': 8, // K. H. Máchy 1697, 434 00, Most
  '05': 9, // Hálkova 4715, 430 00, Chomutov
  '06': 4, // Areál H-PARK, Heršpická 11c
  '07': 7, // Dubská 554, 415 01, Teplice
  '11': 6, // Plazy 127, 293 01 Mladá Boleslav
};

function branchToNumber(branch: BranchListItem): KodProvozovny {
  const kodProvozovny = branchNumberMap[branch.costCenterCode];
  invariant(kodProvozovny);
  return kodProvozovny;
}

async function valuesForStep(
  apiClient: ApiClient,
  params: any,
  step: 'step1' | 'step2' | 'step3' | 'step4' | 'step5',
): Promise<any> {
  if (step === 'step2' && params !== undefined) {
    return tiresChangeDateList(apiClient, params as TiresChangeDateListRequest).then((res) => {
      if (res.status === 'success') {
        return res.dateList;
      }
      return undefined;
    });
  } else if (step === 'step3' && params !== undefined) {
    return tiresChangeDayTimeList(apiClient, params as TiresChangeDayTimeListRequest).then((res) => {
      if (res.status === 'success') {
        return res.timeList;
      }
      return undefined;
    });
  } else if (step === 'step5' && params !== undefined) {
    return tiresChangeRegister(apiClient, params);
  }

  return undefined;
}
