import React from 'react';
import { BranchListItem } from '../../api/articles/Branch';
import useFilteredBranches, { FilterAvailabilityByItem } from '../../hooks/useFilteredBranches';

export type ChooseWarehouseStoksLoadable =
  | { contents: BranchListItem[]; state: 'hasValue' }
  | { contents: Promise<BranchListItem[]>; state: 'loading' }
  | { contents: Error; state: 'hasError' };

export type ChooseWarehouseStoksFilterAvailabilityByStatus = 'none' | 'single' | 'multi';

export interface ChooseWarehouseStoksForwardProps {
  branchesLoadable: ChooseWarehouseStoksLoadable;
  filterAvailabilityByStatus: ChooseWarehouseStoksFilterAvailabilityByStatus;
}

export interface ChooseWarehouseStoksProps {
  children: (props: ChooseWarehouseStoksForwardProps) => JSX.Element | null;
  filterAvailabilityBy?: FilterAvailabilityByItem[];
  onNoBranches?: () => void;
}

const ChooseWarehouseStoks: React.FC<ChooseWarehouseStoksProps> = ({
  children,
  filterAvailabilityBy,
  onNoBranches,
}) => {
  const branchesLoadable = useFilteredBranches(filterAvailabilityBy);
  const [status, setStatus] = React.useState<'init' | 'no-branches' | 'branches'>('init');

  const handleNoBranches = React.useCallback(() => {
    if (onNoBranches) {
      onNoBranches();
    }
  }, [onNoBranches]);

  React.useEffect(
    () => {
      if (branchesLoadable.state === 'hasValue') {
        if (
          ['init', 'branches'].includes(status) &&
          (!branchesLoadable.contents || !Array.isArray(branchesLoadable.contents) || !branchesLoadable.contents.length)
        ) {
          setStatus('no-branches');
          handleNoBranches();
        } else if (status === 'no-branches' && branchesLoadable.contents && branchesLoadable.contents.length) {
          setStatus('branches');
        }
      }

      if (branchesLoadable.state === 'hasError') {
        setStatus('no-branches');
        handleNoBranches();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(branchesLoadable.contents), branchesLoadable.state, handleNoBranches, onNoBranches, status],
  );

  const filterAvailabilityByStatus = React.useMemo<ChooseWarehouseStoksFilterAvailabilityByStatus>(() => {
    if (!filterAvailabilityBy || filterAvailabilityBy.length === 0) return 'none';
    if (filterAvailabilityBy.length === 1) return 'single';
    return 'multi';
  }, [filterAvailabilityBy]);

  return children({ branchesLoadable, filterAvailabilityByStatus });
};

export default ChooseWarehouseStoks;
