import { asyncComponent, AsyncRouteProps } from '@jaredpalmer/after';
import { QueryOrderSettings, QueryPaginationSettings } from '@sprinx/query-builder';
import { TranslationDictionary } from '@sprinx/react-globalize/types';
import kebabCase from 'lodash/kebabCase';
import pathToRegexp from 'path-to-regexp';
import qs from 'qs';
import { match as Match } from 'react-router-dom';
import { CatalogueFilter } from './api/catalogue/catalogue';
import { tireSeasonsToRoute } from './api/tireSeasonTypes';
import { vehicleTypeToRoute } from './api/vehicleTypes';
import Placeholder from './AppComponentLoader';
import { SupportedLocale } from './supported';

const routesDefinition = {
  home: {
    path: '/',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/HomePage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  catalogue: {
    path: {
      cs: '/pneumatiky/:vehicleType?/:tireSeasons?',
    },
    component: asyncComponent({
      loader: () => import('./pages/CataloguePage'),
      Placeholder,
    }),
    urlBuilder: (
      locale: SupportedLocale,
      path: string,
      otherParamsToQs: boolean | string[] | undefined | null,
      params: {
        filter?: Partial<CatalogueFilter>;
        order?: Partial<QueryOrderSettings>;
        pagination?: Partial<QueryPaginationSettings>;
      },
    ): string => {
      const {
        filter: { vehicleType: rVehicleType, tireSeasons: rTireSeasons, ...restFilter } = {},
        order,
        pagination,
      } = params;

      const vehicleType = vehicleTypeToRoute(locale, rVehicleType);
      const tireSeasons = vehicleType ? tireSeasonsToRoute(locale, rTireSeasons) : undefined;

      return routesBuilderDefaultdUrlBuilder(locale, path, otherParamsToQs, {
        ...restFilter,
        vehicleType,
        tireSeasons,
        ...(order ? { order } : {}),
        ...(pagination ? { pagination } : {}),
      });
    },
  },
  productDetail: {
    path: '/:sn([^/]+)-product-:id([^/]+)',
    component: asyncComponent({
      loader: () => import('./pages/ProductDetailPage'),
      Placeholder,
    }),
    urlBuilder: (
      locale: SupportedLocale,
      path: string,
      otherParamsToQs: boolean | string[] | undefined | null,
      params: { id: string; name: string | TranslationDictionary },
    ): string => {
      const toUrl = pathToRegexp.compile(path);
      return safeAbsoluteUrl(
        path,
        toUrl({
          id: params.id,
          sn: kebabCase(
            (typeof params.name === 'string' ? params.name : params.name.find((i) => i.language === locale)?.text) ||
              `${params.id}`,
          ),
        }),
      );
    },
  },

  cartSummary: {
    path: {
      cs: '/kosik/prehled',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CartSummaryPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  seasonHintDetail: {
    path: {
      cs: '/tipy-na-sezonu/:id',
    },
    component: asyncComponent({
      loader: () => import('./pages/SeasonHintDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  newsList: {
    path: {
      cs: '/novinky',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/NewsListPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  newsDetail: {
    path: {
      cs: '/novinky/:id',
    },
    component: asyncComponent({
      loader: () => import('./pages/ArticleDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  homePageClanky: {
    path: {
      cs: '/clanek/:id',
    },
    component: asyncComponent({
      loader: () => import('./pages/ArticleDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  tiresTestsList: {
    path: {
      cs: '/testy-pneumatik',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/TiresTestsArticlePage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  tiresTestsDetail: {
    path: {
      cs: '/testy-pneumatik/:id',
    },
    component: asyncComponent({
      loader: () => import('./pages/ArticleDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  technicalAdvisor: {
    path: {
      cs: '/technicky-radce',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/TechnicalAdvisorPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  technicalAdvisorDetail: {
    path: {
      cs: '/technicky-radce/:id',
    },
    component: asyncComponent({
      loader: () => import('./pages/ArticleDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  termsAndConditions: {
    path: {
      cs: '/obchodni-podminky',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/TermsAndConditionsPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  termsAndConditionsDetail: {
    path: {
      cs: '/obchodni-podminky/:id',
    },
    component: asyncComponent({
      loader: () => import('./pages/ArticleDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  posts: {
    path: {
      cs: '/prispevky',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/PostsPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  postsDetail: {
    path: {
      cs: '/prispevky/:id',
    },
    component: asyncComponent({
      loader: () => import('./pages/ArticleDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  actions: {
    path: {
      cs: '/akcni-nabidky',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/ActionsPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  actionsDetail: {
    path: {
      cs: '/akcni-nabidky/:id',
    },
    component: asyncComponent({
      loader: () => import('./pages/ArticleDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  searchResults: {
    path: {
      cs: '/vysledky-vyhledavani',
    },
    component: asyncComponent({
      loader: () => import('./pages/SearchResults'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  branchList: {
    path: {
      cs: '/pneuservisy',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/BranchListPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  branchDetail: {
    path: {
      cs: '/pneuservisy/:id',
    },
    component: asyncComponent({
      loader: () => import('./pages/BranchDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  ourServices: {
    path: {
      cs: '/servisni-sluzby',
    },
    component: asyncComponent({
      loader: () => import('./pages/ArticleDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  tireSelection: {
    path: {
      cs: '/vyber-pneumatiky',
    },
    component: asyncComponent({
      loader: () => import('./pages/ArticleDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  contact: {
    path: {
      cs: '/kontakt',
    },
    component: asyncComponent({
      loader: () => import('./pages/ContactPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  siteMap: {
    path: {
      cs: '/mapa-stranek',
    },
    component: asyncComponent({
      loader: () => import('./pages/ArticleDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  tiresDictionary: {
    path: {
      cs: '/slovnik-o-pneumatikach',
    },
    component: asyncComponent({
      loader: () => import('./pages/ArticleDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  thankyou: {
    path: {
      cs: '/dekujeme-za-objednavku',
    },
    component: asyncComponent({
      loader: () => import('./pages/ThankyouPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },

  // {
  //   path: '/prihlaseni',
  //   component: asyncComponent({
  //     loader: () => import('./pages/LoginPage'),
  //     Placeholder,
  //   }),
  // },
  // {
  //   path: '/odhlaseni',
  //   component: asyncComponent({
  //     loader: () => import('./pages/LogoutPage'),
  //     Placeholder,
  //   }),
  // },

  profilePurchases: {
    path: {
      cs: '/profil/moje-nakupy',
    },
    component: asyncComponent({
      loader: () => import('./pages/ProfilePurchasesPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  profileReservations: {
    path: {
      cs: '/profil/moje-rezervace',
    },
    component: asyncComponent({
      loader: () => import('./pages/ProfileReservationsPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  profileSettings: {
    path: {
      cs: '/profil/nastaveni',
    },
    component: asyncComponent({
      loader: () => import('./pages/ProfileSettingsPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
};

/**
 * Returnd builded URL from `type` and params...
 *
 * ```
 * routeUrl('home', {locale, params: { ... } }); // => '/'
 * routeUrl('productDetail', {locale, params: { ... } }); // => '/some-product-name-product-12344'
 * ```
 */
export function routeUrl<T extends keyof typeof routesDefinition, P extends typeof routesDefinition[T]['urlBuilder']>(
  type: T,
  opts: {
    locale: SupportedLocale;
    otherParamsToQs?: boolean | string[];
    params: P extends (...args: any[]) => any ? Parameters<P>[3] : Record<string, any> | undefined;
  },
): string {
  const path = routePath(type, opts);
  const def = routesDefinition[type];
  const inputParams = opts.params || {}; // ensure params exists

  return def.urlBuilder && typeof def.urlBuilder === 'function'
    ? def.urlBuilder(opts.locale, path, opts.otherParamsToQs, inputParams as any)
    : routesBuilderDefaultdUrlBuilder(opts.locale, path, opts.otherParamsToQs, inputParams as any);
}

export function routeIsActive(
  type: keyof typeof routesDefinition,
  opts: {
    customMatcher?: (opts: {
      nodePath: string;
      pathRegexMatches: RegExpExecArray | null;
      routeRegexMatch: boolean;
    }) => boolean;
    locale: SupportedLocale;
    match: Match<any>;
  },
): boolean {
  const nodePath = routePath(type, opts);

  const re = pathToRegexp(opts.match.path, undefined, { strict: opts.match.isExact });
  const pathRegexMatches = re.exec(nodePath);
  const routeRegexMatch = pathRegexMatches !== null;

  return opts.customMatcher ? opts.customMatcher({ nodePath, routeRegexMatch, pathRegexMatches }) : routeRegexMatch;
}

/**
 * Returns a `path` from roteDefinitions by `type` and `opts.locale`.
 *
 * ```
 * routePath('home', { locale }); // => '/'
 * routePath('productDetail', { locale }); // => '/:sn([^/]+)-product-:id([^/]+)'
 * ```
 */
export function routePath(type: keyof typeof routesDefinition, opts: { locale: string }): string {
  const def = routesDefinition[type];
  return typeof def.path === 'string' ? def.path : def.path[opts.locale];
}

/**
 * Standard rou
 * @param locale
 * @param path
 * @param otherParamsToQs
 * @param inputParams
 */
export function routesBuilderDefaultdUrlBuilder(
  locale: SupportedLocale,
  path: string,
  otherParamsToQs: boolean | string[] | undefined | null,
  inputParams: Record<string, any> | undefined,
): string {
  const { pathParams, queryParams, otherParamsExists } = routesBuilderSplitInputParams(
    otherParamsToQs,
    path,
    inputParams || {},
  );

  const toUrl = pathToRegexp.compile(path);
  const urlB = toUrl(pathParams);

  return !otherParamsToQs || !otherParamsExists
    ? safeAbsoluteUrl(path, urlB)
    : safeAbsoluteUrl(
        path,
        `${urlB}${qs.stringify(queryParams, {
          addQueryPrefix: true,
        })}`,
      );
}

/**
 * Returns `inputParameters` object splitted to two separate
 * @param otherParamsToQs
 * @param path
 * @param inputParams
 */
export function routesBuilderSplitInputParams<P extends Record<string, any>>(
  otherParamsToQs: boolean | string[] | undefined | null,
  path: string,
  inputParams: P,
): { otherParamsExists: boolean; pathParams: Record<string, any>; queryParams?: Record<string, any> } {
  const [pathParams, queryParams] = !otherParamsToQs
    ? [inputParams]
    : ((reg) => {
        const pathParamKeys = reg.keys.map((i) => i.name);
        return Object.entries(inputParams).reduce<[Record<string, any>, Record<string, any>]>(
          (acc, [k, v]) => {
            if (pathParamKeys.includes(k)) {
              return [{ ...acc[0], [k]: v }, acc[1]];
            }

            if (Array.isArray(otherParamsToQs) && !otherParamsToQs.includes(k)) {
              return acc;
            }

            return [acc[0], { ...acc[1], [k]: v }];
          },
          [{}, {}],
        );
      })(pathToRegexp(path));

  const otherParamsExists = !!queryParams && Object.keys(queryParams).length > 0;

  return {
    pathParams,
    queryParams,
    otherParamsExists,
  };
}

/**
 * Returns all router routes definitions...
 */
export function routeAllRoutesToRouter(): AsyncRouteProps[] {
  const extr = ({ urlBuilder, path, ...rest }: RouteDefinition): Omit<AsyncRouteProps, 'path'> => rest;

  return Object.values(routesDefinition).reduce<AsyncRouteProps[]>((acc, itm) => {
    if (typeof itm.path === 'string') return [...acc, { path: itm.path, ...extr(itm as any) }];
    return [...acc, ...Object.values(itm.path).map((path) => ({ path, ...extr(itm as any) }))];
  }, []);
}

const safeAbsoluteUrl = (path: string, url: string): string =>
  `${path.startsWith('/') ? '/' : ''}${url.replace(/^\//, '')}`;

export interface RouteDefinition extends Omit<AsyncRouteProps, 'path'> {
  path: string | Record<SupportedLocale, string>;
  urlBuilder: (
    locale: SupportedLocale,
    path: string,
    otherParamsToQs: boolean | string[] | undefined | null,
    params: any,
  ) => string;
}
