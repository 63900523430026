import { atom } from 'recoil';

export const tiresChangeDialogShowState = atom<null | {
  daysOffset?: number;
  onDone: TiresChangeDialogShowStateOnDoneHandler;
  personInfo: {
    emailAddress: string;
    personName: string;
    phoneNumber: string;
  };
  selectBranch?: boolean;
  titlePrefix: string;
}>({
  key: 'tiresChangeDialogShow',
  default: null,
});

export interface TiresChangeDialogShowStateOnDoneHandler {
  (err: Error): void;
  (err: null | undefined, res: { reservationCode: string } | { canceled: true }): void;
}
