import { atom } from 'recoil';
import { GlobalStateRegister } from '../@sprinx/react-after-razzle/stateStore';
import { SupportedLocale } from '../supported';

export const DEFAULT_TIRE_SEASON: TireSeasonType = 'summer';

export type TireSeasonType = 'summer' | 'winter' | 'all-season';

// export const productTaxonomySelector = selector<string | undefined>({
//   key: 'globalContextProductTaxonomy',
//   get: ({ get }) => {
//     const s = get(tireSeasonState);
//     const vt = get(vehicleTypeState);

//     // TODO: nove taxonomie... /season/winter (summer) a typ vozidla /catalogue/vehicle-type/passenger

//     return !vt ? undefined : `/catalogue/${s}/${vt}`;
//   },
// });

/**
 * Default `TireSeason` loaded from back-end (API).
 * Is setted-up in CMS.
 */
export const tireSeasonDefaultState = GlobalStateRegister.register(
  atom<TireSeasonType>({
    key: 'tireSeasonDefault',
    default: DEFAULT_TIRE_SEASON,
  }),
);

const tireSeasonT: Record<TireSeasonType, Record<SupportedLocale, string>> = {
  summer: {
    cs: 'letni',
  },
  winter: {
    cs: 'zimni',
  },
  'all-season': {
    cs: 'celorocni',
  },
};

export const tireSeasonsToRoute = (
  locale: SupportedLocale,
  tireSeasons: TireSeasonType[] | undefined,
): string | undefined => {
  if (tireSeasons === undefined || (Array.isArray(tireSeasons) && tireSeasons.length === 0)) return undefined;
  if (Array.isArray(tireSeasons) && tireSeasons.length === 1) return tireSeasonT[tireSeasons[0]][locale];

  return '-';
};

export const routeParamToTireSeasonTypes = (
  locale: SupportedLocale,
  param: string | undefined,
): TireSeasonType[] | false | undefined => {
  if (param === undefined) return undefined;
  if (param === '-') return ['summer', 'winter', 'all-season'];

  const found = Object.entries(tireSeasonT).reduce<TireSeasonType | undefined>(
    (a, [vt, t]) => (t[locale] === param ? (vt as TireSeasonType) : a),
    undefined,
  );

  return found ? [found] : false;
};
