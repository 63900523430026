import React from 'react';
import { useSetRecoilState } from 'recoil';
import { authState, useApiClient } from '../appState';

export default function useLogoutHandler(onSuccess?: () => void, onError?: (err: Error) => void): () => Promise<any> {
  const apiClient = useApiClient();
  const updateAuth = useSetRecoilState(authState);

  return React.useCallback((): Promise<any> => {
    return apiClient.post('/v1/auth/logout', {}).then(
      () => {
        updateAuth(undefined);

        if (onSuccess) onSuccess();
      },
      (err) => {
        if (onError) onError(err);
      },
    );
  }, [apiClient, onError, onSuccess, updateAuth]);
}
