import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Alert, Skeleton } from '@material-ui/lab';
import DotJoined from '@sprinx/react-mui-components/DotJoined';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { ProductParameterTypes } from '../../@sprinx/knihovka-types';
import { localeState } from '../../api/appState';
import { useProductParametersConvertToDisplay } from '../../api/products/productParameters';
import { productParameterTypesQuery } from '../../api/products/productParameterTypes';
import { ProductCardProduct } from '../@types/ProductCardProduct';

export interface ProductCardInlineParamsProps extends StandardDivProps<ProductCardInlineParamsClassKey> {
  product: ProductCardProduct;
}

export type ProductCardInlineParamsClassKey = 'root';

const themeSettings = { name: 'ProductCardInlineParams' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<ProductCardInlineParamsClassKey, {}>({
      root: {
        color: theme.palette.text.secondary,
        margin: theme.spacing(1, 0),
        fontSize: theme.typography.pxToRem(12),
        display: 'flex',
        flexDirection: 'column',
        minHeight: theme.spacing(5),
      },
    }),
  themeSettings,
);

const ProductCardInlineParams: React.FC<ProductCardInlineParamsProps> = ({ className, classes: pClasses, product }) => {
  const classes = useStyles({ classes: pClasses });
  const productParameterTypes = useRecoilValueLoadable(productParameterTypesQuery);

  if (productParameterTypes.state === 'hasError') {
    if (process.env.NODE_ENV !== 'production') {
      console.error(
        'Při stahování parametrů produktu v komponente ProductCardInlineParams nastala chyba.',
        productParameterTypes.contents,
      );
    }

    return <Alert severity='error'>Došlo k chybě při načítání parametrů.</Alert>;
  }

  if (productParameterTypes.state === 'loading') {
    return <Skeleton width={200} height={56} />;
  }

  if (!productParameterTypes.contents) {
    return <Alert severity='warning'>Parametry nenalezeny</Alert>;
  }

  return (
    <ProductCardInlineParametersContent
      className={clsx(classes.root, className)}
      productParameterTypes={productParameterTypes.contents}
      product={product}
    />
  );
};

ProductCardInlineParams.displayName = 'ProductCardInlineParams';

export default withThemeProps(themeSettings)(ProductCardInlineParams);

const ProductCardInlineParametersContent: React.FC<{
  className?: string;
  product: ProductCardProduct;
  productParameterTypes: ProductParameterTypes;
}> = ({ productParameterTypes, product, className }) => {
  const parameters = useProductParametersConvertToDisplay(
    useRecoilValue(localeState),
    productParameterTypes,
    product.parameters,
  );
  const [width, profile, diameter, season, vehicleType, loadIndex, speed] = React.useMemo(() => {
    const toDisplayCodes = ['/width', '/profile', '/diameter', '/season', '/vehicleType', '/loadIndex', '/speed'];
    return toDisplayCodes.map((dc) => (parameters || []).find((p) => p.code === dc)).filter(Boolean);
  }, [parameters]);
  return (
    <div className={className}>
      <span>
        {width?.value}/{profile?.value} R{diameter?.value} {loadIndex?.value} {speed?.value}
      </span>
      <span>
        <DotJoined>
          {vehicleType?.value}
          {season?.value}
        </DotJoined>
      </span>
    </div>
  );
};
