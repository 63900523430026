import { OptionsObject, useSnackbar } from 'notistack';
import React from 'react';
import { atom, DefaultValue, selector } from 'recoil';

export const useShowSnackbarMessage = (): ((message: string, options?: OptionsObject) => React.ReactText) => {
  const { enqueueSnackbar } = useSnackbar();
  return enqueueSnackbar;
};

export const useCloseMessage = (): ((key?: string | number | undefined) => void) => {
  const { closeSnackbar } = useSnackbar();
  return closeSnackbar;
};

export const showMessageState = atom<({ message: string } & OptionsObject)[]>({
  key: 'showMessageState',
  default: [],
});

export const enqueueSnackbarMessage = selector<undefined | ({ message: string } & OptionsObject)>({
  key: 'enqueueSnackbarMessage',
  get: () => {
    return undefined;
  },
  set: ({ set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      set(showMessageState, (q) => (newValue === undefined ? q : [...q, newValue]));
    }
  },
});
