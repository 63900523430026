import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import HamburgerIcon from '@material-ui/icons/Menu';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { mainNavigationNarrowNavigationToggleState } from '../../../api/mainNavigation';
import ShoppingCartAppBarAction from '../../../components/ShoppingCartAppBarAction';

export type AppLayoutHeaderNarrowHamburgerNavigationProps = StandardDivProps<AppLayoutHeaderNarrowHamburgerNavigationClassKey>;

export type AppLayoutHeaderNarrowHamburgerNavigationClassKey = 'root' | 'logo' | 'icon' | 'basket';

const themeSettings = { name: 'AppLayoutHeaderNarrowHamburgerNavigation' };
const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: theme.palette.common.white,
      background: theme.palette.primary.main,
    },
    icon: { fill: theme.palette.common.white },
    logo: {
      ...theme.typography.body1,
      fontSize: theme.typography.pxToRem(22),
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightRegular,
      textDecoration: 'none',
    },
    basket: {
      margin: theme.spacing(0.5),
    },
  }),
  themeSettings,
);

const AppLayoutHeaderNarrowHamburgerNavigation: React.FC<AppLayoutHeaderNarrowHamburgerNavigationProps> = React.forwardRef(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });

    const onToggleOpen = useSetRecoilState(mainNavigationNarrowNavigationToggleState);
    const open = useRecoilValue(mainNavigationNarrowNavigationToggleState);

    const HamburgerIconComponent = open ? CloseIcon : HamburgerIcon;

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <IconButton onClick={() => onToggleOpen(undefined)}>
          <HamburgerIconComponent className={classes.icon} />
        </IconButton>
        <Link component={RouterLink} to='/' className={classes.logo}>
          Prodej-pneu.cz
        </Link>
        <ShoppingCartAppBarAction className={classes.basket} />
      </div>
    );
  },
);

AppLayoutHeaderNarrowHamburgerNavigation.displayName = 'AppLayoutHeaderNarrowHamburgerNavigation';

export default withThemeProps(themeSettings)(AppLayoutHeaderNarrowHamburgerNavigation);
