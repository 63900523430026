import { TranslationDictionary } from '@sprinx/react-globalize/types';
import isObjectLike from 'lodash/isObjectLike';
import { useMemo } from 'react';
import {
  ProductDisplayParametersItem,
  ProductParametersParameterValue,
  ProductParameterTypeEnum,
  ProductParameterTypes,
  ProductParameterValueRecord,
  ProductPopulates,
} from '../../@sprinx/knihovka-types';
import { VehicleType } from '../vehicleTypes';

export function productParametersConvertToDisplay(
  locale: string,
  productParameterTypes: undefined | ProductParameterTypes,
  parameters: undefined | ProductParameterValueRecord[],
): undefined | ProductDisplayParametersItem[] {
  if (!parameters || !productParameterTypes) return undefined;

  const displayParameters = parameters
    .map((productParam: ProductParameterValueRecord) =>
      productParametersConvertSingleParameterToDisplay(locale, productParameterTypes, productParam),
    )
    .filter(Boolean) as ProductDisplayParametersItem[];

  return displayParameters;
}

export function productParametersConvertSingleParameterToDisplay(
  locale: string,
  productParameterTypes: undefined | ProductParameterTypes,
  productParam: undefined | ProductParameterValueRecord,
): undefined | ProductDisplayParametersItem {
  if (!productParam || !productParameterTypes) return undefined;

  const t = (
    toT: string | TranslationDictionary,
    options?: { acceptString?: boolean; defaultValue?: string },
  ): string => {
    const opts = { acceptString: false, defaultValue: '', ...options };

    if (typeof toT === 'string' && opts.acceptString) {
      return toT;
    }
    if (Array.isArray(toT)) {
      return (toT.find((x) => x.language === locale) || {}).text || opts.defaultValue;
    }

    return opts.defaultValue;
  };

  function resolveParamDisplayValue(
    productTypeEnum: ProductParameterTypeEnum[] | undefined,
    parameterValue: ProductParametersParameterValue | undefined,
  ): string | number | boolean | undefined {
    if (parameterValue === undefined) return undefined;

    // Parameter value is TranslationDictionary
    if (Array.isArray(parameterValue)) return t(parameterValue, { acceptString: true });

    // dont lookup for value in enum
    if (!productTypeEnum) {
      return isObjectLike(parameterValue)
        ? t((parameterValue as { label: TranslationDictionary }).label, { acceptString: true })
        : (parameterValue as string | boolean | number);
    }

    const enumValue = productTypeEnum.find((it: ProductParameterTypeEnum) =>
      Array.isArray(it) ? it[0] === parameterValue : it === parameterValue,
    );

    if (typeof enumValue === 'number') return enumValue;
    if (typeof enumValue === 'string') return enumValue;
    if (Array.isArray(enumValue)) return t((enumValue as [any, TranslationDictionary])[1], { acceptString: true });

    return undefined;
  }

  const type = productParameterTypes.find((pt) => pt.code === productParam.code);

  if (type === undefined) return undefined;

  // Lookup for display value
  const parameterDisplayValue = resolveParamDisplayValue(type.enum, productParam.value);

  return {
    ...productParam,
    value: parameterDisplayValue,
    label: t(type.label, { acceptString: true }),
  };
}

export function useProductParametersConvertToDisplay(
  locale: string,
  productParameterTypes: undefined | ProductParameterTypes,
  parameters: undefined | ProductPopulates['parameters']['parameters'],
): ProductPopulates['parametersDisplay']['parameters'] {
  return useMemo(() => productParametersConvertToDisplay(locale, productParameterTypes, parameters), [
    locale,
    parameters,
    productParameterTypes,
  ]);
}

export const useProductPatametersConvertVehicleTypeParamToVehicleType = (
  vahicleTypeParameterValue: string | undefined,
): VehicleType => {
  return useMemo<VehicleType>(() => {
    const map: Record<string, VehicleType> = {
      LNP: 'van',
      Nákladní: 'truck',
      Osobní: 'passenger',
      Motocykly: 'moto',
      '4×4/SUV': 'passenger',
    };

    return vahicleTypeParameterValue ? map[vahicleTypeParameterValue] || 'passenger' : 'passenger';
  }, [vahicleTypeParameterValue]);
};

export const useParameterTypeValueEnum = (
  productParameterTypes: ProductParameterTypes,
  parameterCode: string,
): string[] => {
  return useMemo<string[]>(() => {
    if (!productParameterTypes) return [] as string[];

    const f = productParameterTypes.find((pt) => pt.code === parameterCode);

    return (!f ? null : (f.enum as string[])) || ([] as string[]);
  }, [parameterCode, productParameterTypes]);
};
