import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

export interface FormInDialogProps {
  children: React.ReactNode;
  classes?: Partial<Record<FormInDialogClassKey, string>>;
  className?: string;
  onReset?: (event: React.FormEvent<HTMLFormElement>) => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

export type FormInDialogClassKey = 'root';

const useStyles = makeStyles(
  createStyles<FormInDialogClassKey, {}>({
    root: {
      display: 'flex',
      maxHeight: 'calc(100% - 64px)',
      flexDirection: 'column',
      overflowY: 'auto',
    },
  }),
  { name: 'FormInDialog' },
);

const FormInDialog = React.forwardRef<HTMLFormElement, FormInDialogProps>(
  ({ children, classes: pClasses, className, onReset, onSubmit, ...rest }, ref) => {
    const classes = useStyles({ classes: pClasses });

    const handleSubmit = React.useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (onSubmit) {
          onSubmit(event);
        }
      },
      [onSubmit],
    );

    const handleReset = React.useCallback(
      (event: React.FormEvent<HTMLFormElement>): void => {
        if (onReset) {
          onReset(event);
        }
      },
      [onReset],
    );

    return (
      <form
        ref={ref}
        className={clsx(classes.root, className)}
        onReset={handleReset}
        onSubmit={handleSubmit}
        noValidate
        {...rest}
      >
        {children}
      </form>
    );
  },
);

FormInDialog.displayName = 'FormInDialog';

export default FormInDialog;
