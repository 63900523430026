import NotFoundErrorPage from './404';
import InternalErrorPage from './500';
import { routeAllRoutesToRouter } from './routesBuilder';

export default [
  ...routeAllRoutesToRouter(),
  {
    path: '/_error',
    component: InternalErrorPage,
  },
  {
    component: NotFoundErrorPage,
  },
];
