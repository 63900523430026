import { ApplicationSettings } from './@sprinx/react-after-razzle';
import { SupportedCurrency, SupportedLocale, SupportedRegion } from './supported';

const applicationSettings: ApplicationSettings<SupportedLocale, SupportedCurrency, SupportedRegion> = {
  currency: (process.env.RAZZLE_SX_DEFAULT_CURRENCY || 'CZK') as SupportedCurrency,
  supportedCurrencies: ['CZK'],
  locale: (process.env.RAZZLE_SX_LOCALE || 'cs') as SupportedLocale,
  supportedLocales: [
    { locale: 'cs', label: 'Česky' },
    // { code: 'en', label: 'English' },
    // { code: 'sk', label: 'Slovelnsky' },
    // { code: 'pl', label: 'Polska' },
  ],
  pricesType: 'B2C',
  region: (process.env.RAZZLE_SX_REGION || '_default') as SupportedRegion,

  // TODO: add to AppProvider
  anonymouseUserId: process.env.RAZZLE_SX_ANONYMOUS_CONTACT_ID || 'storex-anonymous',
};

export default applicationSettings;
