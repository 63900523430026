import { TranslationDictionary } from '@sprinx/react-globalize/types';
import { routeUrl } from '../../routesBuilder';
import { SupportedLocale } from '../../supported';

type CPPDb = {
  id: string;
  name: TranslationDictionary;
};

export default function transformProduct<T extends CPPDb>(
  locale: SupportedLocale,
  products: T[],
): (T & { href: string })[] {
  const buildHref = (product: T): string => routeUrl('productDetail', { locale, params: product });

  const extender = (product: T): T & { href: string } => ({
    ...product,
    href: buildHref(product),
  });

  return (products || []).map(extender);
}
