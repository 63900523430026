import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { localeState } from '../../../api/appState';
import { routeUrl } from '../../../routesBuilder';

export type AppLayoutHeaderWideMainNavigationLinksProps = StandardDivProps<AppLayoutHeaderWideMainNavigationLinksClassKey>;

export type AppLayoutHeaderWideMainNavigationLinksClassKey = 'root';

const themeSettings = { name: 'AppLayoutHeaderWideMainNavigationLinks' };
const useStyles = makeStyles(
  (theme) => ({
    root: {
      color: 'inherit',
      '& a': {
        color: 'inherit',
        paddingLeft: theme.spacing(4),
        '&:not(:last-of-type)': {
          paddingRight: theme.spacing(1),
        },
        [theme.breakpoints.down('md')]: {
          paddingLeft: theme.spacing(1),
        },
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(1),
      },
    },
  }),
  themeSettings,
);

const AppLayoutHeaderWideMainNavigationLinks: React.FC<AppLayoutHeaderWideMainNavigationLinksProps> = React.forwardRef(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const locale = useRecoilValue(localeState);
    const t = useTranslate();

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <Link component={RouterLink} to={routeUrl('newsList', { locale, params: {} })}>
          {t('appMainMenuLinks/news')}
        </Link>
        <Link component={RouterLink} to={routeUrl('tiresTestsList', { locale, params: {} })}>
          {t('appMainMenuLinks/tests')}
        </Link>
        <Link component={RouterLink} to='/technicky-radce'>
          {t('appMainMenuLinks/techHelp')}
        </Link>
        {/* <Link component={RouterLink} to='/obchodni-podminky'> */}
        <Link component={RouterLink} to={routeUrl('termsAndConditions', { locale, params: {} })}>
          {t('appMainMenuLinks/termsAndConditions')}
        </Link>
        <Link component={RouterLink} to='/kontakt'>
          {t('appMainMenuLinks/contact')}
        </Link>
      </div>
    );
  },
);

AppLayoutHeaderWideMainNavigationLinks.displayName = 'AppLayoutHeaderWideMainNavigationLinks';

export default withThemeProps(themeSettings)(AppLayoutHeaderWideMainNavigationLinks);
