import Link, { LinkProps } from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface AppLayoutHeaderWideVehicleTypeLinkProps extends Omit<LinkProps, 'classes' | 'ref'> {
  active?: boolean;
  classes?: Partial<Record<AppLayoutHeaderWideVehicleTypeLinkClassKey, string>>;
  icon: React.ElementType;
  ref?: React.Ref<any>;
  to: string;
}

export type AppLayoutHeaderWideVehicleTypeLinkClassKey = 'root' | 'iconFill';

const themeSettings = { name: 'AppLayoutHeaderWideVehicleTypeLink' };
const useStyles = makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.down('md')]: {
        width: 130,
      },
      flexGrow: 1,
      height: 60,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      color: theme.palette.primary.contrastText,

      borderRight: '1px solid rgba(255,255,255,0.3)',
      '&:first-of-type': { borderLeft: '1px solid rgba(255,255,255,0.3)' },

      background: theme.palette.primary.main,
      transition: 'transform .2s ease',
      fontSize: 12,
      paddingTop: theme.spacing(0.5),
    },
    iconFill: {},
    active: {
      border: 'none',
      textDecoration: 'none',
      transformOrigin: 'top center',
      transform: 'scale(1.1) translate(0, -1.5px)',
      boxShadow: theme.shadows[7],
      '&:first-of-type': { borderLeft: 'none' },
    },
  }),
  themeSettings,
);

const AppLayoutHeaderWideVehicleTypeLink: React.FC<AppLayoutHeaderWideVehicleTypeLinkProps> = React.forwardRef(
  ({ active = false, children, className, classes: pClasses, icon: Icon, ...rest }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <Link
        ref={ref}
        component={RouterLink}
        className={clsx(classes.root, className, { [classes.active]: active })}
        variant='body1'
        {...rest}
      >
        <Icon fillClassName={classes.iconFill} />
        {children}
      </Link>
    );
  },
);

AppLayoutHeaderWideVehicleTypeLink.displayName = 'AppLayoutHeaderWideVehicleTypeLink';

export default withThemeProps(themeSettings)(AppLayoutHeaderWideVehicleTypeLink);
