import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slide from '@material-ui/core/Slide';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { TransitionProps } from '@material-ui/core/transitions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslate } from '@sprinx/react-globalize';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { currencyState, localeState } from '../../../api/appState';
import { catalogueSearchQuery, catalogueSearchTerm } from '../../../api/catalogue/catalogueSearch';
import PriceFormatted from '../../../components/PriceFormatted';
import ProductCardInlineParams from '../../../components/ProductCardInlineParams';
import StorexTextField from '../../../components/StorexTextField';
import { routeUrl } from '../../../routesBuilder';
import Skeleton from './AppLayoutSearchBarSkeleton';

export interface UserEvent {
  target: HTMLInputElement;
}

const themeSettings = { name: 'AppLayoutSearchBar' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      icon: { marginLeft: 'auto' },
      wrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      },
      textField: {
        minWidth: '380px',
      },
      fullWidth: {
        width: '100%',
      },
      closeIcon: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.primary.main,
      },
      products: {
        display: 'flex',
        /* marginBottom: theme.spacing(2), */
        marginTop: theme.spacing(2),
        flexDirection: 'column',
        gap: '0.75rem',
        '& > a': {
          color: 'inherit',
          textDecoration: 'inherit',
        },
        '& > :not(:last-child)': {
          borderBottom: '1px solid #dfdada',
        },
        [theme.breakpoints.down('md')]: {
          marginTop: '10px',
          marginBottom: '8px',
          padding: '0.75rem',
          border: '1px solid #dfdada;',
        },
      },
      product: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '10px',
        '& > *': {
          marginBlockStart: '0',
          marginBlockEnd: '0',
        },
        '& > h3': {
          marginBottom: '10px',
        },
        '& > p': {
          marginBottom: '2px',
        },
      },
      pricing: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 600,
      },
      searchBarMobile: {
        marginTop: '5px',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        '& h2': {
          marginBlockEnd: '0',
        },
      },
      searchMobileFull: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        zIndex: 9999999999,
        overflowY: 'scroll',
      },
    }),
  themeSettings,
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const AppLayoutSearchBar: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchBarOpen, setSearchBarOpen] = React.useState<boolean>(false);

  const handleOpen = React.useCallback(() => {
    setSearchBarOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setSearchBarOpen(false);
  }, []);

  return (
    <>
      {!isMobile ? (
        <Tooltip title='Vyhledávání' aria-label='Vyhledávání'>
          <IconButton className={classes.icon} onClick={handleOpen}>
            <SearchIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          <SearchBarMobile />
        </>
      )}

      <Dialog
        open={searchBarOpen}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby='Vyhledávání'
        aria-describedby='Vyhledávání'
      >
        <DialogTitle id='Vyhledávání'>
          {'Vyhledávání'}
          <IconButton className={classes.closeIcon} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ paddingBottom: '1rem' }}>
          <SearchBar onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  );
};

const useSearch = () => {
  const locale = useRecoilValue(localeState);
  const [searchText, setSearchText] = useState('');
  const setSearchTerm = useSetRecoilState(catalogueSearchTerm);
  const searchResultsUrl = routeUrl('searchResults', {
    locale,
    otherParamsToQs: true,
    params: { searchTerm: searchText },
  });
  const handleChange = useCallback(
    (v: string) => {
      setSearchText(v);
      setSearchTerm(v);
    },
    [setSearchText, setSearchTerm],
  );
  const handleReset = useCallback(() => {
    setSearchText('');
    setSearchTerm(undefined);
  }, [setSearchText, setSearchTerm]);

  useEffect(() => {
    setSearchTerm(undefined);
  }, [setSearchTerm]);
  return {
    searchText,
    searchResultsUrl,
    handleChange,
    handleReset,
  };
};

const typeWriterWords = ['225/45R17', 'Firestone 205/55R16', '245/45R18 Bridgestone LM 005', 'Barum Bravuris'];
const useTypeWriter = (input: React.MutableRefObject<HTMLInputElement | null>) => {
  React.useEffect(() => {
    const typingTimeout = 50;
    const wordTimeout = 1000;
    let timeout: NodeJS.Timeout | undefined = undefined;
    const run = (index = 0) => {
      const word = typeWriterWords[index];
      const type = (done: () => void, currentIndex = 0): void => {
        timeout = setTimeout(() => {
          if (input.current) {
            input.current.placeholder += word[currentIndex];
            if (currentIndex + 1 < word.length) {
              type(done, currentIndex + 1);
            } else {
              done();
            }
          }
        }, typingTimeout);
      };
      const backspace = (done: () => void): void => {
        timeout = setTimeout(() => {
          if (input.current) {
            input.current.placeholder = input.current.placeholder.slice(0, input.current.placeholder.length - 1);
            if (input.current.placeholder.length > 0) {
              backspace(done);
            } else {
              done();
            }
          }
        }, typingTimeout);
      };
      type(() => {
        timeout = setTimeout(() => {
          backspace(() => {
            timeout = setTimeout(() => {
              run(index + 1 < typeWriterWords.length ? index + 1 : 0);
            }, wordTimeout);
          });
        }, wordTimeout);
      });
    };
    run();
    return () => timeout && clearTimeout(timeout);
  }, [input]);
};

export const SearchBar: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const classes = useStyles();
  const { searchText, searchResultsUrl, handleChange } = useSearch();

  const input = React.useRef<HTMLInputElement | null>(null);
  useTypeWriter(input);

  useEffect(() => {
    if (input && input.current) {
      input.current?.focus();
    }
  }, []);

  return (
    <form>
      <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <StorexTextField
          ref={input}
          variant='outlined'
          value={searchText}
          onChange={handleChange}
          InputProps={{
            className: classes.textField,
          }}
        />
        <Link to={searchResultsUrl} onClick={onClose} style={{ textDecoration: 'inherit' }}>
          <Button style={{ height: '40px' }} variant='contained' color='primary' type='submit'>
            Vyhledat
          </Button>
        </Link>
      </div>
      <SearchBarResults classes={classes} onSelectItem={onClose} />
    </form>
  );
};

export const SearchBarMobile: React.FC = () => {
  const classes = useStyles();
  const { searchText, searchResultsUrl, handleChange, handleReset } = useSearch();
  const input = React.useRef<HTMLInputElement | null>(null);
  useTypeWriter(input);

  useEffect(() => {
    const body = document.getElementsByTagName('BODY')[0];
    if (typeof window !== 'undefined') {
      if (searchText) {
        body.classList.add('mobileOpen');
      } else if (body.classList.contains('mobileOpen')) {
        body.classList.remove('mobileOpen');
      }
    }
  }, [searchText]);

  return (
    <form className={clsx(classes.searchBarMobile, searchText && classes.searchMobileFull)}>
      <StorexTextField
        ref={input}
        fullWidth
        value={searchText}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {searchText ? (
                <>
                  <IconButton type='button' aria-label='remove text' edge='end' onClick={handleReset}>
                    <CloseIcon />
                  </IconButton>
                  <Link to={searchResultsUrl} style={{ textDecoration: 'inherit' }}>
                    <IconButton aria-label='search product' edge='end' type='submit' onClick={handleReset}>
                      <SearchIcon />
                    </IconButton>
                  </Link>
                </>
              ) : (
                <IconButton aria-label='search product' edge='end'>
                  <SearchIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
          startAdornment: (
            <>
              {searchText && (
                <InputAdornment position='start'>
                  <IconButton aria-label='back button' edge='start' onClick={handleReset}>
                    <ArrowBackIcon />
                  </IconButton>
                </InputAdornment>
              )}
            </>
          ),
        }}
      />

      {searchText && <SearchBarResults classes={classes} onSelectItem={handleReset} />}
    </form>
  );
};

const SearchBarResults: React.FC<{
  classes: { pricing: string; product: string; products: string };
  onSelectItem?: () => void;
}> = ({ classes, onSelectItem }) => {
  const currency = useRecoilValue(currencyState);
  const t = useTranslate();
  const searchResult = useRecoilValueLoadable(catalogueSearchQuery);
  return (
    <div className={classes.products}>
      {searchResult.state === 'loading' ? <Skeleton /> : null}
      {searchResult.state === 'hasError' ? 'Nepodařilo se načíst data' : null}
      {searchResult.state === 'hasValue'
        ? searchResult.contents.rows.map((product) => {
            return (
              <Link key={product.id} to={product.href} onClick={onSelectItem}>
                <div className={classes.product}>
                  <h3>
                    {product.manufacturer ? product.manufacturer.name + ' ' : null}
                    {t(product.name, { acceptString: true })}
                  </h3>
                  <ProductCardInlineParams product={product} />
                  <PriceFormatted className={classes.pricing} value={product.priceWithTax} currency={currency} />
                </div>
              </Link>
            );
          })
        : null}
    </div>
  );
};

AppLayoutSearchBar.displayName = 'AppLayoutSearchBar';

export default withThemeProps(themeSettings)(AppLayoutSearchBar);
