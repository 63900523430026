import { ContainerProps } from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import AppLayoutHeaderWideAppBar from './AppLayoutHeaderWideAppBar';
import AppLayoutHeaderWideMainNavigation from './AppLayoutHeaderWideMainNavigation';

export interface AppLayoutHeaderWideProps extends StandardDivProps<AppLayoutHeaderWideClassKey> {
  maxWidth: ContainerProps['maxWidth'];
}

export type AppLayoutHeaderWideClassKey = 'root' | 'container' | 'centralArea' | 'sideArea' | 'selectVehicleType';

const themeSettings = { name: 'AppLayoutHeaderWide' };
const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      zIndex: theme.zIndex.appBar,
      background: theme.palette.background.default,
      boxShadow: theme.shadows[2],
    },
    appBarWrapper: {
      backgroundColor: theme.palette.primary.main,
    },
    mainNavigationWrapper: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  }),
  themeSettings,
);

const AppLayoutHeaderWide: React.FC<AppLayoutHeaderWideProps> = React.forwardRef(
  ({ className, classes: pClasses, maxWidth }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <div className={classes.mainNavigationWrapper}>
          <AppLayoutHeaderWideMainNavigation maxWidth={maxWidth} />
        </div>
        <div className={classes.appBarWrapper}>
          <AppLayoutHeaderWideAppBar maxWidth={maxWidth} />
        </div>
      </div>
    );
  },
);

AppLayoutHeaderWide.displayName = 'AppLayoutHeaderWide';

export default withThemeProps(themeSettings)(AppLayoutHeaderWide);
