import Container, { ContainerProps } from '@material-ui/core/Container';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import TireChangeWizzard from '../../components/TireChangeWizzard/TireChangeWizzard';
import useGtmPageViews from '../../hooks/useGtmPageViews';
import AppLayoutFooter from './components/AppLayoutFooter';
import AppLayoutHeader from './components/AppLayoutHeader';

export interface AppLayoutProps extends StandardDivProps<AppLayoutClassKey> {
  children: ContainerProps['children'];
  containerProps?: ContainerProps;
  maxWidth?: ContainerProps['maxWidth'];
}

export type AppLayoutClassKey = 'root' | 'main';

const themeSettings = { name: 'AppLayout' };

const useStyles = makeStyles(
  (theme) =>
    createStyles<AppLayoutClassKey, {}>({
      root: {
        background: theme.palette.background.default,
        fontFamily: theme.typography.fontFamily,
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      main: {
        flexGrow: 1,
      },
    }),
  themeSettings,
);

const AppLayout: React.FC<AppLayoutProps> = React.forwardRef(
  ({ children, classes: pClasses, className, containerProps, maxWidth = 'lg', ...rest }, ref) => {
    const classes = useStyles({ classes: pClasses });
    useGtmPageViews();

    return (
      <div ref={ref} className={clsx(classes.root, className)} {...rest}>
        <AppLayoutHeader maxWidth={maxWidth} />
        <Container component='main' className={classes.main} maxWidth={maxWidth} {...containerProps}>
          {children}
        </Container>
        <AppLayoutFooter maxWidth={maxWidth} />
        {/*  <AppLayoutCookieConsent /> */}
        <TireChangeWizzard />
      </div>
    );
  },
);

export default withThemeProps(themeSettings)(AppLayout);
