import type { FormikErrors } from '@sprinx/react-forms';
import formSectionErrorSub from '@sprinx/react-forms/formSectionErrorSub';
import defaultsDeep from 'lodash/defaultsDeep';
import { DeepPartial } from 'utility-types';
import calculateSectionCompletedByFields from './calculateSectionCompletedByFields';
import { CheckoutSectionCommonState } from './types';

export interface SectionState extends CheckoutSectionCommonState {
  data: SectionStateData;
  dataInitialized: boolean;
  deliveryInfoIsDifferent: boolean | undefined;
  visible: boolean;
}

export interface SectionStateData {
  companyName?: string;
  emailAddress?: string;
  personName: string;
  personOrCompany: 'person' | 'company';
  phoneNumber?: string;
  postAddress: {
    city: string;
    country: string;
    postalCode: string;
    street: string;
  };
}

export function createDefaultState(initialData?: DeepPartial<SectionStateData>): SectionState {
  return {
    completed: false,
    deliveryInfoIsDifferent: false,
    data: createDefaultData(initialData),
    dataInitialized: !!initialData,
    visible: true,
  };
}

export function createDefaultData(initialData: DeepPartial<SectionStateData> = {}): SectionStateData {
  return defaultsDeep(
    {
      companyName: undefined,
      emailAddress: undefined,
      personName: undefined,
      phoneNumber: undefined,
      personOrCompany: 'person',
      postAddress: {
        city: undefined,
        country: 'CZ',
        postalCode: undefined,
        street: undefined,
      },
    },
    initialData,
  );
}

export function calculateCompletedByFields(
  value: SectionState | undefined,
  error: FormikErrors<SectionState> | undefined,
): boolean {
  const errorData = formSectionErrorSub(formSectionErrorSub(error)?.data);
  const errorDataPostAddress = formSectionErrorSub(errorData?.postAddress);

  // prettier-ignore
  const r = calculateSectionCompletedByFields([
    [value?.data?.personName, errorData?.personName],
    [value?.data?.emailAddress, errorData?.emailAddress, true],
    [value?.data?.phoneNumber, errorData?.phoneNumber, true],
    [value?.data?.postAddress?.city, errorDataPostAddress?.city],
    [value?.data?.postAddress?.street, errorDataPostAddress?.street],
    [value?.data?.postAddress?.postalCode, errorDataPostAddress?.postalCode],
    ...(value?.data?.personOrCompany === 'company'
      ? ([
          [value?.data?.companyName, errorData?.companyName],
        ] as [any, any][])
      : []),
  ]);

  return r;
}
