import { EventSourcePolyfill } from 'event-source-polyfill';
import React from 'react';

const hostUri: string = process.env.RAZZLE_API_URI || '';
const secureClientId: string = process.env.RAZZLE_AUTH_CLIENT_ID || '';

// let eventSource: typeof EventSourcePolyfill | undefined;
let eventSource: any | undefined;

export const SseListenerConnect: React.FC<{
  /**
   * Relative URL part of eventSourceService
   */
  eventSourceServiceUrl?: string;
}> = ({ children, eventSourceServiceUrl = '_event-stream' }) => {
  React.useEffect(
    () => {
      const eventSourceUrl = `${hostUri.replace(/\/$/, '')}/${eventSourceServiceUrl.replace(/^\//, '')}`;

      eventSource = new EventSourcePolyfill(eventSourceUrl, {
        headers: { 'x-sx-client-id': secureClientId },
        withCredentials: true,
      });
      return () => {
        eventSource = undefined;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return children ? <>{children}</> : null;
};

SseListenerConnect.displayName = 'SseListenerConnect';

export const useSseEventListener = <Payload extends any>(
  eventName: string,
  handler: (payload: Payload) => void,
): void => {
  const savedHandler = React.useRef<undefined | ((payload: Payload) => void)>();

  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(
    () => {
      // Make sure element supports addEventListener
      const isSupported = eventSource && eventSource.addEventListener;
      if (!isSupported) return undefined;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event: MessageEvent<string>) => {
        if (savedHandler.current && typeof savedHandler.current === 'function') {
          const payload = event.data ? JSON.parse(event.data) : undefined;
          savedHandler.current(payload);
        }
      };

      // Add event listener
      eventSource.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        if (eventSource) eventSource.removeEventListener(eventName, eventListener);
      };
    },
    [eventName], // Re-run if eventName or element changes
  );
};
