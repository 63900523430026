import React from 'react';
import { useRecoilState } from 'recoil';
import { showMessageState, useShowSnackbarMessage } from '../../api/snackbars';

const SnackbarMessageCatcher: React.FC = (): null => {
  const showMessage = useShowSnackbarMessage();
  const [msgQ, setMsgQ] = useRecoilState(showMessageState);
  React.useEffect(() => {
    if (msgQ.length > 0) {
      msgQ.forEach((o) => {
        if (o) {
          const { message, ...opts } = o;
          showMessage(message, opts);
        }
      });
      setMsgQ([]);
    }
  }, [msgQ, setMsgQ, showMessage]);
  return null;
};

SnackbarMessageCatcher.displayName = 'SnackbarMessageCatcher';

export default SnackbarMessageCatcher;
