import Container, { ContainerProps } from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import AppLayoutHeaderWideMainNavigationInfo from './AppLayoutHeaderWideMainNavigationInfo';
import AppLayoutHeaderWideMainNavigationLinks from './AppLayoutHeaderWideMainNavigationLinks';

export interface AppLayoutHeaderWideMainNavigationProps
  extends StandardDivProps<AppLayoutHeaderWideMainNavigationClassKey> {
  maxWidth: ContainerProps['maxWidth'];
}

export type AppLayoutHeaderWideMainNavigationClassKey = 'root';

const themeSettings = { name: 'AppLayoutHeaderWideMainNavigation' };
const useStyles = makeStyles(
  (theme) => ({
    root: {
      minHeight: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      ...theme.typography.body2,
      padding: theme.spacing(1, 3),
      fontSize: '.8125rem',
    },
    info: {
      width: '25%',
    },
    links: {
      flexGrow: 1,
    },
  }),
  themeSettings,
);

const AppLayoutHeaderWideMainNavigation: React.FC<AppLayoutHeaderWideMainNavigationProps> = React.forwardRef(
  ({ className, classes: pClasses, maxWidth }, ref) => {
    const classes = useStyles({ classes: pClasses });

    return (
      <Container ref={ref} className={clsx(classes.root, className)} maxWidth={maxWidth}>
        <AppLayoutHeaderWideMainNavigationInfo className={classes.info} />
        <AppLayoutHeaderWideMainNavigationLinks className={classes.links} />
      </Container>
    );
  },
);

AppLayoutHeaderWideMainNavigation.displayName = 'AppLayoutHeaderWideMainNavigation';

export default withThemeProps(themeSettings)(AppLayoutHeaderWideMainNavigation);
