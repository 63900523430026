import { atom, isRecoilValue, selector } from 'recoil';

export const mainNavigationNarrowNavigationOpenState = atom<boolean>({
  key: 'mainNavigationNarrowNavigationOpen',
  default: false,
});

export const mainNavigationNarrowNavigationToggleState = selector<boolean | undefined>({
  key: 'mainNavigationNarrowNavigationToggleState',
  get: ({ get }) => {
    return get(mainNavigationNarrowNavigationOpenState);
  },
  set: ({ get, set }, newValue) => {
    const v: boolean | undefined = isRecoilValue(newValue) ? get(newValue) : newValue;
    set(mainNavigationNarrowNavigationOpenState, (ps) => (typeof v === 'boolean' ? v : !ps));
  },
});
