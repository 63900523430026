import React from 'react';
import { useDefaultLayout } from '../LayoutProvider';

export default function wrapLayout<P = {}, PL = {}>(
  Component: React.ComponentType<P>,
  layoutComponent?: React.ComponentType<PL>,
  layoutComponentProps?: Partial<PL>,
): React.ComponentType<P> {
  const LayoutWrapper = (props: any): any => {
    const DefaultLayout = useDefaultLayout();
    const LayoutComponent = layoutComponent || DefaultLayout;

    return (
      <LayoutComponent {...layoutComponentProps}>
        <Component {...props} />
      </LayoutComponent>
    );
  };

  LayoutWrapper.displayName = `LayoutWrapper(${Component.displayName || Component.name || 'Component'})`;

  return LayoutWrapper;
}
