/**
 * Season hints articles
 */

import { QueryPaginationSettings } from '@sprinx/query-builder';
import { atom, DefaultValue, selector } from 'recoil';
import invariant from 'tiny-invariant';
import { MoleculerListResult } from '../../@sprinx/knihovka-types';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { buildPaginationQuery } from '../../@sprinx/react-after-razzle/filteredLists';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import { routeUrl } from '../../routesBuilder';
import { SupportedLocale } from '../../supported';
import { apiClientState, localeState } from '../appState';
import { Branch, BranchListItem } from './Branch';

export const branchListQuery = selector<MoleculerListResult<BranchListItem>>({
  key: 'branchListQuery',
  get: ({ get }) => {
    const initials = get(branchListInitialState);
    if (initials) return initials;

    const params = get(branchListSettingsState);
    return getBranchList(get(apiClientState), get(localeState), params.pagination);
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(branchListTrigger, (n) => n + 1);
    }
  },
});
const branchListTrigger = atom<number>({
  key: 'branchListTrigger',
  default: 0,
});

export const branchListInitialState = GlobalStateRegister.register(
  atom<MoleculerListResult<BranchListItem> | undefined>({
    key: 'branchListInitial',
    default: undefined,
  }),
);

export const createBranchListDefaultSettings = (): { pagination: QueryPaginationSettings } => {
  return { pagination: { page: 1, rowsPerPage: 12 } };
};

export const branchListSettingsState = GlobalStateRegister.register(
  atom<{ pagination: QueryPaginationSettings }>({
    key: 'branchListSettings',
    default: createBranchListDefaultSettings(),
  }),
);

export const branchListPaginationState = selector<
  Partial<QueryPaginationSettings & { total: number; totalPages: number }>
>({
  key: 'branchListPagination',
  get: ({ get }) => {
    const params = get(branchListSettingsState);
    const queryResult = get(branchListQuery);

    return {
      page: params.pagination.page || queryResult.page,
      rowsPerPage: queryResult.pageSize || params.pagination.rowsPerPage,
      total: queryResult.total,
      totalPages: queryResult.totalPages,
    };
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      const df = createBranchListDefaultSettings();
      set(branchListSettingsState, (prev) => ({
        ...prev,
        pagination: df.pagination,
      }));
    } else {
      set(branchListSettingsState, (prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          ...newValue,
        },
      }));
    }
  },
});

export const getBranchList = (
  apiClient: ApiClient,
  locale: SupportedLocale,
  pagination: QueryPaginationSettings,
): Promise<MoleculerListResult<BranchListItem>> => {
  invariant(apiClient, "getBranchList(...) requires 'apiClient' to be defined.");
  invariant(locale, "getBranchList(...) requires 'locale' to be defined.");
  invariant(pagination, "getBranchList(...) requires 'locale' to be defined.");

  return apiClient
    .get<MoleculerListResult<BranchListItem>, {}>('/v1/stores', buildPaginationQuery(pagination))
    .then((l) => ({
      ...l,
      rows: l.rows.map((a) => ({ ...a, href: routeUrl('branchDetail', { locale, params: { id: a.id } }) })),
    }));
};

export const getBranchDetail = (apiClient: ApiClient, locale: SupportedLocale, id: string): Promise<Branch> => {
  invariant(apiClient, "getBranchList(...) requires 'apiClient' to be defined.");
  invariant(locale, "getBranchList(...) requires 'locale' to be defined.");
  invariant(id, "getBranchList(...) requires 'id' of article to be defined.");
  return apiClient.get<Branch, { id: string; locale: SupportedLocale }>('/v1/store/:id', { id, locale });
};
