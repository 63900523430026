import React from 'react';
import { useSetRecoilState } from 'recoil';
import { useSseEventListener } from '../../@sprinx/react-after-razzle/sseListener';
import { ShoppingCartManipulationResult, shoppingCartState, useShowCartUpdateMessage } from '../../api/shoppingCart';

export interface ShoppingCartRemoteChangeListenerProps {
  children?: React.ReactNode;
}

const ShoppingCartRemoteChangeListener: React.FC<ShoppingCartRemoteChangeListenerProps> = ({ children }) => {
  const updateCart = useSetRecoilState(shoppingCartState);
  const showCartUpdateStatusMsg = useShowCartUpdateMessage();

  const cartRemoteUpdateHandler = React.useCallback(
    (res: ShoppingCartManipulationResult) => {
      updateCart(res.cart);
      if (res.updateStatus.msg) {
        showCartUpdateStatusMsg(res.updateStatus);
      }
    },
    [showCartUpdateStatusMsg, updateCart],
  );

  useSseEventListener('storex.shoppingCart.updated', cartRemoteUpdateHandler);

  return children ? <>{children}</> : null;
};

ShoppingCartRemoteChangeListener.displayName = 'ShoppingCartRemoteChangeListener';

export default ShoppingCartRemoteChangeListener;
