import { Required } from 'utility-types';
import { AfterCtx } from './types';
import { MakeAfterComponentGetInitialProps } from './makeAfterComponent';

export default function createInitialPropsRedirectToLogin<M = any>(
  ctx: Required<Partial<AfterCtx<M>>, 'match'>,
  path = '/login',
): ReturnType<MakeAfterComponentGetInitialProps<any, M>> {
  return Promise.resolve({
    redirectTo: `${path}?from=${encodeURIComponent(ctx.match.url)}`,
  });
}
