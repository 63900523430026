import { makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { localeState } from '../../../api/appState';
import { catalogueCallParamsState, createDefaultCatalogueCallParams } from '../../../api/catalogue/catalogue';
import { VehicleType } from '../../../api/vehicleTypes';
import MotoIcon from '../../../components/icons/MotoIcon';
import SuvIcon from '../../../components/icons/SuvIcon';
import TirIcon from '../../../components/icons/TirIcon';
import TruckIcon from '../../../components/icons/TruckIcon';
import { routeIsActive, routeUrl } from '../../../routesBuilder';
import AppLayoutHeaderWideVehicleTypeLink from './AppLayoutHeaderWideVehicleTypeLink';

export type AppLayoutHeaderWideVehicleTypeListProps = StandardDivProps<AppLayoutHeaderWideVehicleTypeListClassKey>;

export type AppLayoutHeaderWideVehicleTypeListClassKey = 'root' | 'selectVehicleTypeArea' | 'vehicleType';

const themeSettings = { name: 'AppLayoutHeaderWideVehicleTypeList' };
const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '50%',
      maxWidth: theme.spacing(68),
    },
    selectVehicleTypeArea: {
      display: 'flex',
      alignItems: 'stretch',
    },
    vehicleType: {},
    iconFill: {
      fill: theme.palette.common.white,
    },
  }),
  themeSettings,
);

const AppLayoutHeaderWideVehicleTypeList: React.FC<AppLayoutHeaderWideVehicleTypeListProps> = React.forwardRef(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const locale = useRecoilValue(localeState);
    const routeMatch = useRouteMatch();
    const catalogueCallParams = useRecoilValue(catalogueCallParamsState);

    const url = (vt: VehicleType): string => {
      const params =
        catalogueCallParams.filter.vehicleType === vt
          ? catalogueCallParams
          : createDefaultCatalogueCallParams({
              // tireSeasons: catalogueCallParams.filter.tireSeasons,
              vehicleType: vt,
            });

      return routeUrl('catalogue', { locale, otherParamsToQs: true, params });
    };

    const isCatalogueRouteActive = routeIsActive('catalogue', { locale, match: routeMatch });

    return (
      <div ref={ref} className={clsx(classes.root, classes.selectVehicleTypeArea, className)}>
        <AppLayoutHeaderWideVehicleTypeLink
          active={catalogueCallParams.filter.vehicleType === 'passenger' && isCatalogueRouteActive}
          className={classes.vehicleType}
          classes={{ iconFill: classes.iconFill }}
          icon={SuvIcon}
          to={url('passenger')}
        >
          {'Osobní a SUV'}
        </AppLayoutHeaderWideVehicleTypeLink>
        <AppLayoutHeaderWideVehicleTypeLink
          active={catalogueCallParams.filter.vehicleType === 'van' && isCatalogueRouteActive}
          className={classes.vehicleType}
          classes={{ iconFill: classes.iconFill }}
          icon={TirIcon}
          to={url('van')}
        >
          {'Dodávky'}
        </AppLayoutHeaderWideVehicleTypeLink>
        <AppLayoutHeaderWideVehicleTypeLink
          active={catalogueCallParams.filter.vehicleType === 'truck' && isCatalogueRouteActive}
          className={classes.vehicleType}
          icon={TruckIcon}
          to={url('truck')}
        >
          {'Nákladní auta'}
        </AppLayoutHeaderWideVehicleTypeLink>
        <AppLayoutHeaderWideVehicleTypeLink
          active={catalogueCallParams.filter.vehicleType === 'moto' && isCatalogueRouteActive}
          className={classes.vehicleType}
          classes={{ iconFill: classes.iconFill }}
          icon={MotoIcon}
          to={url('moto')}
        >
          {'Motocykly'}
        </AppLayoutHeaderWideVehicleTypeLink>
      </div>
    );
  },
);

AppLayoutHeaderWideVehicleTypeList.displayName = 'AppLayoutHeaderWideVehicleTypeList';

export default withThemeProps(themeSettings)(AppLayoutHeaderWideVehicleTypeList);
