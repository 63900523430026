import React from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';

export default function useGtmPageViews(): void {
  const location = useLocation();
  const { pathname, search } = location;
  const path = `${pathname}${search}`;

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const { title } = Helmet.peek();
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pageView',
        pagePath: path,
        pageTitle: title,
      });
    }
  }, [path]);
}
